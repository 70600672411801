import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import CustomSearch from '../components/icons/CustomSearch.vue';
import CustomFilter from '../components/icons/CustomFilter.vue';
import CustomArrowDown from '../components/icons/CustomArrowDown';
import CustomArrowBack from '../components/icons/CustomArrowBack';
import CustomNavIcon from '../components/icons/CustomNavIcon';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1264,
      lg: 1440,
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        anchor: '#34C759',
        tagdarkpink: '#FE2E55',
        taglightblue: '#59C8FA',
        tagorange: '#FF9502',
        taggreen: '#34C759',
        magenta: '#C33AFC',
        tagdarkblue: '#007AFF',
        bluepurple: '#5856D7',
        red: '#FF3830',
        bordergray: '#E5E5EA',
        borderformgray: '#C7C7CC',
        bordercleargray: '#8E8E93',
        loadinggray: '#D1D1D6',
        bgdark: '#1C1C1E',
        bgsustainability: '#F4FCF6',
        bgabout: '#F1F4F7',
        bgcontact: '#FAFAF8',
        mainbg: '#FFFFFF',
        colors: {
          green: '#34C759',
          purple: '#AF52DE',
        },
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      search: {
        // name of our custom icon
        component: CustomSearch, // our custom component
      },
      filter: {
        // name of our custom icon
        component: CustomFilter, // our custom component
      },
      arrowDown: {
        // name of our custom icon
        component: CustomArrowDown, // our custom component
      },
      arrowBack: {
        // name of our custom icon
        component: CustomArrowBack, // our custom component
      },
      navigationIcon: {
        component: CustomNavIcon,
      },
    },
  },
});
