<template>
  <div class="gradient">
    <div class="page-height d-flex">
      <top-forty light-themed wider addLogo scrollTo="#inprogress">

        <template #titleh2>{{ $t('forty.hero.text') }}</template>
        /></top-forty>
    </div>


    <div class="page-height my-15" id="inprogress">
      <forty-card class="py-0 py-md-15 px-0 px-md-15" scrollTo="#infuture">
        <template #first>
          <text-component light-themed position="left" max-width="632">
            <template #titleh1>{{ $t('forty.inprogress.title') }}</template>
            <template #content>
              <span class="text-h3" v-show="$vuetify.breakpoint.smAndDown">{{ $t('forty.inprogress.subtitle') }}</span>
              <span class="text-h2" v-show="$vuetify.breakpoint.mdAndUp">{{ $t('forty.inprogress.subtitle') }}</span>
            </template>
          </text-component>
        </template>
        <template #second>
          <text-component light-themed position="right">
            <template #content>
              <v-img src="@/assets/images/forty/_desktop_mobile/image_inprogress.png" max-width="500" />
            </template>
          </text-component>
        </template>
      </forty-card>
    </div>

    <div class="page-height my-15" id="infuture">
      <forty-card class="py-0 py-md-15 px-0 px-md-15" scrollTo="#vsys">
        <template #first>
          <text-component light-themed position="left" max-width="632">
            <template #titleh1>{{ $t('forty.infuture.title') }}</template>
            <template #content>
              <span class="text-h3" v-show="$vuetify.breakpoint.smAndDown">{{ $t('forty.infuture.subtitle') }}</span>
              <span class="text-h2" v-show="$vuetify.breakpoint.mdAndUp">{{ $t('forty.infuture.subtitle') }}</span>
            </template>
          </text-component>
        </template>
        <template #second>
          <text-component light-themed position="right">
            <template #content>
              <v-img src="@/assets/images/forty/_desktop_mobile/image_infuture.png" max-width="500" />
            </template>
          </text-component>
        </template>
      </forty-card>
    </div>

    <div class="page-height my-15" id="vsys">
      <forty-card class="py-0 py-md-15 px-0 px-md-15" scrollTo="#inforum">
        <template #first>
          <text-component light-themed position="left" max-width="632">
            <template #titleh1>{{ $t('forty.vsys.title') }}</template>
            <template #content>
              <span class="text-h3" v-show="$vuetify.breakpoint.smAndDown">{{ $t('forty.vsys.subtitle') }}</span>
              <span class="text-h2" v-show="$vuetify.breakpoint.mdAndUp">{{ $t('forty.vsys.subtitle') }}</span>
            </template>
          </text-component>
        </template>
        <template #second>
          <text-component light-themed position="right">
            <template #content>
              <v-img src="@/assets/images/forty/_desktop_mobile/image_vsys.png" max-width="500" />
            </template>
          </text-component>
        </template>
      </forty-card>
    </div>

    <div class="page-height my-15" id="inforum">
      <forty-card class="py-0 py-md-15 px-0 px-md-15" scrollTo="#thirds">
        <template #first>
          <text-component light-themed position="left" max-width="632">
            <template #titleh1>{{ $t('forty.inforum.title') }}</template>
            <template #content>
              <span class="text-h3" v-show="$vuetify.breakpoint.smAndDown">{{ $t('forty.inforum.subtitle') }}</span>
              <span class="text-h2" v-show="$vuetify.breakpoint.mdAndUp">{{ $t('forty.inforum.subtitle') }}</span>
            </template>
          </text-component>
        </template>
        <template #second>
          <text-component light-themed position="right">
            <template #content>
              <v-img src="@/assets/images/forty/_desktop_mobile/image_inforum.png" max-width="500" />
            </template>
          </text-component>
        </template>
      </forty-card>
    </div>



    <three-cols class="py-15 my-4 my-md-8">
      <template #first>
        <text-component id="thirds" class="pt-10 pt-sm-0 pt-md-10" light-themed position="right">
          <template #titleh1><v-img src="@/assets/images/forty/_desktop_mobile/bild_40.png"
              aspect-ratio="1.7778" /></template>
          <template #titleh2 class="py-10">{{ $t('forty.thirds.article.title') }}</template>
          <v-card-text class="white--text px-0 py-0">
            <p class="text-body-1">
              {{ $t('forty.thirds.article.text') }}
            </p>
            <a href="https://www.incoord.se/nyheter/nu-fortsatter-framtiden-med-ytterligare-40-ar-av-framgang">
              <outlined-button lightContent>
                <template #buttonText>
                  {{ $t('forty.thirds.article.button') }}
                </template>
              </outlined-button>
            </a>
          </v-card-text>
        </text-component>
      </template>
      <template #second>
        <text-component class="pt-10 pt-sm-0 pt-md-10" light-themed position="right">
          <template #titleh1><v-img src="@/assets/images/forty/_desktop_mobile/the-wood-hotel.jpeg"
              aspect-ratio="1.7778" /></template>
          <template #titleh2>{{ $t('forty.thirds.project.title') }}</template>
          <v-card-text class="white--text px-0 py-0">
            <p class="text-body-1">
              {{ $t('forty.thirds.project.text') }}
            </p>
            <a href="https://www.incoord.se/projekt">
              <outlined-button lightContent>
                <template #buttonText>
                  {{ $t('forty.thirds.project.button') }}
                </template>
              </outlined-button>
            </a>
          </v-card-text>
        </text-component>
      </template>
      <template #third>
        <text-component class="pt-10 pt-sm-0 pt-md-10" light-themed position="right">
          <template #titleh1><v-img src="@/assets/images/forty/_desktop_mobile/image_anlita_oss.jpg"
              aspect-ratio="1.7778" /></template>
          <template #titleh2>{{ $t('forty.thirds.hireUs.title') }}</template>
          <v-card-text class="white--text px-0 py-0">
            <p class="text-body-1">
              {{ $t('forty.thirds.hireUs.text') }}
            </p>
            <a href="https://www.incoord.se/kontakt">
              <outlined-button lightContent>
                <template #buttonText>
                  {{ $t('forty.thirds.hireUs.button') }}
                </template>
              </outlined-button>
            </a>
          </v-card-text>
        </text-component>
      </template>
    </three-cols>

    <template>
      <div class="contact pt-16 pb-10">
        <autoadjusting-row>
          <v-col cols="12" md="6" offset="0" offset-md="3">
            <v-row justify="center">
              <text-component light-themed centered>
                <template #titleh2>{{ $t('forty.newsletter.header') }}</template>
                <template #contentWithoutFont>
                  <h3 class="text-h3">
                    {{ $t('forty.newsletter.text') }}
                  </h3>
                </template>
              </text-component>
            </v-row>
            <v-row class="mb-lg-15 pb-lg-15" justify="center">
              <v-col cols="12">
                <v-form ref="form" v-model="valid" lazy-validation class="contact-form" :disabled="disableForm">
                  <v-row centered>
                    <v-col cols="8" offset="0" offset-md="1" class="mt-4">
                      <v-text-field id="form-email" v-model="formData.email" :placeholder="emailPlaceholder"
                        :rules="emailRules" label="" outlined dense required class="rounded-sm"
                        background-color="white"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <outlined-button v-on:click-submit="submit" :enable-value="valid" lightContent :loading="loading"
                        :disabled="loading">
                        <template #buttonText>
                          {{ $t('forty.newsletter.button') }}
                        </template>
                      </outlined-button>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
        </autoadjusting-row>
      </div>
    </template>
    <user-message :showMe="showMessage" :messageText="userMessage" :error="error"></user-message>


  </div>
</template>

<script>
import FortyCard from '../components/partial/FortyCard.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TopForty from '../components/complete/TopForty.vue';
import ThreeCols from '../components/partial/ThreeCols.vue';
import OutlinedButton from '../components/partial/OutlinedButton.vue';
import setSubHeaderToNullMixin from '../mixins/setSubHeaderToNullMixin.js';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import UserMessage from '../components/complete/UserMessage.vue';
import { mapActions } from 'vuex';

//noinspection JSAnnotator
export default {
  name: 'Forty',
  components: {
    FortyCard,
    TextComponent,
    TopForty,
    ThreeCols,
    OutlinedButton,
    AutoadjustingRow,
    UserMessage
  },
  mixins: [setSubHeaderToNullMixin],
  data() {
    return {
      showMessage: false,
      userMessage: '',
      error: false,
      valid: false,
      loading: false,
      emailPlaceholder: this.$t('forty.newsletter.placeholder'),
      emailRules: [
        (v) => !!v || this.$t('global.contact.form.required'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('global.contact.form.invalidEmail'),
      ],
      formData: {
        email: '',
      },
    };
  },
  computed: {
    breadcrumbItems() {
      return [];
    },
    disableForm() {
      return this.loading;
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
      postNewsletter: 'app/postNewsletter'
    }),
    async submit() {
      console.log('Submitting');
      this.showMessage = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let sendData = this.formData;
        const formData = new FormData();
        Object.keys(sendData).map((key) => {
          let value = sendData[key];
          formData.append(key, value);
        });
        try {
          await this.postNewsletter(formData);
          this.userMessage = this.$t('global.contact.form.success');
          this.error = false;
          this.$refs.form.reset();
        } catch (error) {
          this.userMessage = this.$t('global.contact.form.error');
          this.error = true;
        }
        this.loading = false;
        this.showMessage = true;
      }
    },
    navigate(item) {
      if (item.startsWith('#')) {
        this.$vuetify.goTo(item, { duration: 400 });
      } else {
        this.$router.push({
          name: item,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 16px;
}

.card-subtitle {
  font-weight: bold;
  display: block;
}

.gradient {
  background: transparent linear-gradient(90deg, #813A89 0%, #813A89 33%, #CF605F 100%) 0% 0% no-repeat padding-box !important;
  margin-bottom: -20px;
}

.page-height {
  min-height: 100svh;
  position: relative;
}

.v-application {

  a,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
  }
}
</style>
