<template>
  <div class="news-list" v-if="teaserItems && teaserItems.length > 2">
    <autoadjusting-row>
      <v-col>
        <v-card
          color="transparent"
          elevation="0"
          class="text-center px-5 pt-10 pt-sm-2 pt-lg-10 mb-10 mb-sm-7 mb-md-8"
        >
          <slot name="newsTitle"></slot>
        </v-card>
      </v-col>
    </autoadjusting-row>

    <list-component :items="teaserItems" forceThreeColsOnSm>
      <template v-slot:default="slotProps">
        <small-news whiteText :item="slotProps.item"> </small-news>
      </template>
    </list-component>
    <div class="text-center pb-5">
      <outlined-button noMargin @click-submit="clickAllNews">
        <template #buttonText>
          {{ $t('news.teaser.button') }}
        </template>
      </outlined-button>
    </div>

    <autoadjusting-row v-if="subText">
      <v-col>
        <v-card
          class="d-flex justify-center pt-10 pt-sm-8 pt-md-5"
          elevation="0"
          color="transparent"
        >
          <p
            class="text-body-1 text-center white--text lower-opacity"
            :style="maxWidthText"
          >
            {{ $t('news.teaser.subText') }}
          </p>
        </v-card>
      </v-col>
    </autoadjusting-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SmallNews from '../news/SmallNews.vue';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import ListComponent from '../partial/ListComponent.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';

//noinspection JSAnnotator
export default {
  components: { AutoadjustingRow, ListComponent, OutlinedButton, SmallNews },
  name: 'ThreeTeasersNews',
  props: {
    newsRelatedToTagId: {
      type: Number,
    },
    subText: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    await this.getNewsItems(this.newsRelatedToTagId);
  },
  computed: {
    ...mapGetters({
      teaserItems: 'news/teaserItems',
    }),
    maxWidthText() {
      return this.$vuetify.breakpoint.sm
        ? 'max-width: 424px;'
        : 'max-width: 554px;';
    },
  },
  methods: {
    ...mapActions({
      getNewsItems: 'news/getNewsItems',
      resetNewsFilterModel: 'news/resetNewsFilterModel',
    }),
    clickAllNews() {
      this.resetNewsFilterModel();
      this.$router.push({
        name: 'News',
      });
    },
  },
};
</script>

<style scoped>
.news-list {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: var(--v-bgdark-base);
}
.lower-opacity {
  opacity: 0.6;
}
</style>
