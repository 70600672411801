<template>
  <v-row
    :no-gutters="$vuetify.breakpoint.xs"
    v-bind="{ ...$attrs }"
    :style="{
      'margin-left': marginWidth() + 'px',
      'margin-right': marginWidth() + 'px',
    }"
  >
    <slot> </slot>
  </v-row>
</template>

<script>
export default {
  name: 'AutoadjustingRow',
  methods: {
    marginWidth() {
      return this.$store.state.mainMarginSize;
    },
  },
};
</script>
