<template>
  <v-row v-if="$vuetify.breakpoint.lgAndUp"
    ><v-col
      ><div
        class="text-body-1"
        v-for="(item, index) in items.slice(0, index1)"
        :key="index"
      >
        {{ item }}
      </div></v-col
    >
    <v-col
      ><div
        class="text-body-1"
        v-for="(item, index) in items.slice(index1, index2)"
        :key="index"
      >
        {{ item }}
      </div></v-col
    ><v-col
      ><div
        class="text-body-1"
        v-for="(item, index) in items.slice(index2, index3)"
        :key="index"
      >
        {{ item }}
      </div></v-col
    >
  </v-row>
  <v-row v-else
    ><v-col
      ><div
        class="text-body-1"
        v-for="(item, index) in items.slice(0, items.length / 2 + 1)"
        :key="index"
      >
        {{ item }}
      </div></v-col
    >
    <v-col
      ><div
        class="text-body-1"
        v-for="(item, index) in items.slice(items.length / 2 + 1)"
        :key="index"
      >
        {{ item }}
      </div></v-col
    >
  </v-row>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    items: Array,
  },
  computed: {
    index1() {
      return this.items.length / 3 + 1;
    },
    index2() {
      return this.index1 * 2 - 1;
    },
    index3() {
      return this.items.length + 1;
    },
  },
};
</script>
