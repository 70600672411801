export default {
  loading: false,
  searchResultsNews: [],
  searchResultsNewsTags: [],
  allNews: [],
  newsFilterModel: { tag: '', year: '' },
  initalItemsToShow: 9,
  numberOfItemsToShow: 9,
  newsTags: [],
  newsTagsLoading: false,
  teaserItems: [],
};
