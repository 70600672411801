<template>
  <div>
    <top-component
      :image-source="
        require('@/assets/images/about/desktop/infuture/infuture_hero_bg.jpg')
      "
      :image-srcset="
        require('@/assets/images/about/mobile/infuture/infuture_hero_bg.jpg')
      "
      scrollTo="#modell"
    >
      <template #overline>{{
        $t('about.subpages.infuture.hero.overline')
      }}</template>
      <template #titleh1>{{
        $t('about.subpages.infuture.hero.title')
      }}</template>
    </top-component>

    <v-card
      id="modell"
      class="rounded-0 elevation-0 py-16"
      style="background-color: white"
    >
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col cols="12" sm="6">
          <text-component
            darkThemed
            centered
            class="mx-auto"
            :max-width="$vuetify.breakpoint.sm ? '329px' : '508px'"
          >
            <template #titleh2>
              {{ $t('about.subpages.infuture.start.title') }}
            </template>
          </text-component>
          <text-component darkThemed centered>
            <template #content>{{
              $t('about.subpages.infuture.start.text')
            }}</template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </v-card>

    <srcset-image
      id="arbetsmodell"
      class="align-center"
      :imageLarge="
        require('@/assets/images/about/desktop/infuture/infuture_arbetsmodell_bg.jpg')
      "
      :imageSmall="
        require('@/assets/images/about/mobile/infuture/infuture_arbetsmodell_bg.jpg')
      "
    >
      <v-container>
        <autoadjusting-row class="my-sm-16">
          <v-col cols="12" sm="6" order="2" order-sm="1" class="py-16 py-sm-0">
            <text-component
              class="mt-md-15 pt-md-15"
              :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            >
              <template #titleh1>{{
                $t('about.subpages.infuture.model.title')
              }}</template>
            </text-component>
            <text-component>
              <template #content>
                <div class="mb-2">
                  {{ $t('about.subpages.infuture.model.text1') }}
                </div>
                <div class="mb-2">
                  {{ $t('about.subpages.infuture.model.text2') }}
                </div>
                <div>{{ $t('about.subpages.infuture.model.text3') }}</div>
              </template>
            </text-component>
          </v-col>
          <v-col cols="12" sm="6" order="1" order-sm="2" class="pt-10 py-sm-0">
            <v-img
              src="@/assets/images/about/_desktop_mobile/infuture/infuture_arbetsmodell.png"
            />
          </v-col>
        </autoadjusting-row>
      </v-container>
    </srcset-image>
    <div class="infuture-page pt-16 pt-sm-10">
      <no-margin-image
        id="unik"
        imageOnLeft
        :imageSource="
          require('@/assets/images/about/_desktop_mobile/infuture/unik_losning.jpg')
        "
        :titleh2="$t('about.subpages.infuture.unique.title')"
      >
        <template #content>
          <div class="mb-4">
            {{ $t('about.subpages.infuture.unique.text1') }}
          </div>
          <div>
            {{ $t('about.subpages.infuture.unique.text2') }}
          </div>
        </template></no-margin-image
      >
      <no-margin-image
        id="kontaktperson"
        :imageSource="
          require('@/assets/images/about/_desktop_mobile/infuture/en_kontakt.jpg')
        "
        :titleh2="$t('about.subpages.infuture.oneContact.title')"
      >
        <template #content>
          <div class="mb-4">
            {{ $t('about.subpages.infuture.oneContact.text1') }}
          </div>
          <div>
            {{ $t('about.subpages.infuture.oneContact.text2') }}
          </div>
        </template>
        ></no-margin-image
      >

      <autoadjusting-row>
        <v-col>
          <v-card flat class="transparent text-center px-5 py-10">
            <h1 class="text-h1 text-center black--text">
              {{ $t('start.project.title1') }}
            </h1>
          </v-card>
        </v-col>
      </autoadjusting-row>

      <two-cols id="helhetssyn">
        <template #first>
          <text-component
            dark-themed
            class="pb-0 pb-sm-16"
            position="left"
            max-width="523"
          >
            <template #titleh2>{{
              $t('about.subpages.infuture.entirety.title')
            }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component dark-themed class="pb-0 pb-sm-16">
            <template #content>
              {{ $t('about.subpages.infuture.entirety.text') }}</template
            >
          </text-component>
        </template>
      </two-cols>

      <two-cols class="py-xl-15 my-xl-15">
        <template #first>
          <text-component
            class="mt-0 pt-0 pb-10 mt-sm-7"
            dark-themed
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
          >
            <template #titleh2>{{
              $t('about.subpages.infuture.services.title')
            }}</template>
            <template #content>{{
              $t('about.subpages.infuture.services.text')
            }}</template>
          </text-component>
        </template>
        <template #second>
          <v-card
            flat
            class="py-4 px-4 py-sm-5 px-sm-5 py-md-10 px-md-10 white--text"
            style="background-color: #003c70"
          >
            <table-component :items="services"></table-component>
          </v-card>
        </template>
      </two-cols>

      <two-cols>
        <template #first>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            class="mt-0 mt-sm-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('about.subpages.infuture.what.title')
            }}</template>
            <template #content>
              <p>{{ $t('about.subpages.infuture.what.text') }}</p>
            </template>
          </text-component>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            class="my-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('about.subpages.infuture.how.title')
            }}</template>
            <template #content>
              <div
                v-for="(item, index) in $t(
                  'about.subpages.infuture.how.list'
                ).split('|')"
                :key="index"
              >
                {{ item }}
              </div>
              <p class="mt-4">{{ $t('about.subpages.infuture.how.text') }}</p>
            </template>
          </text-component>
        </template>
        <template #second>
          <v-img
            class="rounded"
            src="@/assets/images/about/_desktop_mobile/infuture/vad_vi_gor.jpg"
          >
          </v-img>
        </template>
      </two-cols>
    </div>

    <srcset-image
      class="align-center"
      :imageLarge="
        require('@/assets/images/about/desktop/infuture/arbetsmodell_som_planeten_bg.jpg')
      "
      :imageSmall="
        require('@/assets/images/about/mobile/infuture/arbetsmodell_som_planeten_bg.jpg')
      "
    >
      <v-card class="transparent text-center white--text" flat>
        <autoadjusting-row class="mt-16">
          <v-col cols="12">
            <v-img
              height="100"
              width="100"
              class="mx-auto"
              src="@/assets/images/about/_desktop_mobile/infuture/infuture_logo.svg"
            />
          </v-col>
          <v-col cols="12" class="my-8 my-sm-0">
            <h1 class="text-h1">
              {{ $t('about.subpages.infuture.thePlanet.titleh1') }}
            </h1>
          </v-col></autoadjusting-row
        >
        <autoadjusting-row class="mb-16">
          <v-spacer></v-spacer>
          <v-col cols="12" sm="10" md="8">
            <h2 class="text-h2">
              {{ $t('about.subpages.infuture.thePlanet.titleh2-1') }}
            </h2>
            <h2 class="text-h2">
              {{ $t('about.subpages.infuture.thePlanet.titleh2-2') }}
            </h2>
          </v-col>
          <v-spacer></v-spacer>
        </autoadjusting-row>
      </v-card>
    </srcset-image>
    <contact-us></contact-us>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContactUs from '../../components/complete/ContactUs.vue';
import NoMarginImage from '../../components/complete/NoMarginImage.vue';
import TopComponent from '../../components/complete/TopComponent.vue';
import AutoadjustingRow from '../../components/partial/AutoadjustingRow.vue';
import SrcsetImage from '../../components/partial/SrcsetImage.vue';
import TableComponent from '../../components/partial/TableComponent.vue';
import TextComponent from '../../components/partial/TextComponent.vue';
import TwoCols from '../../components/partial/TwoCols.vue';

//noinspection JSAnnotator
export default {
  name: 'InFuture',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  components: {
    TopComponent,
    TextComponent,
    AutoadjustingRow,
    NoMarginImage,
    TwoCols,
    TableComponent,
    ContactUs,
    SrcsetImage,
  },
  computed: {
    services() {
      return this.$t('about.subpages.infuture.services.services').split('|');
    },
    breadcrumbItems() {
      return [
        {
          text: this.$t('about.breadcrumb'),
          name: 'About',
          disabled: false,
        },
        {
          text: this.$t('about.subpages.infuture.breadcrumb'),
          name: 'InFuture',
          disabled: true,
        },
        {
          text: this.$t('about.subpages.infuture.model.breadcrumb'),
          name: '#arbetsmodell',
          disabled: false,
        },
        {
          text: this.$t('about.subpages.infuture.unique.breadcrumb'),
          name: '#unik',
          disabled: false,
        },
        {
          text: this.$t('about.subpages.infuture.oneContact.breadcrumb'),
          name: '#kontaktperson',
          disabled: false,
        },
        {
          text: this.$t('about.subpages.infuture.entirety.breadcrumb'),
          name: '#helhetssyn',
          disabled: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
};
</script>

<style lang="scss">
.infuture-page {
  background-color: var(--v-bgabout-base);
}
</style>
