<template>
  <div>
    <template v-for="(item, index) in visibleItems">
      <three-cols
        :key="item.id"
        v-if="
          (!$vuetify.breakpoint.sm || forceThreeColsOnSm) && index % 3 === 0
        "
      >
        <template
          v-for="(item, index2) in getThreeItems(index)"
          #[slots[index2]]
        >
          <slot :item="item"></slot>
        </template>
      </three-cols>
      <two-cols
        :key="item.id"
        v-if="$vuetify.breakpoint.sm && !forceThreeColsOnSm && index % 2 === 0"
      >
        <template v-for="(item, index2) in getTwoItems(index)" #[slots[index2]]>
          <slot :item="item"></slot>
        </template>
      </two-cols>
      <slot name="featuredProject" v-if="index === 2"></slot>
    </template>

    <div
      v-if="numberOfItemsToShow < this.items.length"
      class="text-center pb-5 mb-5 pb-md-15 mb-md-15"
    >
      <outlined-button class="mt-0" darkContent down @click-submit="showMore">
        <template #buttonText>
          {{ $t('global.showMore') }}
        </template>
      </outlined-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ThreeCols from './ThreeCols.vue';
import OutlinedButton from './OutlinedButton.vue';
import TwoCols from './TwoCols.vue';

//noinspection JSAnnotator
export default {
  name: 'ListComponent',
  props: {
    items: { type: Array },
    showFeaturedProject: { type: Boolean, default: false },
    forceThreeColsOnSm: { type: Boolean, default: false },
  },
  components: {
    ThreeCols,
    OutlinedButton,
    TwoCols,
  },
  data() {
    return {
      slots: ['first', 'second', 'third'],
      loadMoreNumber: 9,
    };
  },
  computed: {
    ...mapGetters({
      numberOfItemsToShow: 'news/numberOfItemsToShow',
    }),
    visibleItems() {
      return this.items.slice(0, this.numberOfItemsToShow);
    },
  },
  methods: {
    ...mapActions({
      setNumberOfItemsToShow: 'news/setNumberOfItemsToShow',
    }),
    showMore() {
      this.setNumberOfItemsToShow(
        this.numberOfItemsToShow + this.loadMoreNumber
      );
    },
    getThreeItems(index) {
      return this.items.slice(index, index + 3);
    },
    getTwoItems(index) {
      return this.items.slice(index, index + 2);
    },
  },
};
</script>

<style scoped>
.v-application a {
  color: inherit;
  text-decoration: none;
}
</style>
