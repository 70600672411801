import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuItems: [
      { title: 'Om Incoord', routeName: 'About', topMenu: true },
      { title: 'Projekt', routeName: 'Project', topMenu: true },
      { title: 'Hållbarhet', routeName: 'Sustainability', topMenu: true },
      { title: 'Nyheter', routeName: 'News', topMenu: true },
      { title: 'Karriär', routeName: 'Career', topMenu: true },
      { title: 'Kontakt', routeName: 'Contact', topMenu: true },
      { title: 'In English', routeName: 'InEnglish', topMenu: true },
      { title: 'Integritetspolicy', routeName: 'Privacy', topMenu: false },
    ],
    mainMarginSize: 30,
    maxContentWidth: 1396,
    minMargin: 30,
    mobileBreakpoint: 600,
    mobileMargin: 23,
    allProjects: [],
    filteredProjects: [],
    projectTeaserItems: [],
    searchFieldContents: '',
    searchResults: [],
    projectsUrl: 'projects/published',
    projectsSearchUrl: 'projects/search/',
    newsSearchUrl: 'news/search/',
    popularSearchesUrl: 'search/popular',
    featuredProjectsUrl: 'projects/featured',
    projectCategoriesUrl: 'projects/categories',
    projectTagsUrl: 'projects/tags',
    getProjectsWithTagUrl: 'projects/tags/{}/projects',
    queryStringParam: 'page_size',
    projectImageUrl: '/img/projects/',
    newsImageUrl: '/img/articles/',
    newslettersUrl: 'newsletters',
    postContactUrl: 'contact',
    showingProjectResults: false,
    loading: true,
    sortNewestFirst: true,
    routingKey: 0,
    idOfNewsSustainabilityTag: Number(
      process.env.VUE_APP_ID_NEWSTAG_SUSTAINABILITY
    ),
    idOfProjectSustainabilityTag:
      process.env.VUE_APP_ID_PROJECTTAG_SUSTAINABILITY,
    currentSubHeaderComponent: null,
    currentPageBreadCrumbs: [],
    showProjectFilters: false,
    showOverlay: false,
    publicPath: process.env.BASE_URL,
  },
  getters: {
    topMenuItems: (state) => {
      return state.menuItems.filter((item) => item.topMenu);
    },
    getNewsItem: (state) => (params) => {
      if (params.id) {
        return state.allNews.find((item) => item.id === params.id);
      } else if (params.slug) {
        return state.allNews.find((item) => item.slug === params.slug);
      } else {
        return {};
      }
    },
    apiBaseUrl: () => {
      const apiUrl = process.env.VUE_APP_API_BASE_URL;
      return apiUrl.endsWith('/') ? apiUrl : apiUrl + '/';
    },
    backendUrl: () => {
      const backendUrl = process.env.VUE_APP_API_BASE_URL;
      return backendUrl.substring(0, backendUrl.length - 4);
    },
    getCurrentSubHeaderComponent: (state) => {
      return state.currentSubHeaderComponent;
    },
    getCurrentPageBreadCrumbs: (state) => {
      return state.currentPageBreadCrumbs;
    },
  },
  actions: {
    getProjectTeaserItems({ getters, commit }, relatedToId) {
      let url = '';
      if (relatedToId) {
        url = `projects/tags/${relatedToId}/projects?page_size=3`;
      } else {
        url = this.state.projectsUrl + '?page_size=3';
      }
      axios.get(getters.apiBaseUrl + url).then(
        (response) => {
          commit('setProjectTeaserItems', response.data);
        },
        (error) => {
          console.log(error);
          //handle error here
        }
      );
    },
    getNewsItems({ getters }, relatedToId) {
      let url;
      if (relatedToId) {
        url = `news/tags/${relatedToId}/news?page_size=3`;
      } else {
        url = this.state.newsUrl + '?page_size=3';
      }
      return axios.get(getters.apiBaseUrl + url).then(
        (response) => {
          return response.data;
        },
        (error) => {
          console.log(error);
          //handle error here
        }
      );
    },
    getPopularSearches({ getters }) {
      return axios.get(getters.apiBaseUrl + this.state.popularSearchesUrl).then(
        (response) => {
          return response.data;
        },
        (error) => {
          console.log(error);
          //handle error here
        }
      );
    },
    getListOfNewsletterFiles({ getters }) {
      return axios.get(getters.apiBaseUrl + this.state.newslettersUrl).then(
        (response) => {
          return response.data;
        },
        (error) => {
          console.log(error);
          //handle error here
        }
      );
    },
    searchNews({ getters, commit }, searchFor) {
      return axios
        .get(getters.apiBaseUrl + this.state.newsSearchUrl + searchFor)
        .then(
          (response) => {
            commit('setSearchResultsNews', response.data);
          },
          (error) => {
            console.log(error);
            //handle error here
          }
        );
    },
  },
  mutations: {
    setMainMarginSize(state, newWidth) {
      if (newWidth < state.mobileBreakpoint) {
        state.mainMarginSize = state.mobileMargin;
      } else {
        state.mainMarginSize =
          newWidth > state.maxContentWidth + state.minMargin * 2
            ? (newWidth - state.maxContentWidth) / 2
            : state.minMargin;
      }
    },
    initializeProjects(state, projects) {
      state.allProjects = projects;
      state.filteredProjects = projects;
    },
    updateFilteredProjects(state, filteredProjects) {
      state.filteredProjects = filteredProjects;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    resetNumberOfItemsToShow(state) {
      state.numberOfItemsToShow = state.initalItemsToShow;
    },
    setSearchResultsProjectsTags(state, value) {
      state.searchResultsProjectsTags = value;
    },
    setSearchResultsNewsTags(state, value) {
      state.searchResultsNewsTags = value;
    },
    setRoutingKey(state) {
      state.routingKey = state.routingKey + 1;
    },
    setAllNews(state, value) {
      state.allNews = value;
    },
    setNewsFilterModel(state, value) {
      state.newsFilterModel = value;
    },
    setProjectTeaserItems(state, value) {
      state.projectTeaserItems = value;
    },
    setShowProjectFilters(state, value) {
      state.showProjectFilters = value;
    },
    setShowOverlay(state, value) {
      state.showOverlay = value;
    },
  },
  modules,
});
