<template>
  <v-container>
    <autoadjusting-row
      v-if="$vuetify.breakpoint.xs"
      :no-gutters="$vuetify.breakpoint.xs"
      ><v-col align-self="center">
        <h3 class="text-h3 mr-auto">{{ $t('news.breadcrumb') }}</h3>
      </v-col>
      <v-spacer></v-spacer>
      <v-col align-self="center">
        <v-select
          dense
          hide-details
          flat
          prepend-inner-icon="$arrowDown"
          v-model="yearFilterModel"
          :items="getYears"
          single-line
          class="text-h3 black--text fit"
          item-color="black"
          color="black"
        >
        </v-select>
      </v-col>
    </autoadjusting-row>
    <autoadjusting-row :no-gutters="$vuetify.breakpoint.xs">
      <v-col cols="auto">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="mt-md-1 mt-lg-2">
          <span class="text-body-1"> {{ $t('news.filter.filter') }}</span>
        </div>
      </v-col>
      <v-col cols="12" sm="9" class="col-auto mr-auto">
        <v-chip-group
          v-model="filterModel"
          mandatory
          active-class="white--text"
          class="d-inline-block"
        >
          <v-chip
            small
            v-for="item in getNewsTags"
            :key="item.title"
            :value="item.title"
            class="rounded"
            :color="getNewsTagColor(item.id)"
            text-color="black"
            :outlined="filterModel !== item.title"
          >
            {{ item.title }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-spacer></v-spacer>
      <v-col sm="2" class="col-auto" v-if="$vuetify.breakpoint.smAndUp">
        <v-select
          dense
          hide-details
          flat
          prepend-inner-icon="$arrowDown"
          v-model="yearFilterModel"
          :items="getYears"
          single-line
          append-icon=""
          class="text-h3 black--text fit"
          item-color="black"
          color="black"
        >
        </v-select>
      </v-col>
    </autoadjusting-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import loadNewsTagsMixin from '../../mixins/loadNewsTagsMixin.js';

//noinspection JSAnnotator
export default {
  components: { AutoadjustingRow },
  name: 'FilterComponent',
  mixins: [loadNewsTagsMixin],

  computed: {
    ...mapGetters({
      getNewsYears: 'news/getNewsYears',
      newsFilterModel: 'news/newsFilterModel',
    }),
    getNewsTags() {
      return [
        ...[{ id: -1, title: this.$t('news.filter.allTags') }],
        ...this.newsTags,
      ];
    },
    getYears() {
      return [...[this.$t('news.filter.allYears')], ...this.getNewsYears];
    },
    filterModel: {
      get() {
        return this.newsFilterModel.tag
          ? this.newsFilterModel.tag
          : this.$t('news.filter.allTags');
      },
      set(newValue) {
        newValue = newValue === this.$t('news.filter.allTags') ? '' : newValue;
        this.setNewsFilterModel({
          ...this.newsFilterModel,
          ...{ tag: newValue },
        });
      },
    },
    yearFilterModel: {
      get() {
        return this.newsFilterModel.year
          ? this.newsFilterModel.year
          : this.$t('news.filter.allYears');
      },
      set(newValue) {
        newValue = newValue === this.$t('news.filter.allYears') ? '' : newValue;
        this.setNewsFilterModel({
          ...this.newsFilterModel,
          ...{ year: newValue },
        });
      },
    },
  },
  methods: {
    ...mapActions({
      setNewsFilterModel: 'news/setNewsFilterModel',
    }),
  },
};
</script>

<style scoped>
.row >>> .v-icon__component {
  height: 10px !important;
  width: 12px !important;
}
.v-text-field.v-input--dense >>> .v-input__prepend-inner,
.v-text-field.v-input--dense >>> .v-input__append-inner {
  /* margin-top: 3px; */
}
.v-select.fit {
  width: 150px;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
</style>
