import { mapGetters, mapMutations } from 'vuex';
import * as helper from '../js/helper.js';

//noinspection JSAnnotator
export default {
  computed: {
    ...mapGetters({
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
    }),
  },
  methods: {
    ...mapMutations({
      updateProjectCategories: 'project/updateProjectCategories',
      updateProjectTags: 'project/updateProjectTags',
      setShowingProjectResults: 'project/setShowingProjectResults',
      setSortNewestFirst: 'project/setSortNewestFirst',
      setSearchFieldContents: 'setSearchFieldContents',
      setSearchResults: 'setSearchResults',
      resetNumberOfItemsToShow: 'resetNumberOfItemsToShow',
    }),
    resetProjectFilters() {
      this.setSearchFieldContents('');
      this.setSearchResults([]);
      this.setSortNewestFirst(true);
      this.setShowingProjectResults(false);
      this.resetNumberOfItemsToShow();
      this.clearTagFilters();
    },
    clearTagFilters() {
      this.updateProjectCategories(
        helper.setAllSelected(this.projectCategories)
      );
      this.updateProjectTags(helper.setAllSelected(this.projectTags));
    },
  },
};
