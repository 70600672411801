<template>
  <div>
    <search-tags-results
      v-if="searchResultsCats.length > 0"
      :results="searchResultsCats"
      resultsType="categories"
      @tag-clicked="clickTag"
    >
      <template #title>{{ $t('search.showProjectsWithTag') }}</template>
    </search-tags-results>
    <search-tags-results
      v-if="searchResultsTags.length > 0"
      :results="searchResultsTags"
      resultsType="tags"
      @tag-clicked="clickTag"
    >
      <template #title>{{ $t('search.showProjectsWithTag') }}</template>
    </search-tags-results>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SearchTagsResults from './SearchTagsResults.vue';
import loadProjectCategoriesAndTagsMixin from '../../mixins/loadProjectCategoriesAndTagsMixin.js';

//noinspection JSAnnotator
export default {
  name: 'SearchProjectTags',
  components: { SearchTagsResults },
  mixins: [loadProjectCategoriesAndTagsMixin],

  props: ['searchFor'],
  watch: {
    searchFor: {
      immediate: true,
      handler: function (newVal) {
        if (!newVal) {
          this.resetResults();
        } else {
          this.search();
        }
      },
    },
  },
  data() {
    return {
      searchResultsTags: [],
      searchResultsCats: [],
    };
  },
  computed: {
    ...mapGetters({
      searchResultsProjectsTags: 'project/searchResultsProjectsTags',
    }),
  },
  methods: {
    ...mapActions({
      setSearchResultsProjectsTags: 'project/setSearchResultsProjectsTags',
      setShowingProjectResults: 'project/setShowingProjectResults',
    }),
    searchProjectsCats(cats, searchWord) {
      return cats.filter((item) =>
        item.title.toLowerCase().startsWith(searchWord)
      );
    },
    closeSearch() {
      this.$emit('close-search');
    },
    resetResults() {
      this.searchResultsTags = [];
      this.searchResultsCats = [];
      this.setSearchResultsProjectsTags([]);
    },
    search() {
      this.searchResultsCats = this.searchProjectsCats(
        this.projectCategories,
        this.searchFor
      );
      this.searchResultsTags = this.searchProjectsCats(
        this.projectTags,
        this.searchFor
      );
      this.setSearchResultsProjectsTags(
        this.searchResultsTags.concat(this.searchResultsCats)
      );
      this.$emit('loading-done', true);
    },
    clickTag(clicked, type) {
      let arrayOfCats = this.projectCategories.map((cat) => {
        return { ...cat, selected: false };
      });
      let arrayOfTags = this.projectTags.map((tag) => {
        return { ...tag, selected: false };
      });
      if (type === 'categories') {
        arrayOfCats.find((item) => item.id === clicked.id).selected = true;
        arrayOfTags[0].selected = true;
      } else {
        arrayOfTags.find((item) => item.id === clicked.id).selected = true;
        arrayOfCats[0].selected = true;
      }
      this.updateProjectCategories(arrayOfCats);
      this.updateProjectTags(arrayOfTags);
      this.closeSearch();
      this.setShowingProjectResults(true);
      this.$router.push({
        name: 'Project',
      });
    },
  },
};
</script>
