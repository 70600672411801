<template>
  <v-container>
    <top-news-and-projects
      :tags="project.categories"
      :image-large="getCategoryBackground()"
      :image-small="getCategoryBackground(true)"
      :blackCard="false"
    >
      <template #title>{{ project.title }}</template>
      <template #rightCardContent v-if="project.tags.length > 0">
        <v-card-title class="text-h3">
          {{ $t('global.services') }}
        </v-card-title>
        <v-card-text class="black--text text-body-1">
          {{ tagsOfProject }}
        </v-card-text>
      </template>
    </top-news-and-projects>

    <two-cols>
      <template #first>
        <v-row>
          <v-col>
            <h3 class="text-h3">{{ $t('project.customer') }}</h3>
            <p class="text-body-1">
              {{ project.client }}
            </p>
          </v-col>
          <v-col>
            <h3 class="text-h3">{{ $t('project.area') }}</h3>
            <p class="text-body-1">
              {{ project.area }}
            </p>
          </v-col>
        </v-row>
        <div class="text-h2 mt-10 mb-5">
          <h2 v-if="project.extra_title">{{ project.extra_title }}</h2>
          <h2 v-else>{{ $t('project.shortAbout') }}</h2>
        </div>

        <div v-html="project.content"></div>
      </template>
      <template #second>
        <image-grid
          :main-image="project.image"
          :images="project.images"
        ></image-grid>
      </template>
    </two-cols>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TopNewsAndProjects from '@/components/complete/TopNewsAndProjects.vue';
import TwoCols from '@/components/partial/TwoCols.vue';
import ImageGrid from './ImageGrid.vue';
import { projectBackgroundImages } from '@/utils/constants';

//noinspection JSAnnotator
export default {
  components: { TwoCols, ImageGrid, TopNewsAndProjects },
  name: 'BigProject',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  updated() {
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },

  computed: {
    ...mapGetters({
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
      selectedCategories: 'project/selectedCategories',
    }),
    categoryTitel() {
      return this.getSelectedProjectCategory.title || '';
    },
    projectTitel() {
      return this.project.title || '';
    },
    breadcrumbItems() {
      return [
        {
          text: this.$t('project.breadcrumb'),
          name: 'Project',
          disabled: false,
        },
        {
          text: this.categoryTitel,
          disabled: true,
          thinText: true,
        },
        {
          text: this.projectTitel,
          name: 'title',
          disabled: true,
        },
      ];
    },
    firstProjectCategory() {
      return this.project.categories[0];
    },
    firstSelectedCategory() {
      return this.selectedCategories[0];
    },
    hasOnlyOneSelectedCategory() {
      return this.selectedCategories.length === 1;
    },
    selectedCategoryIsDefault() {
      return this.firstSelectedCategory.id === -1;
    },
    getSelectedProjectCategory() {
      const selectedCats = this.selectedCategories;
      if (this.hasOnlyOneSelectedCategory && this.selectedCategoryIsDefault) {
        return this.firstProjectCategory;
      }
      return selectedCats.find((cat) => {
        return this.project.categories.find((item) => item.id === cat.id);
      });
    },
    tagsOfProject: function () {
      return this.project.tags.map((item) => item.title).join(', ');
    },
  },
  methods: {
    ...mapActions({
      updateProjectCategories: 'project/updateProjectCategories',
      updateProjectTags: 'project/updateProjectTags',
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
    getCategoryBackground(isMobile) {
      const { slug } = this.getSelectedProjectCategory || {};
      if (!slug) {
        return isMobile
          ? projectBackgroundImages.bostaderMobile
          : projectBackgroundImages.bostaderDesktop;
      }
      if (isMobile) {
        return projectBackgroundImages[`${slug}Mobile`];
      }
      return projectBackgroundImages[`${slug}Desktop`];
    },
  },
};
</script>
