export default {
  loading: (state) => state.loading,
  searchResultsNews: (state) => state.searchResultsNews,
  searchResultsNewsTags: (state) => state.searchResultsNewsTags,
  allNews: (state) => state.allNews,
  newsFilterModel: (state) => state.newsFilterModel,
  getNewsYears: (state) => {
    return state.allNews
      .map((item) => {
        return item.date.substring(0, 4);
      })
      .filter((value, index, array) => array.indexOf(value) === index);
  },
  newsTags: (state) => state.newsTags,
  newsTagsLoading: (state) => state.newsTagsLoading,
  numberOfItemsToShow: (state) => state.numberOfItemsToShow,
  getNewsItem: (state) => (params) => {
    if (params.id) {
      return state.allNews.find((item) => item.id === params.id);
    } else if (params.slug) {
      return state.allNews.find((item) => item.slug === params.slug);
    } else {
      return {};
    }
  },
  getNewsList: (state) => (filterTag, filterYear) => {
    if (!filterTag && !filterYear) {
      return state.allNews;
    } else {
      let filteredNews = state.allNews;
      if (filterTag) {
        filteredNews = state.allNews.filter((item) =>
          item.tags.some((tag) => tag.title === filterTag)
        );
      }
      if (filterYear) {
        filteredNews = filteredNews.filter((item) =>
          item.date.startsWith(filterYear)
        );
      }
      return filteredNews;
    }
  },
  teaserItems: (state) => state.teaserItems,
};
