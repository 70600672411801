<template>
  <div>
    <search-tags-results
      v-if="searchResultsNewsTags.length > 0"
      :results="searchResultsNewsTags"
      resultsType="news"
      @tag-clicked="clickTag"
    >
      <template #title>{{ $t('search.showNewsWithTag') }}</template>
    </search-tags-results>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import SearchTagsResults from './SearchTagsResults.vue';
import loadNewsTagsMixin from '../../mixins/loadNewsTagsMixin.js';

export default {
  components: { SearchTagsResults },
  name: 'SearchNewsTags',
  props: ['searchFor'],
  mixins: [loadNewsTagsMixin],
  watch: {
    searchFor: {
      immediate: true,
      handler: function (newVal) {
        if (!newVal) {
          this.resetResults();
        } else {
          this.search();
        }
      },
    },
  },
  computed: {
    ...mapState(['searchResultsNewsTags']),
  },
  methods: {
    ...mapMutations(['setSearchResultsNewsTags', 'setNewsFilterModel']),
    closeSearch() {
      this.$emit('close-search');
    },
    resetResults() {
      this.setSearchResultsNewsTags([]);
    },
    search() {
      this.setSearchResultsNewsTags(
        this.newsTags.filter((item) =>
          item.title.toLowerCase().startsWith(this.searchFor)
        )
      );
      this.$emit('loading-done', true);
    },
    clickTag(clickedTag) {
      this.closeSearch();
      this.setNewsFilterModel({
        ...this.newsFilterModel,
        ...{ tag: clickedTag.title },
      });
      this.$router.push({
        name: 'News',
      });
    },
  },
};
</script>
