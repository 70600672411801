<template>
  <v-container>
    <top-component v-if="!showingProjectResults" :image-source="require('@/assets/images/project/desktop/projekt_hero_bg.jpg')
      " :image-srcset="require('@/assets/images/project/mobile/projekt_hero_bg.jpg')
    " scrollTo="#startScrollTo">
      <template #overline>{{ $t('project.hero.overline') }}</template>
      <template #titleh1>{{ $t('project.hero.text') }}</template>
      /></top-component>
    <loading-indicator v-if="loading || loadingFeatured"></loading-indicator>
    <div id="startScrollTo" class="pt-4 pt-sm-0" v-else>
      <list-component :items="listOfProjects">
        <template v-slot:default="slotProps">
          <small-project :project="slotProps.item"> </small-project>
        </template>
        <template #featuredProject v-if="!showingProjectResults && featuredProject">
          <featured-project :featuredProject="featuredProject"></featured-project>
        </template>
      </list-component>
    </div>
    <contact-us></contact-us>
  </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import TopComponent from '../../components/complete/TopComponent.vue';
import ContactUs from '../../components/complete/ContactUs.vue';
import ListComponent from '../../components/partial/ListComponent.vue';
import LoadingIndicator from '../../components/complete/LoadingIndicator.vue';
import SmallProject from '../../components/project/list/SmallProject.vue';
import featuredProjectMixin from '../../mixins/featuredProjectMixin.js';
import FeaturedProject from '../../components/complete/FeaturedProject.vue';

//noinspection JSAnnotator
export default {
  name: 'ProjectList',
  components: {
    TopComponent,
    ContactUs,
    ListComponent,
    LoadingIndicator,
    SmallProject,
    FeaturedProject,
  },
  mixins: [featuredProjectMixin],
  data() {
    return {
      lastScrollTop: 0,
    };
  },
  created() {
    if (!this.showingProjectResults) {
      this.getFeaturedProject();
    }
    this.updateSubHeader('search-project-component');
  },
  computed: {
    ...mapGetters({
      showingProjectResults: 'project/showingProjectResults',
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
      loading: 'project/loading',
      filteredProjects: 'project/filteredProjects',
    }),
    listOfProjects() {
      const selectedCategories = this.projectCategories.filter(item => item.selected);
      const tempList = this.filteredProjects.filter(project => {
        const cids = project.categories.map(item => item.id);
        return selectedCategories.some(category => category.id === -1 || cids.includes(category.id));
      });

      if (!this.showingProjectResults && this.featuredProject) {
        return tempList.filter(project => project.id !== this.featuredProject.id);
      }

      return tempList;
    },
    loadingFeatured() {
      return this.showingProjectResults ? false : !this.featuredProjectsLoaded;
    },
  },
  methods: {
    ...mapActions({
      updateSubHeader: 'app/updateCurrentSubHeaderComponent',
      updateFilteredProjects: 'project/updateFilteredProjects',
    }),
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  &.v-text-field--enclosed {
    &:not(.v-text-field--rounded) {
      >.v-input__control {
        >.v-input__slot {
          padding: 0 20px !important;
        }
      }
    }
  }
}

.v-chip {
  &.v-chip--outlined {
    border-width: 1px;
    border-color: #c4c4c4;
  }

  &.v-size--default {
    height: 26px;
  }
}
</style>
