<template>
  <srcset-image class="align-center" :imageLarge="imageSource" :imageSmall="imageSrcset" :max-height="getImageHeight">
    <template>
      <v-container>
        <autoadjusting-row>
          <v-spacer> </v-spacer>
          <v-col cols="12" :sm="wider ? '10' : '9'" :xl="wider ? '10' : '8'" align="center">
            <v-card class="rounded-0 elevation-0 px-5 py-10 transparent" :max-width="getTextWidth">
              <h1>
                <v-img v-if="addLogo" class="mx-auto my-10" :max-width="getLogoWidth" alt="incoord 40 år"
                  src="@/assets/images/forty/_desktop_mobile/incoord_40_logo.svg" />
              </h1>
              <h2 v-if="$slots.overline && $vuetify.breakpoint.mdAndUp" class="text-h2 mb-5" :class="getThemedTextClass">
                <slot name="overline"></slot>
              </h2>
              <h2 v-if="$slots.overline2" class="text-h2 mb-5" :class="getThemedTextClass">
                <slot name="overline2"></slot>
              </h2>
              <h2 v-if="$slots.titleh2" class="text-h2 mt-5" :class="getThemedTextClass">
                <slot name="titleh2"></slot>
              </h2>
              <h1 v-if="$slots.titleh1" class="text-h1" :class="getThemedTextClass">
                <slot name="titleh1"></slot>
              </h1>
              <v-card-actions class="pt-10 pt-sm-8 pt-lg-10">
                <v-img v-if="scrollTo" :max-width="$vuetify.breakpoint.sm ? '35' : '67'" :src="getThemedButtonSource"
                  @click="scrollToElement" class="mx-auto cursor-pointer">
                </v-img>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-spacer> </v-spacer>
        </autoadjusting-row>
      </v-container>
    </template>
  </srcset-image>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import SrcsetImage from '../partial/SrcsetImage.vue';
export default {
  components: { AutoadjustingRow, SrcsetImage },
  name: 'TopForty',
  props: {
    darkThemed: Boolean,
    centered: Boolean,
    wider: Boolean,
    addLogo: { type: Boolean, default: false },
    imageSource: {
      type: String,
    },
    imageSrcset: {
      type: String,
    },
    scrollTo: {
      type: String,
    },
  },
  computed: {
    commonAttrs() {
      return {
        class: 'px-5 transparent',
        elevation: 0,
      };
    },
    getThemedTextClass() {
      let classes = 'px-0 text-center';
      return classes.concat(
        ' ',
        this.darkThemed ? 'black--text' : 'white--text'
      );
    },
    getThemedButtonSource() {
      return this.darkThemed
        ? require('@/assets/images/global/_desktop_mobile/hero_chevron_down_bl.svg')
        : require('@/assets/images/global/_desktop_mobile/hero_chevron_down_wh.svg');
    },
    getTextWidth() {
      if (this.wider) {
        return this.$vuetify.breakpoint.sm ? 464 : 1118;
      } else {
        return this.$vuetify.breakpoint.sm ? 464 : 882;
      }
    },

    getImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 750;
        case 'sm':
          return 835;
        default:
          return 1030;
      }
    },
    getLogoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 200;
        case 'sm':
          return 300;
        case 'md':
          return 300;
        default:
          return 300;
      }
    },
  },
  methods: {
    scrollToElement() {
      this.$vuetify.goTo(this.scrollTo, { offset: 0, duration: 300 });
    },
  },
};
</script>