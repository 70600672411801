<template>
  <div>
    <top-component
      :image-source="
        require('@/assets/images/career/desktop/karriar_hero_bg.jpg')
      "
      :image-srcset="
        require('@/assets/images/career/mobile/karriar_hero_bg.jpg')
      "
      scrollTo="#jobb"
    >
      <template #overline>{{ $t('career.hero.overline') }}</template>
      <template #titleh1>{{ $t('career.hero.title') }}</template>
      ></top-component
    >

    <v-card
      id="jobb"
      class="rounded-0 elevation-0 py-16"
      style="background-color: white"
    >
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col sm="10" lg="8">
          <text-component dark-themed centered>
            <template #overline>{{ $t('career.jobs.overline') }}</template>
            <template #titleh2>{{ $t('career.jobs.title') }}</template>
            <template #actions>
              <outlined-button down darkContent @click-submit="scrollToApply">
                <template #buttonText>
                  {{ $t('career.apply') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </v-card>

    <div style="background-color: #fafaf8">
      <two-cols>
        <template #second>
          <text-component
            id="exjobb"
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            class="mt-10"
            dark-themed
          >
            <template #overline>{{ $t('career.thesis.overline') }}</template>
            <template #titleh2>{{ $t('career.thesis.title') }}</template>
            <template #content>
              <p>{{ $t('career.thesis.text') }}</p>
            </template>
            <template #actions>
              <outlined-button down darkContent @click-submit="scrollToApply">
                <template #buttonText>
                  {{ $t('career.apply') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/career/_desktop_mobile/exjobb.png"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols>
        <template #[slots(1)]>
          <text-component
            id="praktik"
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            class="mt-10"
            dark-themed
          >
            <template #overline>{{
              $t('career.internship.overline')
            }}</template>
            <template #titleh2>{{ $t('career.internship.title') }}</template>
            <template #content>
              <p>{{ $t('career.internship.text') }}</p>
            </template>
            <template #actions>
              <outlined-button down darkContent @click-submit="scrollToApply">
                <template #buttonText>
                  {{ $t('career.apply') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #[slots(2)]>
          <v-img
            class="rounded"
            src="@/assets/images/career/_desktop_mobile/praktik.jpg"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols>
        <template #second>
          <text-component
            id="sommarjobb"
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            class="mt-10"
            dark-themed
          >
            <template #overline>{{ $t('career.summerJob.overline') }}</template>
            <template #titleh2>{{ $t('career.summerJob.title') }}</template>
            <template #content>
              <p>{{ $t('career.summerJob.text') }}</p>
            </template>
            <template #actions>
              <outlined-button down darkContent @click-submit="scrollToApply">
                <template #buttonText>
                  {{ $t('career.apply') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/career/_desktop_mobile/sommarjobb.png"
          >
          </v-img>
        </template>
      </two-cols>

      <!-- Hidden 21-12-09 by request. Note that route and breadcrumb also are commented out ->
        <two-cols>
        <template #[slots(1)]>
          <text-component
            id="intervju"
            :max-width="$vuetify.breakpoint.sm ? '329px' : '404px'"
            class="mt-10"
            dark-themed
          >
            <template #overline>{{
              $t('career.interviewTeaser.overline')
            }}</template>
            <template #titleh2>{{
              $t('career.interviewTeaser.title')
            }}</template>
            <template #content>
              <p>{{ $t('career.interviewTeaser.text') }}</p>
            </template>
            <template #actions>
              <outlined-button darkContent :to="{ name: 'Interview' }">
                <template #buttonText>
                  {{ $t('career.interviewTeaser.button') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #[slots(2)]>
          <v-img
            class="rounded"
            src="@/assets/images/career/_desktop_mobile/jobba_pa_incoord_placeholder.jpg"
          >
          </v-img>
        </template>
      </two-cols> -->
    </div>
    <apply-for-job></apply-for-job>
  </div>
</template>

<script>
import ApplyForJob from '../components/complete/ApplyForJob.vue';
import TopComponent from '../components/complete/TopComponent.vue';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import OutlinedButton from '../components/partial/OutlinedButton.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TwoCols from '../components/partial/TwoCols.vue';
import { mapActions } from 'vuex';

//noinspection JSAnnotator
export default {
  components: {
    TopComponent,
    TextComponent,
    OutlinedButton,
    AutoadjustingRow,
    TwoCols,
    ApplyForJob,
  },
  name: 'Career',
  data() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('career.breadcrumb'),
          name: 'Career',
          disabled: true,
        },
        {
          text: this.$t('career.jobs.overline'),
          name: '#jobb',
          disabled: false,
        },
        {
          text: this.$t('career.thesis.overline'),
          name: '#exjobb',
          disabled: false,
        },
        {
          text: this.$t('career.internship.overline'),
          name: '#praktik',
          disabled: false,
        },
        {
          text: this.$t('career.summerJob.overline'),
          name: '#sommarjobb',
          disabled: false,
        },
        {
          text: this.$t('career.apply'),
          name: '#ansok',
          disabled: false,
        },
      ],
    };
  },
  created() {
    this.updateSubHeader('page-breadcrumbs');
    this.updateBreadCrumbs(this.breadcrumbItems);
  },
  methods: {
    ...mapActions({
      updateSubHeader: 'app/updateCurrentSubHeaderComponent',
      updateBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
    scrollToApply() {
      this.$vuetify.goTo('#ansok');
    },
    slots(val) {
      if (val === 1) {
        if (this.$vuetify.breakpoint.xs) {
          return 'second';
        } else {
          return 'first';
        }
      } else {
        if (this.$vuetify.breakpoint.xs) {
          return 'first';
        } else {
          return 'second';
        }
      }
    },
  },
};
</script>
