<template>
  <router-link
    :to="{
      name: 'NewsDetails',
      params: { slug: item.slug, id: item.id },
    }"
  >
    <v-img :src="getMainImageSource" :height="getImageHeight">
      <div class="mt-2 ml-2">
        <template v-for="tag in item.tags">
          <v-chip
            small
            class="mr-1 rounded-sm white--text text-body-2"
            :color="getNewsTagColor(tag.id)"
            :key="tag.id"
          >
            {{ tag.title }}
          </v-chip>
        </template>
      </div>
    </v-img>
    <div class="mt-4 mb-10 ml-3">
      <div
        class="text-body-1"
        :class="whiteText ? 'white--text' : 'black--text'"
      >
        {{ item.date }}
      </div>
      <h3 class="text-h3" :class="whiteText ? 'white--text' : 'black--text'">
        {{ item.title }}
      </h3>
    </div>
  </router-link>
</template>

<script>
import * as helper from '../../js/helper.js';
import { mapGetters, mapState } from 'vuex';
import loadNewsTagsMixin from '../../mixins/loadNewsTagsMixin.js';

export default {
  props: {
    item: {
      type: Object,
    },
    whiteText: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [loadNewsTagsMixin],

  computed: {
    getImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 185;
        case 'sm':
          return 185;
        default:
          return 240;
      }
    },

    ...mapGetters(['backendUrl']),
    ...mapState(['newsImageUrl']),
    getMainImageSource: function () {
      const image = { dir: this.newsImageUrl, filename: this.item.image };
      return helper.getImageUrl(image, this.backendUrl);
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: inherit;
  text-decoration: none;
}
</style>
