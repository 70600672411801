<template>
  <v-sheet>
    <label class="text-body-1" :for="id">
      {{ label }}
    </label>
    <v-chip-group v-model="currentValue" column multiple :mandatory="required">
      <v-chip
        filter
        v-for="option in options"
        :key="`${id}-option-${option.key}`"
        :value="option.key"
        :active-class="`${color} white--text`"
      >
        {{ option.label }}
      </v-chip>
    </v-chip-group>
  </v-sheet>
</template>

<script>
import IncoordFormcontrolMixin from '@/components/core/IncoordFormcontrolMixin';

export default {
  name: 'IncoordCheckboxGroup',
  mixins: [IncoordFormcontrolMixin],
  data() {
    return {
      error: null,
    };
  },
  watch: {
    currentValue() {
      this.onUpdate();
    },
  },
};
</script>

<style scoped></style>
