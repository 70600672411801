<template>
  <v-sheet>
    <label class="text-body-1" :for="id">
      {{ label }}
    </label>
    <v-menu
      v-model="display"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :id="id"
          v-model="currentValue"
          readonly
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          :hint="placeholder"
          class="rounded-sm"
          persistent-hint
          :rules="required ? rules : []"
          placeholder="Välj datum"
        ></v-text-field>
      </template>
      <v-date-picker
        no-title
        @input="display = false"
        first-day-of-week="1"
        show-adjacent-months
        color="green"
        :locale="locale"
        v-model="currentValue"
        :allowed-dates="allowedDates"
        :min="min"
        :value="picker"
        @change="onUpdate"
      ></v-date-picker>
    </v-menu>
  </v-sheet>
</template>

<script>
import IncoordFormcontrolMixin from '@/components/core/IncoordFormcontrolMixin';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

//noinspection JSAnnotator
export default {
  name: 'IncoordDatePicker',
  mixins: [IncoordFormcontrolMixin],
  data() {
    return {
      picker: null,
      display: false,
    };
  },
  created() {
    dayjs().locale(this.locale);
  },
  computed: {
    ...mapGetters({
      locale: 'app/locale',
      dateFormat: 'app/dateFormat',
    }),
    min() {
      return dayjs().format(this.dateFormat);
    },
  },
  methods: {
    allowedDates(val) {
      return this.options.includes(dayjs(val).day());
    },
  },
};
</script>

<style lang="scss">
.v-date-picker-title__date {
  font-size: 24px !important;
}
</style>
