<template>
  <v-img v-bind="{ ...$attrs }" :src="imageSource">
    <slot></slot>
  </v-img>
</template>

<script>
export default {
  name: 'SrcsetImage',
  props: {
    imageLarge: {
      type: String,
    },
    imageSmall: {
      type: String,
    },
  },
  computed: {
    imageSource() {
      if (this.$vuetify.breakpoint.xs) {
        return this.imageSmall;
      } else {
        return this.imageLarge;
      }
    },
  },
};
</script>
