<template>
  <v-container v-bind="{ ...$attrs }">
    <autoadjusting-row class="forty-card py-12 py-sm-4 py-md-8 px-8 px-md-12">
      <v-col cols="12" sm="6" v-if="$slots.first" class="pb-12">
        <slot name="first"></slot>
      </v-col>
      <v-col class="mt-2 mt-sm-0" cols="12" sm="6" v-if="$slots.second" align="center">
        <slot name="second"></slot>
      </v-col>
      <v-col cols="12" align="center">
        <v-card-actions class="pt-10 pt-sm-8 pt-lg-10">
          <v-img v-if="scrollTo" :max-width="$vuetify.breakpoint.sm ? '35' : '67'" :src="getThemedButtonSource"
            @click="scrollToElement" class="mx-auto cursor-pointer">
          </v-img>
        </v-card-actions>
      </v-col>
    </autoadjusting-row>
  </v-container>
</template>

<script>
import AutoadjustingRow from './AutoadjustingRow.vue';
export default {
  components: { AutoadjustingRow },
  name: 'FortyCard',
  props: {
    darkThemed: Boolean,
    centered: Boolean,
    scrollTo: {
      type: String,
    },
  },
  computed: {
    getThemedTextClass() {
      let classes = 'px-0 text-center';
      return classes.concat(
        ' ',
        this.darkThemed ? 'black--text' : 'white--text'
      );
    },
    getThemedButtonSource() {
      return this.darkThemed
        ? require('@/assets/images/global/_desktop_mobile/hero_chevron_down_bl.svg')
        : require('@/assets/images/global/_desktop_mobile/hero_chevron_down_wh.svg');
    },
  },
  methods: {
    scrollToElement() {
      this.$vuetify.goTo(this.scrollTo, { offset: 0, duration: 300 });
    },
  },
};
</script>


<style scoped lang="scss">
.forty-card {
  border: 1px solid white;
  border-radius: 2em;
}

.v-application .text-h2 {
  font-size: var(--headings-size-h3, 1.75rem) !important;
  color: pink;
}
</style>

