<template>
  <v-sheet>
    <label class="text-body-1" :for="id">
      {{ label }}
    </label>
    <v-text-field
      :id="id"
      v-model="currentValue"
      :placeholder="placeholder"
      :rules="required ? rules : []"
      outlined
      dense
      :required="required"
      class="rounded-sm"
      @keyup="onUpdate"
    ></v-text-field>
  </v-sheet>
</template>

<script>
import IncoordFormcontrolMixin from '@/components/core/IncoordFormcontrolMixin';

export default {
  name: 'IncoordInput',
  mixins: [IncoordFormcontrolMixin],
};
</script>

<style scoped></style>
