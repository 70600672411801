var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('two-cols',{scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('text-component',{staticClass:"pt-0 pt-sm-8 pb-10 pb-sm-0",attrs:{"max-width":_vm.$vuetify.breakpoint.sm ? '329px' : '638px',"dark-themed":""},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"overline",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( 'sustainability.subpages.sustainabilityIncoord.subpages.equality.overline' )))]},proxy:true}:null,{key:"titleh1",fn:function(){return [_vm._v(_vm._s(_vm.$t( 'sustainability.subpages.sustainabilityIncoord.subpages.equality.title' )))]},proxy:true},{key:"content",fn:function(){return [(
              _vm.$te(
                'sustainability.subpages.sustainabilityIncoord.subpages.equality.textbr'
              )
            )?_c('p',{staticClass:"text-body-1 mb-4"},[_c('i18n',{attrs:{"path":"sustainability.subpages.sustainabilityIncoord.subpages.equality.textbr","tag":"p"},scopedSlots:_vm._u([{key:"br",fn:function(){return [_c('br')]},proxy:true},{key:"brbr",fn:function(){return [_c('br'),_c('br')]},proxy:true}],null,false,2654219160)})],1):_vm._e()]},proxy:true}],null,true)})]},proxy:true},{key:"second",fn:function(){return [_c('v-img',{staticClass:"rounded",attrs:{"src":require("@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/subpages_top/jamstalldhet_topp.jpg")}})]},proxy:true}])}),(
      _vm.$te(
        'sustainability.subpages.sustainabilityIncoord.subpages.equality.how.title'
      )
    )?_c('div',{staticClass:"sustainability-page"},[_c('two-cols',{staticClass:"py-md-11 py-lg-15",scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('text-component',{attrs:{"dark-themed":"","position":"left","max-width":"632"},scopedSlots:_vm._u([{key:"titleh2",fn:function(){return [_vm._v(_vm._s(_vm.$t( 'sustainability.subpages.sustainabilityIncoord.subpages.equality.how.title' )))]},proxy:true}],null,false,3112987793)})]},proxy:true},{key:"second",fn:function(){return [_c('text-component',{attrs:{"dark-themed":"","position":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('i18n',{attrs:{"path":"sustainability.subpages.sustainabilityIncoord.subpages.equality.how.textbr","tag":"p"},scopedSlots:_vm._u([{key:"br",fn:function(){return [_c('br')]},proxy:true},{key:"brbr",fn:function(){return [_c('br'),_c('br')]},proxy:true}],null,false,2654219160)})]},proxy:true}],null,false,3913066912)})]},proxy:true}],null,false,1512620735)})],1):_vm._e(),_c('news-teaser',{attrs:{"newsRelatedToTagId":_vm.idOfNewsSustainabilityTag},scopedSlots:_vm._u([{key:"newsTitle",fn:function(){return [_c('h2',{staticClass:"text-h2 text-center white--text"},[_vm._v(" "+_vm._s(_vm.$t('news.teaser.titleRelated'))+" "),_c('br',{staticClass:"d-sm-none d-md-flex"})])]},proxy:true}])}),_c('contact-us')],1)}
var staticRenderFns = []

export { render, staticRenderFns }