function getSelectedItemsNames(array) {
  return array.filter((item) => item.selected).map((elem) => elem.title);
}
function projectContainsTagOrCat(tagsOfProject, arrayOfSelected) {
  return tagsOfProject.some((tag) => arrayOfSelected.indexOf(tag.id) >= 0);
}
function setAllItemsToNotSelected(tagArray) {
  return tagArray.forEach((item) => (item.selected = false));
}
function setAllSelected(tagArray) {
  return tagArray.map((item) => {
    const selected = item.id === -1;
    return { ...item, selected };
  });
}
function isNoCategorySelected(tagArray) {
  return !tagArray.find((elem) => elem.selected);
}
function isOptionAllSelected(tagArray) {
  if (!tagArray || tagArray.length === 0) {
    return false;
  }
  const optionAll = tagArray.find((item) => item.id === -1);
  return optionAll && optionAll.selected;
}
function calculateTagsInProjects(tagId, projectsArray) {
  let count = 0;
  count = projectsArray.filter((item) =>
    item.tags.some((tag) => tag.id === tagId)
  ).length;
  return count;
}
function calculateCatsInProjects(tagId, projectsArray) {
  let count = 0;
  count = projectsArray.filter((item) =>
    item.categories.some((tag) => tag.id === tagId)
  ).length;
  return count;
}
function addSelectedAndOptionAll(tagArray) {
  let returnArray = tagArray.map((project) => ({
    ...project,
    selected: false,
  }));
  let index = returnArray.findIndex((item) => item.id === -1);
  if (index > -1) {
    returnArray[index].selected = true;
  } else {
    returnArray.unshift({
      title: 'Alla',
      id: -1,
      selected: true,
    });
  }
  return returnArray;
}
function getImageUrl(image, baseUrl, large) {
  if (baseUrl.endsWith('/') && image.dir.startsWith('/')) {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  } else if (!baseUrl.endsWith('/') && !image.dir.startsWith('/')) {
    baseUrl = baseUrl + '/';
  }
  if (large) {
    return baseUrl + image.dir + 'large/' + image.filename;
  }
  return baseUrl + image.dir + image.filename;
}

export {
  getSelectedItemsNames,
  projectContainsTagOrCat,
  setAllItemsToNotSelected,
  setAllSelected,
  isNoCategorySelected,
  isOptionAllSelected,
  calculateTagsInProjects,
  calculateCatsInProjects,
  addSelectedAndOptionAll,
  getImageUrl,
};
