<template>
  <router-link
    :to="{
      name: 'ProjectDetails',
      params: { slug: project.slug, id: project.id },
    }"
  >
    <v-img :src="getMainImageSource" :height="getImageHeight">
      <div class="mt-2 ml-2">
        <template v-for="(cat, index) in project.categories">
          <v-chip
            class="mr-1 rounded-sm white--text text-body-2"
            color="purple"
            style="max-height: 26px"
            :key="index"
          >
            {{ cat.title }}
          </v-chip>
        </template>
      </div>
    </v-img>
    <div class="mt-4 mb-10 ml-3">
      <h3 class="text-h3">{{ project.title }}</h3>
      <span>
        {{ projectTags }}
      </span>
    </div>
  </router-link>
</template>

<script>
import * as helper from '../../../js/helper.js';
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['project'],
  computed: {
    projectTags: function () {
      let tags = this.project.tags;
      let extra = '';
      if (tags && tags.length > 3) {
        tags = this.project.tags.slice(0, 3);
        extra = ' +' + (this.project.tags.length - 3);
      }
      return tags.map((item) => item.title).join(', ') + extra;
    },
    getImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 185;
        case 'sm':
          return 166;
        default:
          return 240;
      }
    },
    ...mapGetters(['backendUrl']),
    ...mapState(['projectImageUrl']),
    getMainImageSource: function () {
      const image = { dir: this.projectImageUrl, filename: this.project.image };
      return helper.getImageUrl(image, this.backendUrl);
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: inherit;
  text-decoration: none;
}
</style>
