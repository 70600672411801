<template>
  <div>
    <v-card max-width="99%" class="mx-auto">
      <v-img src="@/assets/images/global/desktop/contact.jpg"> </v-img>
    </v-card>
    <v-card flat class="pa-10 pa-sm-5 pa-md-10 rounded mb-16 mt-n6 mt-xl-n16">
      <v-card-title class="text-h3 px-0 pt-0 pb-1"
        >{{ $t('global.contact.title') }}
      </v-card-title>
      <span class="text-body-1">{{ $t('global.contact.phone') }} </span>
      <span class="text-body-1">{{ $t('global.contact.phoneNumber') }}</span>
      <br />
      <span class="text-body-1">{{ $t('global.contact.email') }} </span>
      <a class="text-body-1" :href="mailToLink">{{
        $t('global.contact.emailAddress')
      }}</a>
      <div v-if="extraInfo">
        <v-card-title class="text-h2 px-0 pt-lg-8 pb-lg-2"
          >{{ $t('global.contact.career.title') }}
        </v-card-title>
        <div>
          <span>
            {{ $t('global.contact.career.text') }}
          </span>
        </div>

        <outlined-button :to="{ name: 'Career' }" darkContent v-if="button">
          <template #buttonText>
            {{ $t('global.contact.career.button') }}
          </template>
        </outlined-button>
      </div>
    </v-card>
  </div>
</template>

<script>
import OutlinedButton from './OutlinedButton.vue';
export default {
  components: { OutlinedButton },
  name: 'ContactCard',
  props: {
    extraInfo: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    mailToLink: {
      type: String,
      default: '',
    },
  },
};
</script>
