import { mapActions } from 'vuex';

//noinspection JSAnnotator
export default {
  created() {
    this.updateCurrentSubHeaderComponent(null);
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
    }),
  },
};
