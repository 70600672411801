<template>
  <section v-if="popularSearches.length > 0">
    <p class="text-body-1 text--secondary mb-4">{{ $t('search.popular') }}</p>
    <template v-for="(a, index) in popularSearches">
      <three-cols :noMargin="true" :key="index" v-if="index % 3 === 0">
        <template
          v-for="(item, index2) in getArrayOfThree(index)"
          #[slots[index2]]
        >
          <span
            class="text-h3 cursor-pointer"
            :key="index2"
            @click="searchFor(item.content)"
          >
            {{ item.content }}
          </span>
        </template>
      </three-cols>
    </template>
  </section>
</template>

<script>
import ThreeCols from '../partial/ThreeCols.vue';
import { mapActions } from 'vuex';
export default {
  components: { ThreeCols },
  name: 'PopularSearches',
  data() {
    return {
      popularSearches: [],
      slots: ['first', 'second', 'third'],
    };
  },
  created() {
    this.fetchPopularSearches();
  },
  methods: {
    ...mapActions(['getPopularSearches']),
    getArrayOfThree(index) {
      return this.popularSearches.slice(index, index + 3);
    },
    searchFor(word) {
      this.$emit('search-for', word);
    },
    async fetchPopularSearches() {
      this.popularSearches = await this.getPopularSearches();
    },
  },
};
</script>
