export default {
  name: 'IncoordFormcontrolMixin',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      Default: '',
    },
    model: {
      type: [String, Boolean, Array],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'green',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      currentValue: null,
    };
  },
  created() {
    this.currentValue = this.model;
  },
  methods: {
    onUpdate() {
      this.$emit('update:model', this.currentValue);
      this.$emit('update', { attribute: this.id, value: this.currentValue });
    },
  },
};
