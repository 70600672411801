<template>
  <v-container>
    <two-cols>
      <template #first>
        <div class="text-body-1 mb-1">{{ item.date }}</div>
        <h1 class="text-h1 mb-7">{{ item.title }}</h1>
        <div class="mb-5">
          <template v-for="tag in item.tags">
            <v-chip
              class="mr-1 rounded-sm white--text text-body-2"
              :color="getNewsTagColor(tag.id)"
              style="max-height: 26px"
              :key="tag.id"
            >
              {{ tag.title }}
            </v-chip>
          </template>
        </div>
        <div v-html="contentWithStyleRemoved(item.content)"></div>
      </template>
      <template #second>
        <v-card flat>
          <v-img :src="getImageSource" class="mt-8 mt-sm-0"></v-img>
        </v-card>
      </template>
    </two-cols>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import * as helper from '../../js/helper.js';
import loadNewsTagsMixin from '../../mixins/loadNewsTagsMixin.js';

import TwoCols from '../partial/TwoCols.vue';
//noinspection JSAnnotator
export default {
  components: { TwoCols },
  name: 'BigNews',
  props: ['item'],
  mixins: [loadNewsTagsMixin],
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  watch: {
    item() {
      this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
    },
  },
  computed: {
    ...mapGetters(['backendUrl']),
    ...mapState(['newsImageUrl']),
    breadcrumbItems() {
      return [
        {
          text: this.$t('news.breadcrumb'),
          name: 'News',
          disabled: false,
        },
        {
          text: this.item.title,
          name: 'title',
          disabled: true,
        },
      ];
    },
    getImageSource: function () {
      const image = { dir: this.newsImageUrl, filename: this.item.image };
      return helper.getImageUrl(image, this.backendUrl);
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
    contentWithStyleRemoved(html) {
      return html.replace(/(<[^>]+) style=".*?"/gi, '$1');
    },
  },
};
</script>
