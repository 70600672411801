import Vue from 'vue';
import VueRouter from 'vue-router';
import Forty from '../views/Forty.vue';
import QRcampaign from '../views/QRcampaign.vue';
import About from '../views/About.vue';
import Business from '../views/about/Business.vue';
import InFuture from '../views/about/InFuture.vue';
import RnD from '../views/about/ResearchAndDevelopment.vue';
import Project from '../views/Project.vue';
import Sustainability from '../views/Sustainability.vue';
import SustainabilityProjects from '../views/sustainability/SustainabilityProjects.vue';
import CircularEconomy from '../views/sustainability/sustainabilityInProjects/CircularEconomy.vue';
import ReducedImpact from '../views/sustainability/sustainabilityInProjects/ReducedClimateImpact.vue';
import SmartPowerUse from '../views/sustainability/sustainabilityInProjects/SmartPowerUse.vue';
import AdaptOutsideWorld from '../views/sustainability/sustainabilityInProjects/AdaptOutsideWorld.vue';
import SustainabilityIncoord from '../views/sustainability/SustainabilityIncoord.vue';
import ReducedClimateImpact from '../views/sustainability/sustainabilityInIncoord/ReducedClimateImpact.vue';
import HealthAndWellbeing from '../views/sustainability/sustainabilityInIncoord/HealthAndWellbeing.vue';
import Equality from '../views/sustainability/sustainabilityInIncoord/Equality.vue';
// import Adaptions from '../views/sustainability/sustainabilityInIncoord/Adaptions.vue';
import MaterialsAffect from '../views/sustainability/sustainabilityInIncoord/MaterialsAffect.vue';
import News from '../views/News.vue';
import Career from '../views/Career.vue';
// import Interview from '../views/career/Interview.vue';
import Contact from '../views/Contact.vue';
import InEnglish from '../views/InEnglish.vue';
import ProjectList from '../views/project/ProjectList.vue';
import ProjectDetails from '../views/project/Details.vue';
import NewsList from '../views/news/List.vue';
import NewsDetails from '../views/news/Details.vue';
import Privacy from '../views/Privacy.vue';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

const routes = [
  {
    path: '/40',
    name: '40',
    component: Forty,
  },
  {
    path: '/ljusareframtid',
    name: 'qr',
    component: QRcampaign,
  },
  {
    path: '/om-incoord',
    name: 'About',
    component: About,
  },
  {
    path: '/om-incoord/verksamhet',
    name: 'Business',
    component: Business,
  },
  {
    path: '/om-incoord/infuture',
    name: 'InFuture',
    component: InFuture,
  },
  {
    path: '/om-incoord/forskning-och-utveckling',
    name: 'RnD',
    component: RnD,
  },
  {
    path: '/projekt',
    component: Project,
    children: [
      {
        path: ':slug',
        name: 'ProjectDetails',
        component: ProjectDetails,
        props: true,
      },
      {
        path: '',
        name: 'Project',
        component: ProjectList,
      },
    ],
  },
  {
    path: '/hallbarhet/hallbarhet-inom-incoord/minskad-klimatpaverkan',
    name: 'ReducedClimateImpact',
    component: ReducedClimateImpact,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-incoord/halsa-och-valmaende',
    name: 'HealthAndWellbeing',
    component: HealthAndWellbeing,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-incoord/jamstalldhet',
    name: 'Equality',
    component: Equality,
  },
  // {
  //   path: '/hallbarhet/hallbarhet-inom-incoord/anpassningar',
  //   name: 'Adaptions',
  //   component: Adaptions,
  // },
  {
    path: '/hallbarhet/hallbarhet-inom-incoord/maerials-miljopaverkan',
    name: 'MaterialsAffect',
    component: MaterialsAffect,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-incoord',
    name: 'SustainabilityIncoord',
    component: SustainabilityIncoord,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-projekt',
    name: 'SustainabilityProjects',
    component: SustainabilityProjects,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-projekt/cirkular-ekonomi',
    name: 'CircularEconomy',
    component: CircularEconomy,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-projekt/minskad-klimatpaverkan',
    name: 'ReducedImpact',
    component: ReducedImpact,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-projekt/anpassningar-omvarld',
    name: 'AdaptOutsideWorld',
    component: AdaptOutsideWorld,
  },
  {
    path: '/hallbarhet/hallbarhet-inom-projekt/smart-effektanvandning',
    name: 'SmartPowerUse',
    component: SmartPowerUse,
  },
  {
    path: '/hallbarhet',
    name: 'Sustainability',
    component: Sustainability,
  },
  {
    path: '/nyheter',
    component: News,
    children: [
      {
        path: ':slug',
        name: 'NewsDetails',
        component: NewsDetails,

        props: true,
      },
      {
        path: '',
        name: 'News',
        component: NewsList,
      },
    ],
  },
  {
    path: '/karriar',
    name: 'Career',
    component: Career,
  },
  // {
  //   path: '/karriar/jobba-pa-incoord',
  //   name: 'Interview',
  //   component: Interview,
  // },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/in-english',
    name: 'InEnglish',
    component: InEnglish,
  },
  {
    path: '/integritetspolicy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/',
    name: 'Start',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Start.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
