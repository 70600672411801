<template>
  <two-cols>
    <template #first>
      <v-img
        :class="roundCorners ? 'rounded' : ''"
        :src="getImageSource(featuredProject.image)"
      >
        <template v-for="(cat, index) in featuredProject.categories">
          <v-chip
            :key="index"
            class="mt-2 ml-2 rounded-sm white--text text-body-2"
            color="purple"
            style="max-height: 26px"
            >{{ cat.title }}</v-chip
          >
        </template>
      </v-img>
    </template>
    <template #second>
      <text-component class="mt-10" dark-themed>
        <template #content>
          <h3 class="text-h3">{{ $t('global.project') }}</h3>
          <h2 class="text-h2 mb-5 mb-sm-4 mb-md-6">
            {{ featuredProject.title }}
          </h2>
          <template
            v-if="featuredProject.tags && featuredProject.tags.length > 0"
          >
            <h3 class="text-h3 mb-2">{{ $t('global.services') }}</h3>
            <p class="mb-9">{{ tagsOfProject }}</p>
          </template>
          <p v-html="shorterText(featuredProject.content)"></p
        ></template>
        <template #actions>
          <outlined-button
            :to="{
              name: 'ProjectDetails',
              params: { slug: featuredProject.slug, id: featuredProject.id },
            }"
            darkContent
          >
            <template #buttonText>
              {{ $t('start.project.button') }}
            </template>
          </outlined-button>
        </template>
      </text-component>
    </template>
  </two-cols>
</template>

<script>
import TwoCols from '../partial/TwoCols.vue';
import TextComponent from '../partial/TextComponent.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import { mapState, mapGetters } from 'vuex';

import * as helper from '../../js/helper.js';

export default {
  name: 'FeaturedProject',
  props: ['featuredProject', 'roundCorners'],
  components: {
    OutlinedButton,
    TwoCols,
    TextComponent,
  },
  computed: {
    ...mapState(['projectImageUrl']),
    ...mapGetters(['backendUrl']),
    tagsOfProject: function () {
      return this.featuredProject.tags.map((item) => item.title).join(', ');
    },
  },
  methods: {
    getImageSource: function (img) {
      const image = { dir: this.projectImageUrl, filename: img };
      return helper.getImageUrl(image, this.backendUrl, true);
    },
    shorterText: function (text) {
      return text ? text.split('. ', 3).join('. ') : '';
    },
  },
};
</script>
