export default {
  loading: false,
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'HH:mm',
  timeFormatWithSeconds: 'HH:mm:ss',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  dateTimeWithSecondsFormat: 'YYYY-MM-DD HH:mm:ss',
  locale: localStorage.getItem('incoord_locale') || 'sv',
  routingKey: 0,
  currentSubHeaderComponent: null,
  currentPageBreadCrumbs: [],
  includeFiltersInSearch: true,
  showProjectFilters: false,
  showOverlay: false,
};
