<template>
  <v-container>
    <div class="center">
      <v-text-field
        v-if="$vuetify.breakpoint.smAndUp"
        :placeholder="$t('project.projectList.filterPlaceholder')"
        dense
        hide-details
        persistent-placeholder
        outlined
        @click="openUpPanel"
        @click:append="openUpPanel"
        :value="topTextFieldContents"
        class="shrink top-text-field text-body-1"
        append-icon="$filter"
      >
      </v-text-field>
      <autoadjusting-row v-else>
        <h3 class="text-h3 mr-auto my-auto">
          {{ $t('project.breadcrumb') }}
        </h3>
        <v-btn
          width="32"
          height="32"
          class="pr-0 mr-n3"
          :ripple="false"
          @click="openUpPanel"
          plain
        >
          <v-icon>$filter</v-icon>
        </v-btn>
      </autoadjusting-row>
    </div>
    <autoadjusting-row>
      <filter-component
        v-on:close-panel="closePanel"
        :showFilter="panel"
      ></filter-component>
    </autoadjusting-row>
  </v-container>
</template>

<script>
import FilterComponent from '@/components/project/list/FilterComponent.vue';
import * as helper from '@/js/helper.js';
import { mapGetters, mapActions } from 'vuex';
import AutoadjustingRow from '../../partial/AutoadjustingRow.vue';

//noinspection JSAnnotator
export default {
  name: 'SearchProjectComponent',
  components: { FilterComponent, AutoadjustingRow },
  data() {
    return {
      panel: false,
      overlay: false,
    };
  },
  watch: {
    showProjectFilters: function (newValue) {
      if (!newValue) {
        this.closePanel();
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'project/loading',
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
      showProjectFilters: 'app/showProjectFilters',
    }),
    topTextFieldContents() {
      const searchWords = helper
        .getSelectedItemsNames(this.projectCategories)
        .concat(helper.getSelectedItemsNames(this.projectTags))
        .filter((item) => item !== 'Alla')
        .join(', ');
      return searchWords.length > 0
        ? this.$t('project.projectList.showing') + ' ' + searchWords
        : '';
    },
  },
  methods: {
    ...mapActions({
      setShowProjectFilters: 'app/setShowProjectFilters',
      setShowOverlay: 'app/setShowOverlay',
    }),
    openUpPanel() {
      this.setShowProjectFilters(true);
      this.setShowOverlay(true);
      document.documentElement.style.overflow = 'hidden';
    },
    closePanel() {
      this.setShowProjectFilters(false);
      this.setShowOverlay(false);
      document.documentElement.style.overflow = 'auto';
    },
  },
};
</script>

<style scoped lang="scss">
.v-text-field {
  &.v-text-field--enclosed {
    &:not(.v-text-field--rounded) {
      > .v-input__control {
        > .v-input__slot {
          padding: 0 20px !important;
        }
      }
    }
  }
}
.top-text-field {
  width: 444px;
}

.center {
  display: flex;
  justify-content: center;
  background: white;
  margin-top: -20px;
}
</style>
