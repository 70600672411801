import { set } from '@/utils/vuex';

export default {
  setLoading: set('loading'),
  setNewsFilterModel: set('newsFilterModel'),
  setAllNews: set('allNews'),
  setNewsTags: set('newsTags'),
  setNewsTagsLoading: set('newsTagsLoading'),
  setNumberOfItemsToShow: set('numberOfItemsToShow'),
  setTeaaserItems: set('teaserItems'),
  setSearchResultsNews: set('searchResultsNews'),
};
