<template>
  <v-menu absolute min-width="100%" flat tile v-if="!loading" v-model="showFilter"
    :style="showFilter ? 'top: 0px; position: fixed' : 'position: relative'" content-class="elevation-0"
    :close-on-content-click="false" :close-on-click="true" transition="slide-y-transition">
    <v-sheet color="white" class="pb-16 pb-sm-8 pb-md-10">
      <autoadjusting-row class="white pt-4 mb-0">
        <v-spacer />
        <v-col cols="11" sm="10" :align="$vuetify.breakpoint.xs ? 'left' : 'center'">
          <span class="text-h3">
            {{ $t('project.projectList.filterPlaceholder') }}
          </span></v-col>
        <v-col cols="1" sm="1" align="right">
          <v-btn plain @click="resetSearchAndClosePanel">
            <v-img src="@/assets/images/global/_desktop_mobile/close_icon.svg" max-height="18px" max-width="18px"
              class=""></v-img>
          </v-btn>
        </v-col>
      </autoadjusting-row>
      <autoadjusting-row class="white">
        <template v-for="(card, index) in cardContent">
          <v-col cols="12" sm="6" md="3" :key="index">
            <filter-card :card="card"></filter-card>
          </v-col>
        </template>
        <v-col cols="12" sm="6" md="3">
          <sort-card></sort-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <search-card @show-results="showFilteredProjects()" @clear-tags-and-cats="clearTagsAndFilter()"
            @close-filter="emitClosePanel()"></search-card>
        </v-col>
      </autoadjusting-row>
      <autoadjusting-row no-gutters class="mb-10">
        <v-spacer />
        <v-col class="d-flex justify-end" cols="2">
          <outlined-button class="mr-1" darkContent noArrow @click-submit="showFilteredProjects">
            {{ $t('global.show') }} ({{ countFilteredProjects }})
          </outlined-button>
        </v-col>
        <v-col cols="2">
          <outlined-button class="ml-1" darkContent noArrow @click-submit="resetProjectFilters">
            {{ $t('project.projectList.clearFilter') }}
          </outlined-button>
        </v-col>
        <v-spacer />
      </autoadjusting-row>
    </v-sheet>
  </v-menu>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex';
import * as helper from '../../../js/helper.js';
import AutoadjustingRow from '../../partial/AutoadjustingRow.vue';
import OutlinedButton from '../../partial/OutlinedButton.vue';
import FilterCard from './FilterCard.vue';
import SortCard from './SortCard.vue';
import SearchCard from './SearchCard.vue';
import resetProjectFiltersMixin from '../../../mixins/resetProjectFiltersMixin.js';

//noinspection JSAnnotator
export default {
  name: 'FilterComponent',
  components: {
    AutoadjustingRow,
    OutlinedButton,
    FilterCard,
    SortCard,
    SearchCard,
  },
  mixins: [resetProjectFiltersMixin],

  data() {
    return {
      minSearchStringLength: 3,
    };
  },
  created() {
    this.filterAllProjects();
  },
  watch: {
    projectCategories() {
      this.filterAllProjects();
    },
    projectTags() {
      this.filterAllProjects();
    },
    searchFieldContents() {
      this.filterAllProjects();
    },
    sortNewestFirst() {
      this.filterAllProjects();
    },
  },
  computed: {
    ...mapGetters({
      allProjects: 'project/allProjects',
      filteredProjects: 'project/filteredProjects',
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
      searchResults: 'project/searchResults',
      loading: 'project/loading',
      sortNewestFirst: 'search/sortNewestFirst',
      searchFieldContents: 'search/searchFieldContents',
      showProjectFilters: 'app/showProjectFilters',
    }),
    showFilter: {
      get: function () {
        return this.showProjectFilters;
      },
      set: function (newValue) {
        this.setShowProjectFilters(newValue);
      },
    },
    countFilteredProjects() {
      return this.filteredProjects.length;
    },
    includeFiltersInSearch: {
      get() {
        return this.$store.state.includeFiltersInSearch;
      },
      set(value) {
        this.setIncludeFilters(value);
      },
    },
    commonAttrs() {
      return {
        elevation: 0,
        outlined: true,
      };
    },
    cardContent() {
      return [{
        title: this.$t('project.projectList.cardTitles.categories'),
        subTitle: this.$t('project.projectList.all'),
        content: this.projectCategories,
        type: 'categories',
      },
      {
        title: this.$t('project.projectList.cardTitles.services'),
        subTitle: this.$t('project.projectList.all'),
        content: this.projectTags,
        type: 'tags',
      },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateFilteredProjects: 'project/updateFilteredProjects',
      updateProjectTags: 'project/updateProjectTags',
      updateProjectCategories: 'project/updateProjectCategories',
      setShowingProjectResults: 'search/setShowingProjectResults',
      setSearchFieldContents: 'search/setSearchFieldContents',
      setSearchResults: 'search/setSearchResults',
      setIncludeFilters: 'app/setIncludeFilters',
      setSortNewestFirst: 'search/setSortNewestFirst',
      setShowProjectFilters: 'project/setShowProjectFilters',
    }),

    sort(projects) {
      if (this.sortNewestFirst) {
        projects.sort((firstItem, secondItem) =>
          this.sortNewest(firstItem.created_at, secondItem.created_at)
        );
      } else {
        projects.sort((firstItem, secondItem) =>
          this.sortAZ(firstItem.title, secondItem.title)
        );
      }
      return projects;
    },
    sortNewest(a, b) {
      return new Date(b) > new Date(a) ? 1 : -1;
    },
    sortAZ(a, b) {
      let nameA = a.toUpperCase();
      let nameB = b.toUpperCase();
      return nameA < nameB ? -1 : 1;
    },
    filterAllProjects() {
      let newFilteredProjects = this.allProjects;
      if (!helper.isOptionAllSelected(this.projectCategories)) {
        newFilteredProjects = this.allProjects.filter((project) =>
          helper.projectContainsTagOrCat(
            project.categories,
            this.projectCategories
              .filter((item) => item.selected)
              .map((cat) => cat.id)
          )
        );
      }
      this.calculateTagsCount(newFilteredProjects);
      if (!helper.isOptionAllSelected(this.projectTags)) {
        newFilteredProjects = newFilteredProjects.filter((project) =>
          helper.projectContainsTagOrCat(
            project.tags,
            this.projectTags
              .filter((item) => item.selected)
              .map((tag) => tag.id)
          )
        );
      }
      this.calculateCatsCount(newFilteredProjects);

      const searchString = this.searchFieldContents;
      if (searchString.length >= this.minSearchStringLength) {
        let searchResults = newFilteredProjects.filter(
          (project) =>
            project.title
              .toLowerCase()
              .includes(this.searchFieldContents.toLowerCase()) ||
            project.client
              .toLowerCase()
              .includes(this.searchFieldContents.toLowerCase())
        );
        newFilteredProjects = searchResults;
        this.setSearchResults(searchResults);
      }
      newFilteredProjects = this.sort(newFilteredProjects);
      this.updateFilteredProjects(newFilteredProjects);
    },
    showFilteredProjects() {
      this.emitClosePanel();
      this.setShowingProjectResults(true);
      this.$vuetify.goTo(1);
    },
    calculateTagsCount(projectArray) {
      this.projectTags.forEach(
        (item) =>
          (item.count = helper.calculateTagsInProjects(item.id, projectArray))
      );
    },
    calculateCatsCount(projectArray) {
      this.projectCategories.forEach(
        (item) =>
          (item.count = helper.calculateCatsInProjects(item.id, projectArray))
      );
    },
    clearTagsAndFilter() {
      this.clearTagFilters();
    },
    resetSearchAndClosePanel() {
      this.resetProjectFilters();
      this.emitClosePanel();
    },

    emitClosePanel() {
      this.$emit('close-panel');
    },
  },
};
</script>

<style lang="scss">
.v-menu__content.menuable__content__active {
  overflow-y: scroll !important;
  max-height: 90vh;
}
</style>
