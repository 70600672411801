<template>
  <v-btn
    @click="$emit('click-submit', $event)"
    v-bind="{ ...$attrs, ...commonAttrs }"
    :disabled="!enableValue"
    :color="getButtonColor"
    :height="getButtonHeight"
  >
    <v-img
      v-if="!noArrow"
      :src="getThemedArrowSource"
      class="mx-1"
      :class="enableValue ? '' : 'disabled'"
    />
    <slot name="buttonText"></slot>
    <slot name="default"></slot>
  </v-btn>
</template>

<script>
export default {
  computed: {
    getThemedArrowSource() {
      if (this.down) {
        return require('@/assets/images/global/_desktop_mobile/button_arrow_down_bl.svg');
      } else {
        return this.darkContent
          ? require('@/assets/images/global/_desktop_mobile/button_arrow_right_bl.svg')
          : require('@/assets/images/global/_desktop_mobile/button_arrow_right_wh.svg');
      }
    },
    getButtonColor() {
      return this.darkContent ? 'black' : 'white';
    },
    getButtonHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '36px';
        case 'sm':
          return '27px';
        default:
          return '36px';
      }
    },
    commonAttrs() {
      let classes = 'ml-0 text-h3 rounded-sm';
      if (!this.noMargin) {
        classes = classes + ' mt-5';
      }
      return {
        outlined: true,
        small: true,
        class: classes,
      };
    },
  },
  props: {
    darkContent: Boolean,
    noArrow: Boolean,
    down: Boolean,
    noMargin: Boolean,
    enableValue: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  opacity: 0.4;
}
.v-btn {
  border-width: 2px !important;
}
</style>
