<template>
  <autoadjusting-row class="mt-sm-5 mt-md-10 mb-8 mb-sm-0">
    <v-col cols="12" md="8">
      <template v-for="(item, index) in items">
        <v-row :key="item.id" v-if="index % 2 === 0">
          <template v-for="item in getTwoItems(index)">
            <v-col cols="12" sm="6" :key="item.id">
              <small-news :item="item"> </small-news>
            </v-col>
          </template>
        </v-row>
      </template>
    </v-col>
    <v-col cols="12" md="4">
      <v-card flat color="black">
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="6"
            md="12"
            class="pl-10 pt-10 pl-sm-5 pl-md-10 py-sm-5 pb-md-5 pt-md-10"
          >
            <v-card :max-width="getImageWidth">
              <v-img
                contain
                class="rounded"
                :src="getPathToCurrentNewsletterImage"
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="12" class="mb-5 mb-sm-0">
            <v-card
              color="black"
              flat
              class="px-6 pt-4 pl-sm-0 pb-sm-5 pt-sm-10 px-md-5 pb-md-5 pt-md-0"
            >
              <div class="white--text">
                <v-card-title class="text-h3 pt-4 mt-4 mb-6 mt-sm-0 pt-sm-0">{{
                  $t('news.newsletter.overline')
                }}</v-card-title>
                <v-card-subtitle class="text-h2 pb-2">
                  {{ $t('news.newsletter.title') }}
                </v-card-subtitle>
                <v-card-text class="text-body-1">
                  {{ $t('news.newsletter.text') }}
                </v-card-text>
                <v-card-text class="pb-5 pt-0 text-h3">
                  <a
                    :href="getPathToCurrentNewsletterFile"
                    download
                    class="text-decoration-none"
                  >
                    {{ $t('news.newsletter.downloadCurrent') }}
                    <span> {{ $t('news.newsletter.fileending') }}</span>
                  </a>
                  <div class="pt-1">
                    <v-expansion-panels dark accordion flat tile>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          class="text-h3 pl-0 green-text"
                        >
                          {{ $t('news.newsletter.older') }}
                          <template v-slot:actions>
                            <v-icon color="green"> $expand </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          v-for="(item, i) in newsletters.slice(1)"
                          :key="i"
                        >
                          <a
                            :href="item.path"
                            download
                            class="text-decoration-none"
                          >
                            {{ item.title }}</a
                          >
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </autoadjusting-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import SmallNews from './SmallNews.vue';

export default {
  name: 'ListComponent',
  props: ['items'],
  components: {
    SmallNews,
    AutoadjustingRow,
  },
  mounted() {
    this.getNewsletters();
  },
  data() {
    return {
      slots: ['first', 'second'],
      newsletters: [],
    };
  },
  computed: {
    ...mapGetters(['backendUrl']),
    getPathToCurrentNewsletterFile() {
      return this.newsletters.length > 0 ? this.newsletters[0].path : '';
    },
    getPathToCurrentNewsletterImage() {
      return (
        this.backendUrl +
        '/nyhetsbrev/' +
        this.$t('news.newsletter.currentNewsletterImageFilename')
      );
    },
    getImageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 132;
        case 'sm':
          return 304;
        case 'md':
          return 226;
        default:
          return 226;
      }
    },
  },
  methods: {
    ...mapActions(['getListOfNewsletterFiles']),
    async getNewsletters() {
      this.newsletters = await this.getListOfNewsletterFiles();
      this.addPathAndLinkNewsletter();
      this.sortNewsletters();
    },
    addPathAndLinkNewsletter() {
      this.newsletters = this.newsletters.map((item) => ({
        path: this.backendUrl + '/nyhetsbrev/' + item,
        title: this.titleFromFilename(item),
      }));
    },
    sortNewsletters() {
      this.newsletters.sort(function (a, b) {
        if (a.title > b.title) {
          return -1;
        }
        if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    },
    getTwoItems(index) {
      let arrayOfThreeElements = this.items.slice(index, index + 2);
      return arrayOfThreeElements;
    },
    titleFromFilename(file) {
      const array = file.split('_');
      const partTwo = array[2].split('.');
      return `${array[1].charAt(0).toUpperCase() + array[1].slice(1)} ${
        partTwo[0]
      }`;
    },
  },
};
</script>

<style scoped>
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
  color: #ffffff;
  color: var(--v-anchor-base) !important;
}
</style>
