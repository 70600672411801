import { render, staticRenderFns } from "./IncoordCheckbox.vue?vue&type=template&id=6b8e0592&scoped=true&"
import script from "./IncoordCheckbox.vue?vue&type=script&lang=js&"
export * from "./IncoordCheckbox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8e0592",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCheckbox,VSheet})
