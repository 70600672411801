<template>
  <v-container v-bind="{ ...$attrs }">
    <autoadjusting-row class="py-13 py-sm-4 py-md-8 py-lg-13">
      <v-col cols="12" sm="6" v-if="$slots.first">
        <slot name="first"></slot>
      </v-col>
      <v-col class="mt-2 mt-sm-0" cols="12" sm="6" v-if="$slots.second">
        <slot name="second"></slot>
      </v-col>
    </autoadjusting-row>
  </v-container>
</template>

<script>
import AutoadjustingRow from './AutoadjustingRow.vue';
export default {
  components: { AutoadjustingRow },
};
</script>
