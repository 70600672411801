<template>
  <search-results
    @close-search="closeSearch"
    :resultsArray="searchResultsProjects"
    :resultsType="resultsType"
  ></search-results>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SearchResults from './SearchResults.vue';

//noinspection JSAnnotator
export default {
  name: 'SearchProjects',
  props: ['searchFor'],
  components: { SearchResults },
  watch: {
    searchFor: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.search();
        } else {
          this.setSearchResultsProjects([]);
        }
      },
    },
  },
  data() {
    return {
      resultsType: {
        name: 'projects',
        title: this.$t('global.project'),
        routeName: 'ProjectDetails',
      },
    };
  },
  computed: {
    ...mapGetters({
      searchResultsProjects: 'project/searchResultsProjects',
    }),
  },
  methods: {
    ...mapActions({
      setSearchResultsProjects: 'project/setSearchResultsProjects',
      searchProjects: 'project/searchProjects',
    }),
    closeSearch() {
      this.$emit('close-search');
    },
    search() {
      this.searchProjects(this.searchFor)
        .then(() => {
          this.$emit('loading-done');
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
