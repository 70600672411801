<template>
  <nav>
    <v-app-bar app flat hide-on-scroll ref="appBar" class="white" height="50" :scroll-threshold="50"
      :extension-height="getExtensionHeight">
      <v-container>
        <autoadjusting-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="space-between">
          <v-col cols="auto" class="mr-auto" :align-self="$vuetify.breakpoint.xs ? 'center' : 'auto'">
            <router-link to="/">
              <v-img contain alt="Incoord Logo" src="@/assets/images/global/_desktop_mobile/header/incoord_logo.svg"
                :max-height="$vuetify.breakpoint.sm ? 21 : 28" />
            </router-link>
          </v-col>

          <v-col cols="auto" v-if="$vuetify.breakpoint.xs">
            <navigation-hamburger v-if="$vuetify.breakpoint.xs">
            </navigation-hamburger>
            <v-btn class="pl-sm-2 pl-md-3" icon :ripple="false" plain :width="$vuetify.breakpoint.sm ? 12 : 18"
              :height="$vuetify.breakpoint.sm ? 12 : 18" @click="showSearch">
              <v-icon color="black" alt="Search">$search</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="auto" v-else>
            <v-toolbar-items class="px-0">
              <v-btn class="text-body-1 black--text text-decoration-none px-sm-1 px-md-2 px-lg-3 my-1"
                v-for="(item, index) in menuItems" :key="index" :to="{ name: item.routeName }" @click="updateRoutingKey"
                plain><span> {{ item.title }}</span>
              </v-btn>
              <v-btn class="pl-sm-2 pl-md-3" icon :ripple="false" plain :width="$vuetify.breakpoint.sm ? 12 : 18"
                :height="$vuetify.breakpoint.sm ? 12 : 18">
                <v-icon size="18" alt="Search" @click="showSearch">$search</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </autoadjusting-row>
      </v-container>
      <template v-slot:extension v-if="subHeaderComponent">
        <v-sheet width="100%">
          <component :is="subHeaderComponent" :items="subHeaderParameters"></component>
        </v-sheet>
      </template>
    </v-app-bar>

    <search-component @close-drawer="closeSearch" :drawer="openDrawer">
    </search-component>
  </nav>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import SearchComponent from '../search/SearchComponent.vue';
import PageBreadcrumbs from '@/components/partial/PageBreadcrumbs.vue';
import NavigationHamburger from './NavigationHamburger.vue';
import SearchProjectComponent from '@/components/project/list/SearchProjectComponent.vue';
import FilterComponent from '@/components/news/FilterComponent.vue';
import { mapMutations, mapGetters } from 'vuex';

//noinspection JSAnnotator
export default {
  data() {
    return {
      openDrawer: false,
      panel: null,
    };
  },
  computed: {
    ...mapGetters({
      subHeaderComponent: 'app/subHeaderComponent',
      breadcrumbs: 'app/breadcrumbs',
      routingKey: 'app/routingKey',
    }),
    getExtensionHeight() {
      if (!this.subHeaderComponent) {
        return 0;
      }
      if (
        this.subHeaderComponent === 'filter-component' &&
        this.$vuetify.breakpoint.xs
      ) {
        return 70;
      } else {
        return 60;
      }
    },
    subHeaderParameters() {
      if (this.subHeaderComponent === 'page-breadcrumbs') {
        return this.breadcrumbs;
      } else {
        return '';
      }
    },
    menuItems() {
      return this.$store.getters.topMenuItems;
    },
    fortyClass() {
      return this.$route.name === "40" ? "nav-forty-gradient" : "nav-forty";
    },
    lightThemed() {
      return this.$route.name === "40";
    },
    fortyImage() {
      return this.$route.name === "40"
        ? require("@/assets/images/forty/_desktop_mobile/40_white.svg")
        : require("@/assets/images/forty/_desktop_mobile/40_gradient.svg");
    },
  },
  components: {
    AutoadjustingRow,
    SearchComponent,
    PageBreadcrumbs,
    SearchProjectComponent,
    FilterComponent,
    NavigationHamburger,
  },
  methods: {
    ...mapMutations(['setRoutingKey']),
    showSearch() {
      if (this.openDrawer) {
        this.openDrawer = false;
      }
      this.openDrawer = true;
      document.documentElement.style.overflow = 'hidden';
    },
    closeSearch() {
      this.openDrawer = false;
      document.documentElement.style.overflow = 'auto';
    },
    updateRoutingKey() {
      this.setRoutingKey();
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn--active span {
  border-bottom: 2px solid #34c759 !important;
}

span:hover {
  border-bottom: 2px solid #d1d1d6 !important;
}

.v-toolbar>>>.v-toolbar__content,
.v-toolbar>>>.v-toolbar__extension {
  padding-right: 0 !important;
  padding-left: 0 !important;
}


.nav-forty {
  border-radius: 6px !important;
  color: black;
  font: normal normal bold 16px/24px Open Sans;
}

.nav-forty-gradient {
  background: transparent linear-gradient(90deg, #813A89 0%, #813A89 33%, #CF605F 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  color: white;
  font: normal normal bold 16px/24px Open Sans;
}
</style>
