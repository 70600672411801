export default {
  setSearchResults: async ({ commit }, values) => {
    commit('setSearchResults', values);
  },
  setSearchFieldContents: async ({ commit }, value) => {
    commit('setSearchFieldContents', value);
  },
  setIncludeFilters: async ({ commit }, value) => {
    commit('setIncludeFilters', value);
  },
  updateProjectCategories: async ({ commit }, value) => {
    commit('setProjectCategories', value);
  },
  setShowingProjectResults({ commit }, value) {
    commit('setShowingProjectResults', value);
  },
  setSortNewestFirst({ commit }, value) {
    commit('setSortNewestFirst', value);
  },
};
