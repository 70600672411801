<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '329px' : '638px'"
          class="mt-9 mb-5"
          dark-themed
        >
          <template #titleh1>{{ $t('about.subpages.rnd.title') }}</template>
          <template #contentWithoutFont>
            <i18n tag="p" path="about.subpages.rnd.textbr" class="text-body-1">
              <template v-slot:br><br /></template>
              <template v-slot:brbr><br /><br /></template>
            </i18n>
          </template>
        </text-component>
        <text-component
          v-for="(part, index) in textParts"
          :key="index"
          :max-width="$vuetify.breakpoint.sm ? '450px' : '650px'"
          dark-themed
          class="pb-2"
        >
          <template #contentWithoutFont>
            <h2 class="text-h2 mb-2">{{ part.title }}</h2>
            <p class="text-body-1 mb-4">{{ part.text }}</p>
          </template>
        </text-component>
      </template>
      <template #second>
        <v-img
          class="rounded mt-12 mt-sm-0"
          src="@/assets/images/about/_desktop_mobile/fou/forskning_utbildning_toppbild.png"
        >
        </v-img>
      </template>
    </two-cols>
    <div class="mb-16"></div>
    <contact-us></contact-us>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContactUs from '../../components/complete/ContactUs.vue';
import TextComponent from '../../components/partial/TextComponent.vue';
import TwoCols from '../../components/partial/TwoCols.vue';

//noinspection JSAnnotator
export default {
  components: {
    TwoCols,
    TextComponent,
    ContactUs,
  },
  name: 'ResearchAndDevelopment',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: this.$t('about.breadcrumb'),
          name: 'About',
          disabled: false,
        },
        {
          text: this.$t('about.subpages.rnd.breadcrumb'),
          name: '#fou',
          disabled: true,
        },
      ];
    },
    textParts() {
      return this.$t('about.subpages.rnd.parts');
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
};
</script>
