import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['backendUrl']),
    getBackendUrl() {
      return this.backendUrl.endsWith('/')
        ? this.backendUrl
        : this.backendUrl + '/';
    },
    getPathToSustainabilityFile() {
      return (
        this.getBackendUrl +
        `nyhetsbrev/${this.$t('footer.download.sustainabilityFileName')}`
      );
    },
    getPathToAccountingFile() {
      return (
        this.getBackendUrl +
        `hallbarhet/${this.$t('footer.download.accountingFileName')}`
      );
    },
  },
};
