<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '329px' : '638px'"
          class="pt-0 pt-sm-8 pb-10 pb-sm-0"
          dark-themed
        >
          <template #overline v-if="$vuetify.breakpoint.mdAndUp">
            {{
              $t('sustainability.subpages.sustainabilityIncoord.top.overline')
            }}</template
          >
          <template #titleh1>{{
            $t('sustainability.subpages.sustainabilityIncoord.top.title')
          }}</template>
          <template #content>
            <p class="text-body-1 mb-4">
              {{ $t('sustainability.subpages.sustainabilityIncoord.top.text') }}
            </p>
          </template>
        </text-component>
      </template>
      <template #second>
        <v-img
          class="rounded"
          src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/hallbarhet_inom_incoord_topp.jpg"
        >
        </v-img>
      </template>
    </two-cols>
    <div class="sustainability-page pb-15">
      <two-cols class="mt-7 py-15 py-md-11">
        <template #first>
          <text-component dark-themed position="left" max-width="632">
            <template #titleh2>{{
              $t(
                'sustainability.subpages.sustainabilityIncoord.knowledge.title'
              )
            }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component dark-themed position="right">
            <template #content>
              <i18n
                tag="p"
                path="sustainability.subpages.sustainabilityIncoord.knowledge.textbr"
                class="mb-4 text-body-1"
              >
                <template v-slot:brbr><br /><br /></template>
              </i18n>
            </template>
          </text-component>
        </template>
      </two-cols>

      <two-cols id="klimat">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityIncoord.climate.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityIncoord.climate.text'
                  )
                }}
              </p>
              <outlined-button
                :to="{ name: 'ReducedClimateImpact' }"
                darkContent
              >
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityIncoord.climate.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/minskad_klimatpaverkan.png"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols id="halsa">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityIncoord.health.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityIncoord.health.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'HealthAndWellbeing' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityIncoord.health.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/halsa_och_valmaende.png"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols id="jamstalldhet">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityIncoord.equality.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityIncoord.equality.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'Equality' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityIncoord.equality.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/jamstalldhet.png"
          >
          </v-img>
        </template>
      </two-cols>

      <!-- Hidden by request 21-12-09. Kept the section below and page the linked page just in case.
        <two-cols id="anpassningar">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityIncoord.adapt.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t('sustainability.subpages.sustainabilityIncoord.adapt.text')
                }}
              </p>
              <outlined-button :to="{ name: 'Adaptions' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityIncoord.adapt.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/anpassningar_omvarld.png"
          >
          </v-img>
        </template>
      </two-cols> -->

      <two-cols id="material">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t(
                'sustainability.subpages.sustainabilityIncoord.materials.title'
              )
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityIncoord.materials.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'MaterialsAffect' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityIncoord.materials.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/material_miljopaverkan.png"
          >
          </v-img>
        </template>
      </two-cols>
    </div>

    <srcset-image
      :imageLarge="
        require('@/assets/images/sustainability/desktop/sustainability_in_incoord/globala_malen_bg.jpg')
      "
      :imageSmall="
        require('@/assets/images/sustainability/mobile/sustainability_in_incoord/globala_malen_projekt_bg@2x.jpg')
      "
    >
      <template>
        <v-container fill-height>
          <autoadjusting-row align="center" justify="center">
            <v-col cols="12" sm="6" md="6" class="py-16">
              <srcset-image
                :imageLarge="
                  require('@/assets/images/sustainability/desktop/sustainability_in_incoord/globala_malen_inom_incoord.png')
                "
                :imageSmall="
                  require('@/assets/images/sustainability/mobile/sustainability_in_incoord/globala_malen_inom_incoord.png')
                "
              ></srcset-image>
            </v-col>
          </autoadjusting-row>
        </v-container>
      </template>
    </srcset-image>

    <three-teasers-projects></three-teasers-projects>

    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from '../../components/complete/ContactUs.vue';
import AutoadjustingRow from '../../components/partial/AutoadjustingRow.vue';
import OutlinedButton from '../../components/partial/OutlinedButton.vue';
import SrcsetImage from '../../components/partial/SrcsetImage.vue';
import TextComponent from '../../components/partial/TextComponent.vue';
import TwoCols from '../../components/partial/TwoCols.vue';
import { mapActions } from 'vuex';
import ThreeTeasersProjects from '../../components/complete/ThreeTeasersProjects.vue';

//noinspection JSAnnotator
export default {
  components: {
    TwoCols,
    TextComponent,
    OutlinedButton,
    SrcsetImage,
    AutoadjustingRow,
    ContactUs,
    ThreeTeasersProjects,
  },
  name: 'SustainabilityIncoord',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: this.$t('sustainability.breadcrumb'),
          name: 'Sustainability',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.breadcrumb'
          ),
          name: 'Sustainability',
          disabled: true,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.climate.breadcrumb'
          ),
          name: '#klimat',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.health.breadcrumb'
          ),
          name: '#halsa',
          disabled: false,
        },

        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.equality.breadcrumb'
          ),
          name: '#jamstalldhet',
          disabled: false,
        },
        // {
        //   text: this.$t(
        //     'sustainability.subpages.sustainabilityIncoord.adapt.breadcrumb'
        //   ),
        //   name: '#anpassningar',
        //   disabled: false,
        // },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.materials.breadcrumb'
          ),
          name: '#material',
          disabled: false,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.sustainability-page {
  background-color: var(--v-bgsustainability-base);
}
</style>
