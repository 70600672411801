<template>
  <div
    class="project-list"
    v-if="projectTeaserItems && projectTeaserItems.length > 2"
  >
    <autoadjusting-row>
      <v-col>
        <v-card
          elevation="0"
          class="text-center px-5 py-5 pt-10 pt-sm-2 pt-lg-10"
        >
          <h2 class="text-h2 text-center black--text">
            {{
              $t(
                'sustainability.subpages.sustainabilityIncoord.referenceProjects.title'
              )
            }}
            <br class="d-sm-none d-md-flex" />
          </h2>
        </v-card>
      </v-col>
    </autoadjusting-row>
    <list-component :items="projectTeaserItems" forceThreeColsOnSm>
      <template v-slot:default="slotProps">
        <small-project :project="slotProps.item"> </small-project>
      </template>
    </list-component>
    <div class="text-center pb-5">
      <outlined-button darkContent noMargin @click-submit="clickAllProjects">
        <template #buttonText>
          {{ $t('start.project.buttonAll') }}
        </template>
      </outlined-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import ListComponent from '../partial/ListComponent.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import SmallProject from '../project/list/SmallProject.vue';
import sustainableProjectsMixin from '../../mixins/sustainableProjectsMixin.js';
import resetProjectFiltersMixin from '../../mixins/resetProjectFiltersMixin.js';

//noinspection JSAnnotator
export default {
  components: {
    AutoadjustingRow,
    ListComponent,
    SmallProject,
    OutlinedButton,
  },
  name: 'ThreeTeasersProjects',
  mixins: [sustainableProjectsMixin, resetProjectFiltersMixin],
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
    clickAllProjects() {
      this.resetProjectFilters();
      this.$router.push({ name: 'Project' });
    },
  },
};
</script>

<style scoped>
.project-list {
  padding-top: 80px;
  padding-bottom: 80px;
}
</style>
