<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '329px' : '638px'"
          class="pt-0 pt-sm-8 pb-10 pb-sm-0"
          dark-themed
        >
          <template #overline v-if="$vuetify.breakpoint.mdAndUp">
            {{
              $t(
                'sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.overline'
              )
            }}</template
          >
          <template #titleh1>{{
            $t(
              'sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.title'
            )
          }}</template>
          <template #content>
            <p
              class="text-body-1 mb-4"
              v-if="
                $te(
                  'sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.textbr'
                )
              "
            >
              <i18n
                path="sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.textbr"
                tag="p"
                ><template v-slot:br><br /></template>
                <template v-slot:brbr>
                  <br />
                  <br />
                </template>
              </i18n>
            </p>
          </template>
        </text-component>
      </template>
      <template #second>
        <v-img
          class="rounded"
          src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_projects/subpages_top/smart_effektanvandning_topp.jpg"
        >
        </v-img>
      </template>
    </two-cols>

    <div
      class="sustainability-page"
      v-if="
        $te(
          'sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.how.title'
        )
      "
    >
      <two-cols class="py-md-11 py-lg-15">
        <template #first>
          <text-component dark-themed position="left" max-width="632">
            <template #titleh2>{{
              $t(
                'sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.how.title'
              )
            }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component dark-themed position="right">
            <template #content>
              <i18n
                path="sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.how.textbr"
                tag="p"
              >
                <template v-slot:br><br /></template>
                <template v-slot:brbr>
                  <br />
                  <br />
                </template>
              </i18n>
            </template>
          </text-component>
        </template>
      </two-cols>
    </div>

    <news-teaser :newsRelatedToTagId="idOfNewsSustainabilityTag">
      <template #newsTitle>
        <h2 class="text-h2 text-center white--text">
          {{ $t('news.teaser.titleRelated') }}
          <br class="d-sm-none d-md-flex" />
        </h2>
      </template>
    </news-teaser>

    <contact-us />
  </div>
</template>

<script>
import ContactUs from '../../../components/complete/ContactUs.vue';
import NewsTeaser from '../../../components/complete/ThreeTeasersNews.vue';
import TextComponent from '../../../components/partial/TextComponent.vue';
import TwoCols from '../../../components/partial/TwoCols.vue';
import { mapState, mapActions } from 'vuex';

//noinspection JSAnnotator
export default {
  components: {
    TwoCols,
    TextComponent,
    NewsTeaser,
    ContactUs,
  },
  name: 'SmartPowerUse',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  computed: {
    ...mapState(['idOfNewsSustainabilityTag']),
    breadcrumbItems() {
      return [
        {
          text: this.$t('sustainability.breadcrumb'),
          name: 'Sustainability',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.breadcrumb'
          ),
          name: 'SustainabilityProjects',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.subpages.smartPowerUse.breadcrumb'
          ),
          name: '',
          disabled: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
};
</script>

<style scoped lang="scss">
.sustainability-page {
  background-color: var(--v-bgsustainability-base);
}
</style>
