<template>
  <div>
    <top-component
      :image-source="
        require('@/assets/images/contact/desktop/kontakt_hero_bg.jpg')
      "
      :image-srcset="
        require('@/assets/images/contact/mobile/kontakt_hero_bg.jpg')
      "
      scrollTo="#kontakt"
    >
      <template #overline>{{ $t('contact.hero.overline') }}</template>
      <template #titleh1>{{ $t('contact.hero.title') }}</template>
    </top-component>

    <v-card id="kontakt" class="py-16" color="white" tile flat>
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col cols="12" sm="10" lg="8">
          <text-component dark-themed centered>
            <template #titleh2>{{ $t('contact.general') }}</template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col cols="12" sm="6" lg="5">
          <text-component dark-themed centered>
            <template #titleh2>{{ $t('contact.emails') }}</template>
            <template #contentWithoutFont>
              <h3 class="text-h3">
                {{ $t('contact.findus') }}
              </h3>
              <p class="text-body-1">
                {{ $t('contact.address') }}
              </p>
            </template>
            <template #actions>
              <outlined-button
                darkContent
                :href="$t('contact.findusLink')"
                target="_blank"
              >
                <template #buttonText>
                  {{ $t('contact.map') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </v-card>

    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from '../components/complete/ContactUs.vue';
import TopComponent from '../components/complete/TopComponent.vue';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import OutlinedButton from '../components/partial/OutlinedButton.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import setSubHeaderToNullMixin from '../mixins/setSubHeaderToNullMixin.js';
export default {
  name: 'Contact',
  mixins: [setSubHeaderToNullMixin],
  components: {
    TopComponent,
    TextComponent,
    AutoadjustingRow,
    OutlinedButton,
    ContactUs,
  },
};
</script>
