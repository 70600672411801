import { set } from '@/utils/vuex';

export default {
  setLoading: set('loading'),
  setProjects: set('allProjects'),
  resetNumberOfItemsToShow: set('resetNumberOfItemsToShow'),
  updateProjectCategories: set('projectCategories'),
  updateProjectTags: set('projectTags'),
  setShowingProjectResults: set('showingProjectResults'),
  initializeFeaturedProjects: set('featuredProjects'),
  setFeaturedProjectsLoaded: set('featuredProjectsLoaded'),
  setFilteredProjects: set('filteredProjects'),
  setSearchResultsProjects: set('searchResultsProjects'),
  setSearchResultsProjectsTags: set('searchResultsProjectsTags'),
};
