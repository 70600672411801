<template>
  <v-container fluid>
    <v-row dense class="mt-16 mt-sm-0">
      <v-col cols="12">
        <v-img
          :src="getMainImageSource"
          class="white--text align-end rounded-t"
          :class="getRoundedCornerClass(-1)"
          height="500px"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        :cols="getNumberOfCols"
        v-for="(image, index) in featuredImages"
        :key="image.id"
      >
        <v-img
          :src="getImageSource(image)"
          class="white--text align-end"
          height="250px"
          :class="getRoundedCornerClass(index)"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as helper from '../../../js/helper.js';

export default {
  name: 'ImageGrid',
  props: ['mainImage', 'images'],
  computed: {
    ...mapGetters(['backendUrl']),
    ...mapState(['projectImageUrl']),
    getMainImageSource: function () {
      const image = { dir: this.projectImageUrl, filename: this.mainImage };
      return helper.getImageUrl(image, this.backendUrl);
    },
    featuredImages: function () {
      return this.images.length > 2 ? this.images.slice(0, 2) : this.images;
    },
    getNumberOfCols: function () {
      return this.images.length > 1 ? 6 : 12;
    },
  },
  methods: {
    getImageSource: function (image) {
      return helper.getImageUrl(image, this.backendUrl);
    },
    getRoundedCornerClass(index) {
      if (!this.images || this.images.length === 0) {
        return 'rounded-b';
      } else if (this.images.length === 1 && index > -1) {
        return 'rounded-b';
      } else if (this.images.length >= 2 && index === 0) {
        return 'rounded-bl';
      } else if (this.images.length >= 2 && index === 1) {
        return 'rounded-br';
      }
      return '';
    },
  },
};
</script>
