<template>
  <div>
    <top-component
      dark-themed
      wider
      :image-source="
        require('@/assets/images/about/desktop/om_incoord_hero_bg.jpg')
      "
      :image-srcset="
        require('@/assets/images/about/mobile/om_incoord_hero_bg.jpg')
      "
      scrollTo="#om"
    >
      <template #overline>{{ $t('about.hero.overline') }}</template>
      <template #titleh1>{{ $t('about.hero.text') }}</template>
      /></top-component
    >

    <two-cols id="om" class="py-15 py-sm-11 py-md-15">
      <template #first>
        <text-component dark-themed position="left" max-width="632">
          <template #titleh2>{{ $t('about.twoCol.title') }}</template>
        </text-component>
      </template>
      <template #second>
        <text-component dark-themed position="right">
          <template #content>
            <i18n tag="p" path="about.twoCol.textbr" class="text-body-1">
              <template v-slot:br><br /></template>
              <template v-slot:brbr><br /><br /></template>
            </i18n>
            <i18n path="about.twoCol.textLinked" tag="p">
              <template v-slot:first>
                <a
                  class="text-decoration-none"
                  text
                  @click="navigate('InFuture')"
                  >{{ $t('about.twoCol.infuture') }}</a
                >
              </template>
              <template v-slot:second>
                <a class="text-decoration-none" @click="navigate('#vsys')">{{
                  $t('about.twoCol.vsys')
                }}</a>
              </template>
              <template v-slot:third>
                <a class="text-decoration-none" href="#inforum">{{
                  $t('about.twoCol.inforum')
                }}</a>
              </template>
            </i18n>
          </template>
        </text-component>
      </template>
    </two-cols>

    <v-card
      id="verksamhet"
      class="rounded-0 elevation-0 py-16"
      style="background-color: #003c70"
    >
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col cols="12" sm="10" lg="8">
          <text-component centered>
            <template #overline>{{ $t('about.business.overline') }}</template>
            <template #titleh2>{{ $t('about.business.title') }}</template>
            <template #actions>
              <outlined-button :to="{ name: 'Business' }">
                <template #buttonText>
                  {{ $t('about.business.button') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </v-card>

    <div class="about-page">
      <div class="pt-16 pt-sm-7 pt-lg-10"></div>
      <no-margin-image
        id="infuture"
        imageOnLeft
        :image-source="
          require('@/assets/images/about/_desktop_mobile/infuture.jpg')
        "
        :overline="$t('about.infuture.overline')"
        :titleh2="$t('about.infuture.title')"
        :content="$t('about.infuture.text')"
      >
        <template #button>
          <outlined-button class="" darkContent :to="{ name: 'InFuture' }">
            <template #buttonText>
              {{ $t('about.infuture.button') }}
            </template>
          </outlined-button>
        </template>
      </no-margin-image>

      <no-margin-image
        id="vsys"
        :image-source="
          require('@/assets/images/about/_desktop_mobile/vsys.jpg')
        "
        :overline="$t('about.vsys.overline')"
        :titleh2="$t('about.vsys.title')"
      >
        <template #content>
          {{ $t('about.vsys.text') }}
          <h3 class="text-h3 pt-8 pb-2">
            {{ $t('about.vsys.subtitle1') }}
          </h3>
          {{ $t('about.vsys.text1-1') }}
          <br />
          {{ $t('about.vsys.text1-2') }}
          <h3 class="text-h3 pt-8 pb-2">
            {{ $t('about.vsys.subtitle2') }}
          </h3>
          <i18n tag="p" path="about.vsys.text2br">
            <template v-slot:br><br /></template>
            <template v-slot:brbr><br /><br /></template>
          </i18n>
        </template>
      </no-margin-image>

      <no-margin-image
        id="inforum"
        imageOnLeft
        :image-source="
          require('@/assets/images/about/_desktop_mobile/inforum.jpg')
        "
        :overline="$t('about.inforum.overline')"
        :titleh2="$t('about.inforum.title')"
      >
        <template #content>
          {{ $t('about.inforum.text') }}
          <h3 class="text-h3 pt-8 pb-2">
            {{ $t('about.inforum.subtitle1') }}
          </h3>
          {{ $t('about.inforum.text1') }}
          <h3 class="text-h3 pt-8 pb-2">
            {{ $t('about.inforum.subtitle2') }}
          </h3>
          <i18n tag="p" path="about.inforum.text2br">
            <template v-slot:br><br /></template>
            <template v-slot:brbr><br /><br /></template>
          </i18n>
        </template>
      </no-margin-image>

      <no-margin-image
        id="fou"
        :image-source="
          require('@/assets/images/about/_desktop_mobile/forskning_utveckling_utbildning.jpg')
        "
        :overline="$t('about.rnd.overline')"
        :titleh2="$t('about.rnd.title')"
      >
        <template #content
          ><p>{{ $t('about.rnd.text') }}</p></template
        >
        <template #button>
          <outlined-button class="" darkContent :to="{ name: 'RnD' }">
            <template #buttonText>
              {{ $t('about.rnd.button') }}
            </template>
          </outlined-button>
        </template>
      </no-margin-image>
    </div>

    <three-cols class="py-15 my-15 my-sm-5" id="vision">
      <template #first>
        <text-component
          class="pt-10 pt-sm-0 pt-md-10"
          dark-themed
          position="right"
        >
          <template #overline>{{ $t('about.vision.visionOverline') }}</template>
          <template #titleh2>{{ $t('about.vision.visionText') }}</template>
        </text-component>
      </template>
      <template #second>
        <text-component
          class="pt-5 pt-sm-0 pt-md-10"
          dark-themed
          position="right"
        >
          <template #overline>{{
            $t('about.vision.missionOverline')
          }}</template>
          <template #titleh2>{{ $t('about.vision.missionText') }}</template>
        </text-component>
      </template>
      <template #third>
        <v-card
          flat
          class="mt-5 mt-sm-0 mt-md-10 pl-lg-6 pl-md-4 pb-lg-9 pb-md-4 pt-md-4"
          style="background-color: #003c70"
        >
          <v-card-text class="white--text">
            <span class="card-subtitle pt-lg-5 pt-md-0">{{
              $t('about.vision.organisationTitle1')
            }}</span>
            <p class="text-body-1" style="max-width: 305px">
              {{ $t('about.vision.organisationText1') }}
            </p>
            <span class="card-subtitle pt-8">{{
              $t('about.vision.organisationTitle2')
            }}</span>
            <div :key="name" v-for="name in boardMembers" class="text-body-1">
              {{ name }}
            </div>
          </v-card-text>
        </v-card>
      </template>
    </three-cols>

    <mission-possible></mission-possible>
    <contact-us></contact-us>
  </div>
</template>

<script>
import TwoCols from '../components/partial/TwoCols.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import OutlinedButton from '../components/partial/OutlinedButton.vue';
import MissionPossible from '../components/complete/MissionPossible.vue';
import TopComponent from '../components/complete/TopComponent.vue';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import ContactUs from '../components/complete/ContactUs.vue';
import ThreeCols from '../components/partial/ThreeCols.vue';
import NoMarginImage from '../components/complete/NoMarginImage.vue';
import { mapActions } from 'vuex';

//noinspection JSAnnotator
export default {
  name: 'About',
  data: () => ({}),
  components: {
    TwoCols,
    TextComponent,
    OutlinedButton,
    MissionPossible,
    TopComponent,
    AutoadjustingRow,
    ContactUs,
    ThreeCols,
    NoMarginImage,
  },
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: this.$t('about.breadcrumb'),
          name: 'About',
          disabled: true,
        },
        {
          text: this.$t('about.business.overline'),
          name: '#verksamhet',
          disabled: false,
        },
        {
          text: this.$t('about.infuture.overline'),
          name: '#infuture',
          disabled: false,
        },
        {
          text: this.$t('about.vsys.overline'),
          name: '#vsys',
          disabled: false,
        },
        {
          text: this.$t('about.inforum.overline'),
          name: '#inforum',
          disabled: false,
        },
        {
          text: this.$t('about.rnd.overline'),
          name: '#fou',
          disabled: false,
        },
        {
          text: this.$t('about.vision.visionOverline'),
          name: '#vision',
          disabled: false,
        },
      ];
    },
    boardMembers() {
      return this.$t('about.vision.organisationText2').split(';');
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
    navigate(item) {
      if (item.startsWith('#')) {
        this.$vuetify.goTo(item, { duration: 400 });
      } else {
        this.$router.push({
          name: item,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
p {
  margin-bottom: 16px;
}
.card-subtitle {
  font-weight: bold;
  display: block;
}
.about-page {
  background-color: var(--v-bgabout-base);
}
</style>
