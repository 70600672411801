<template>
  <v-card elevation="0" outlined class="mb-4">
    <v-card-title class="text-h3 pb-0">{{
      $t('project.projectList.cardTitles.search')
    }}</v-card-title>
    <v-card-text>
      <v-text-field
        :placeholder="$t('project.projectList.searchFieldPlaceholder')"
        dense
        hide-details="auto"
        persistent-placeholder
        style="width: 444px"
        v-model="searchFieldContents"
        clearable
        :clear-icon="icons.mdiCloseCircle"
        color="gray lighten-1"
        class="text-body-1"
      ></v-text-field>
      <v-checkbox v-model="includeFiltersInSearch" color="green">
        <template v-slot:label>
          <span class="text-body-1">
            {{ $t('project.projectList.checkboxLabel') }}
          </span>
        </template>
      </v-checkbox>
      <v-card-text
        class="pa-0 mb-2 text-body-1 cursor-pointer"
        v-if="searchFieldContents"
        @click="showFilteredProjects"
      >
        <v-img
          style="display: inline-block; margin-right: 20px"
          max-width="12px"
          src="@/assets/images/search/input_search_icon.svg"
        />{{ searchFieldContents }}</v-card-text
      >
      <template v-for="(result, index) in searchResults">
        <v-card-text class="pa-0 mb-2 text-body-1" :key="index">
          <p class="cursor-pointer" @click="navigateToProject(result)">
            {{ result.title }}
          </p>
        </v-card-text>
      </template></v-card-text
    ></v-card
  >
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdiCloseCircle } from '@mdi/js';

//noinspection JSAnnotator
export default {
  name: 'SearchCard',
  data() {
    return {
      minSearchStringLength: 3,
      icons: {
        mdiCloseCircle,
      },
    };
  },
  computed: {
    ...mapGetters({
      searchResults: 'search/searchResults',
    }),
    includeFiltersInSearch: {
      get() {
        return this.$store.state.includeFiltersInSearch;
      },
      set(value) {
        this.setIncludeFiltersInSearch(value);
      },
    },
    searchFieldContents: {
      get() {
        return this.$store.state.searchFieldContents;
      },
      set(value) {
        this.changeSearchFieldContents(value);
      },
    },
  },
  methods: {
    ...mapActions({
      setSearchResults: 'search/setSearchResults',
      setSearchFieldContents: 'search/setSearchFieldContents',
      clearTextSearchResults: 'search/clearTextSearchResults',
      setIncludeFilters: 'search/setIncludeFilters',
    }),
    setIncludeFiltersInSearch(value) {
      this.setIncludeFilters(value);
      if (!value) {
        this.setSearchResults([]);
        this.$emit('clear-tags-and-cats');
      }
    },
    changeSearchFieldContents(value) {
      if (value === '' || value === null) {
        this.setSearchFieldContents('');
        this.setSearchResults([]);
      } else if (value.length >= this.minSearchStringLength) {
        this.setSearchFieldContents(value);
      }
    },
    showFilteredProjects() {
      this.$emit('show-results');
    },
    navigateToProject(project) {
      this.$emit('close-filter');
      this.$router.push({
        name: 'ProjectDetails',
        params: {
          slug: project.slug,
          id: project.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.v-menu__content.menuable__content__active p {
  color: #00000099;
  text-decoration: none;
}
.v-menu__content.menuable__content__active p::before {
  content: url('~@/assets/images/global/_desktop_mobile/button_arrow_right_bl.svg');
  margin-right: 16px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
