<template>
  <div class="text-center">
    <v-snackbar :color="getColor" v-model="snackbar">
      {{ messageText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
          {{ $t('global.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
  }),
  props: {
    showMe: {
      type: Boolean,
      default: false,
    },
    messageText: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showMe(newValue) {
      this.snackbar = newValue;
    },
  },
  created() {
    this.snackbar = this.showMe;
  },
  computed: {
    getColor() {
      return this.error ? 'error' : 'var(--v-anchor-base)';
    },
  },
};
</script>
