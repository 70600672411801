<template>
  <v-app>
    <nav-bar />
    <v-main>
      <v-overlay
        :absolute="true"
        :value="showOverlay"
        z-index="2"
        @click="closeOverlay"
      ></v-overlay>

      <v-container>
        <router-view />
      </v-container>
    </v-main>
    <page-footer />
  </v-app>
</template>

<script>
import NavBar from './components/complete/NavigationBar.vue';
import PageFooter from './components/complete/PageFooter.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';

//noinspection JSAnnotator
export default {
  name: 'App',
  components: {
    NavBar,
    PageFooter,
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.setMainMarginSize(
      window.innerWidth - this.$vuetify.breakpoint.scrollBarWidth
    );
  },
  computed: {
    ...mapGetters({ showOverlay: 'app/showOverlay' }),
  },
  methods: {
    ...mapMutations(['setMainMarginSize']),
    ...mapActions({
      setShowProjectFilters: 'app/setShowProjectFilters',
      setShowOverlay: 'app/setShowOverlay',
    }),
    handleResize: debounce(function () {
      this.setMainMarginSize(
        window.innerWidth - this.$vuetify.breakpoint.scrollBarWidth
      );
    }, 250),
    closeOverlay() {
      this.setShowProjectFilters(false);
      this.setShowOverlay(false);
    },
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
@import './scss/_font-size-overrides.scss';
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v7/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v7/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4uaVIGxA.woff2)
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVI.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.v-application {
  .container {
    max-width: none;
    padding: 0;
  }
  .v-card__subtitle,
  .v-card__text {
    font-size: 1em;
    line-height: 1.5em;
  }
  p {
    margin-bottom: 0;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
