<template>
  <v-container v-bind="{ ...$attrs }">
    <component :is="returnComponentTag">
      <v-col v-bind="{ ...commonAttrs }" v-if="$slots.first">
        <slot name="first"></slot>
      </v-col>
      <v-col v-bind="{ ...commonAttrs }" v-if="$slots.second">
        <slot name="second"></slot>
      </v-col>
      <v-col v-bind="{ ...commonAttrs }" v-if="$slots.third">
        <slot name="third"></slot>
      </v-col>
      <v-col v-bind="{ ...commonAttrs }" v-if="$slots.fourth">
        <slot name="fourth"></slot>
      </v-col>
      <!-- </autoadjusting-row> -->
      <!-- </v-row> -->
    </component>
  </v-container>
</template>

<script>
import AutoadjustingRow from './AutoadjustingRow.vue';
import VRow from 'vuetify/lib/components/VGrid/VRow';
export default {
  name: 'FourCols',
  props: ['noMargin'],
  components: { AutoadjustingRow, VRow },
  computed: {
    commonAttrs() {
      return {
        class: 'pt-2 pt-sm-4 pt-md-6',
        cols: '12',
        sm: '3',
      };
    },
    returnComponentTag() {
      return this.noMargin ? 'v-row' : 'autoadjusting-row';
    },
  },
};
</script>
