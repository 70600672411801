import { set } from '@/utils/vuex';

export default {
  setLoading: set('loading'),
  setCurrentSubHeaderComponent: set('currentSubHeaderComponent'),
  setCurrentPageBreadCrumbs: set('currentPageBreadCrumbs'),
  setShowProjectFilters: set('showProjectFilters'),
  setShowOverlay: set('showOverlay'),
  setIncludeFilters: set('includeFiltersInSearch'),
};
