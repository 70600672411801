<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '450px' : '650px'"
          class="my-9 mt-md-10"
          dark-themed
        >
          <template #titleh2>{{ $t('privacy.title') }}</template>
          <template #content>
            <p class="mb-2">{{ $t('privacy.date') }}</p>
            <p>{{ $t('privacy.text') }}</p>
          </template>

          <text-component
            v-for="(part, index) in textParts"
            :key="index"
            :max-width="$vuetify.breakpoint.sm ? '450px' : '650px'"
            dark-themed
            class="pb-2"
          >
            <template #contentWithoutFont>
              <h3 class="text-h3 mt-4 mb-2">{{ part.title }}</h3>
              <p class="text-body-1 mb-1">{{ part.text }}</p>
              <ul>
                <li
                  v-for="(item, index) in listItems(part.list)"
                  :key="index"
                  class="text-body-1 ml-2 mb-1"
                >
                  <p class="font-weight-bold mt-3">{{ fatPart(item) }}</p>
                  {{ restPart(item) }}
                </li>
              </ul>
            </template>
          </text-component>
        </text-component>
      </template>
      <template #second> </template>
    </two-cols>

    <contact-us />
  </div>
</template>

<script>
import ContactUs from '../components/complete/ContactUs.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TwoCols from '../components/partial/TwoCols.vue';
import setSubHeaderToNullMixin from '../mixins/setSubHeaderToNullMixin.js';

export default {
  name: 'Privacy',
  components: {
    TwoCols,
    TextComponent,
    ContactUs,
  },
  mixins: [setSubHeaderToNullMixin],
  computed: {
    textParts() {
      return this.$t('privacy.parts');
    },
  },
  methods: {
    listItems(str) {
      if (str && str.length > 0) {
        return str.split('|');
      }
      return [];
    },
    fatPart(item) {
      const index = item.indexOf(';');
      if (index > 0) {
        return item.substring(0, index);
      } else {
        return '';
      }
    },
    restPart(item) {
      const index = item.indexOf(';');
      if (index > 0) {
        return item.substring(index + 1, item.length);
      } else {
        return item;
      }
    },
  },
};
</script>
