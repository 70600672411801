<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '329px' : '638px'"
          class="pt-0 pt-sm-8 pb-10 pb-sm-0"
          dark-themed
        >
          <template #overline v-if="$vuetify.breakpoint.mdAndUp">
            {{
              $t('sustainability.subpages.sustainabilityProjects.top.overline')
            }}</template
          >
          <template #titleh1>{{
            $t('sustainability.subpages.sustainabilityProjects.top.title')
          }}</template>
          <template #content>
            <p class="text-body-1 mb-4">
              {{
                $t('sustainability.subpages.sustainabilityProjects.top.text')
              }}
            </p>
          </template>
        </text-component>
      </template>
      <template #second>
        <v-img
          class="rounded"
          src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_projects/hallbarhet_inom_projekt_topp.jpg"
        >
        </v-img>
      </template>
    </two-cols>
    <div class="sustainability-page pb-15">
      <two-cols class="mt-7 py-15 py-md-11">
        <template #first>
          <text-component dark-themed position="left" max-width="632">
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityProjects.goals.title')
            }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component dark-themed position="right">
            <template #content
              ><div class="mb-4">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityProjects.goals.text'
                  )
                }}
              </div>
            </template>
          </text-component>
        </template>
      </two-cols>

      <two-cols id="cirkular">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t(
                'sustainability.subpages.sustainabilityProjects.cirkular.title'
              )
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityProjects.cirkular.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'CircularEconomy' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityProjects.cirkular.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_projects/cirkular_ekonomi.png"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols id="klimat">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityProjects.climate.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityProjects.climate.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'ReducedImpact' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityProjects.climate.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_projects/minskad_klimatpaverkan.png"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols id="effekt">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityProjects.effect.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityProjects.effect.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'SmartPowerUse' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityProjects.effect.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_projects/smart_effektanvandning.png"
          >
          </v-img>
        </template>
      </two-cols>

      <two-cols id="anpassningar">
        <template #second>
          <text-component
            :max-width="$vuetify.breakpoint.sm ? '284' : '404'"
            class="mt-10"
            dark-themed
          >
            <template #titleh2>{{
              $t('sustainability.subpages.sustainabilityProjects.adapt.title')
            }}</template>
            <template #content>
              <p class="text-body-1">
                {{
                  $t(
                    'sustainability.subpages.sustainabilityProjects.adapt.text'
                  )
                }}
              </p>
              <outlined-button :to="{ name: 'AdaptOutsideWorld' }" darkContent>
                <template #buttonText>
                  {{
                    $t(
                      'sustainability.subpages.sustainabilityProjects.adapt.button'
                    )
                  }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </template>
        <template #first>
          <v-img
            class="rounded"
            src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_projects/anpassningar_omvarld.png"
          >
          </v-img>
        </template>
      </two-cols>
    </div>

    <srcset-image
      :imageLarge="
        require('@/assets/images/sustainability/desktop/sustainability_in_projects/globala_malen_bg.jpg')
      "
      :imageSmall="
        require('@/assets/images/sustainability/mobile/sustainability_in_projects/globala_malen_projekt_bg@2x.jpg')
      "
    >
      <template>
        <v-container fill-height>
          <autoadjusting-row align="center" justify="center">
            <v-col cols="12" sm="6" md="6" class="py-16">
              <srcset-image
                :imageLarge="
                  require('@/assets/images/sustainability/desktop/sustainability_in_projects/globala_malen_inom_projekt.png')
                "
                :imageSmall="
                  require('@/assets/images/sustainability/mobile/sustainability_in_projects/globala_malen_projekt.png')
                "
              ></srcset-image>
            </v-col>
          </autoadjusting-row>
        </v-container>
      </template>
    </srcset-image>

    <three-teasers-projects></three-teasers-projects>
    <contact-us></contact-us>
  </div>
</template>

<script>
import ContactUs from '../../components/complete/ContactUs.vue';
import AutoadjustingRow from '../../components/partial/AutoadjustingRow.vue';
import OutlinedButton from '../../components/partial/OutlinedButton.vue';
import SrcsetImage from '../../components/partial/SrcsetImage.vue';
import TextComponent from '../../components/partial/TextComponent.vue';
import TwoCols from '../../components/partial/TwoCols.vue';
import { mapActions } from 'vuex';
import ThreeTeasersProjects from '../../components/complete/ThreeTeasersProjects.vue';

//noinspection JSAnnotator
export default {
  components: {
    TwoCols,
    TextComponent,
    OutlinedButton,
    SrcsetImage,
    AutoadjustingRow,
    ContactUs,
    ThreeTeasersProjects,
  },
  name: 'SustainabilityProjects',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },

  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: this.$t('sustainability.breadcrumb'),
          name: 'Sustainability',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.breadcrumb'
          ),
          name: 'Sustainability',
          disabled: true,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.cirkular.breadcrumb'
          ),
          name: '#cirkular',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.climate.breadcrumb'
          ),
          name: '#klimat',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.effect.breadcrumb'
          ),
          name: '#effekt',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityProjects.adapt.breadcrumb'
          ),
          name: '#anpassningar',
          disabled: false,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.sustainability-page {
  background-color: var(--v-bgsustainability-base);
}
</style>
