import axios from 'axios';

const apiHost = process.env.VUE_APP_API_BASE_URL;

export const getFromApi = async (path) => {
  return axios.get(`${apiHost}${path}`);
};

export const postToApi = async (path, data) => {
  return axios.post(`${apiHost}${path}`, data);
};
