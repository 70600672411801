<template>
  <v-card elevation="0" outlined class="mb-4">
    <v-card-title class="text-h3 pb-0">{{ card.title }}</v-card-title>
    <v-card-text v-if="card.subTitle" class="pt-0 text-body-1">
      {{ card.subTitle }}
    </v-card-text>
    <v-card-text class="pt-1">
      <v-chip
        small
        v-for="(item, index) in removeTagsWithCountZero(card.content)"
        class="mr-2 my-1 rounded-sm text-body-2"
        :key="index"
        :color="item.selected ? getSelectedColor() : ''"
        :text-color="item.selected ? 'white' : 'black'"
        :outlined="!item.selected"
        @click="toggleSelected(item.id, !item.selected)"
      >
        {{ item.title }}
        <span
          v-if="item.id > 0"
          class="ml-1"
          style="opacity: 0.6"
          :class="item.selected ? 'white--text' : 'black--text'"
        >
          {{ item.count }}</span
        >
      </v-chip>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import * as helper from '../../../js/helper.js';

//noinspection JSAnnotator
export default {
  name: 'FilterCard',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      filteredProjects: 'project/filteredProjects',
      includeFiltersInSearch: 'app/includeFiltersInSearch',
    }),
    commonAttrs() {
      return {
        elevation: 0,
        outlined: true,
      };
    },
    type() {
      return this.card.type || 'categories';
    },
  },
  methods: {
    ...mapActions({
      updateProjectTags: 'project/updateProjectTags',
      updateProjectCategories: 'project/updateProjectCategories',
      setIncludeFilters: 'search/setIncludeFilters',
    }),
    removeTagsWithCountZero(tags) {
      return tags.filter(({ count = 0, id }) => count > 0 || id === -1);
    },
    toggleSelected(id, value) {
      let newSelectionArray = this.card.content.map((item) => {
        let selected = id !== -1 ? item.selected : false;
        if (item.id === id) {
          selected = value;
        }
        if (item.id === -1) {
          selected = id === -1;
        }
        return { ...item, selected };
      });
      if (this.card.type === 'categories') {
        this.updateProjectCategories(newSelectionArray);
      } else if (this.card.type === 'tags') {
        this.updateProjectTags(newSelectionArray);
      }
      if (!helper.isNoCategorySelected(newSelectionArray)) {
        this.setIncludeFilters(true);
      }
    },
    getSelectedColor() {
      if (this.type === 'categories') {
        return 'purple';
      } else {
        return 'black';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-chip {
  &.v-chip--outlined {
    border-width: 1px;
    border-color: #c4c4c4;
  }
  &.v-size--default {
    height: 26px;
  }
}
</style>
