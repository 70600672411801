import { set } from '@/utils/vuex';

export default {
  setLoading: set('loading'),
  setSearchResults: set('searchResults'),
  setSearchFieldContents: set('searchFieldContents'),
  setIncludeFilters: set('includeFiltersInSearch'),
  setProjectCategories: set('projectCategories'),
  setShowingProjectResults: set('showingProjectResults'),
  setSortNewestFirst: set('sortNewestFirst'),
};
