<template>
  <v-list v-if="resultsArray.length > 0">
    <h2 class="text-h2 mb-4">{{ resultsType.title }}</h2>
    <template v-for="item in getResults">
      <div class="mb-6 cursor-pointer" :key="item.id" @click="navigateTo(item)">
        <h3 class="text-h3 mb-1">{{ item.title }}</h3>
        <p class="text--secondary">
          {{ getDate(item) }}{{ shorterContentStripHtml(item.content) }}
        </p>
      </div>
    </template>
    <a v-if="this.maxShown < resultsArray.length" @click="showAll">
      {{ $t('global.show') + ' ' + remainingCount + ' ' + $t('search.more') }}
    </a>
  </v-list>
</template>

<script>
export default {
  name: 'SearchResults',
  props: ['resultsArray', 'resultsType'],
  data() {
    return {
      maxShown: 3,
      maxWords: 20,
    };
  },
  computed: {
    getResults() {
      return this.resultsArray.slice(0, this.maxShown);
    },
    remainingCount() {
      return this.resultsArray.length - this.maxShown;
    },
  },
  methods: {
    shorterContentStripHtml(text) {
      return text
        ? text
            .replace(/(<([^>]+)>)/gi, '')
            .split(' ', this.maxWords)
            .join(' ') + '...'
        : '';
    },
    getDate(item) {
      if (this.resultsType.name === 'news') {
        return new Date(item.date).toLocaleDateString('sv-SV') + ' | ';
      }
      return '';
    },
    showAll() {
      this.maxShown = this.resultsArray.length;
    },
    navigateTo(item) {
      this.$emit('close-search');
      this.$router.push({
        name: this.resultsType.routeName,
        params: { id: item.id, slug: item.slug },
      });
    },
  },
};
</script>

<style scoped>
.item-pointer {
  cursor: pointer;
}
</style>
