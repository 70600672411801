<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '329px' : '638px'"
          class="pt-0 pt-sm-8 pb-10 pb-sm-0"
          dark-themed
        >
          <template #overline v-if="$vuetify.breakpoint.mdAndUp">
            {{
              $t(
                'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.overline'
              )
            }}</template
          >
          <template #titleh1>{{
            $t(
              'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.title'
            )
          }}</template>
          <template #content>
            <p
              class="text-body-1 mb-4"
              v-if="
                $te(
                  'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.textbr'
                )
              "
            >
              <i18n
                path="sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.textbr"
                tag="p"
                ><template v-slot:br><br /></template>
                <template v-slot:brbr>
                  <br />
                  <br />
                </template>
              </i18n>
            </p>
          </template>
        </text-component>
      </template>
      <template #second>
        <v-img
          class="rounded"
          src="@/assets/images/sustainability/_desktop_mobile/sustainability_in_incoord/subpages_top/minskad_klimatpaverkan_topp.jpg"
        >
        </v-img>
      </template>
    </two-cols>

    <div class="sustainability-page">
      <two-cols class="py-md-11 py-lg-15">
        <template #first>
          <text-component dark-themed position="left" max-width="632">
            <template #titleh2>{{
              $t(
                'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.how.title'
              )
            }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component dark-themed position="right">
            <template #content>
              <i18n
                path="sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.how.textbr"
                tag="p"
                ><template v-slot:br><br /></template>
                <template v-slot:brbr>
                  <br />
                  <br />
                </template>
              </i18n>
            </template>
          </text-component>
        </template>
      </two-cols>

      <!-- Hidden by request 2021-12-10
        <two-cols class="py-md-11 py-lg-15">
        <template #first>
          <text-component dark-themed position="left" max-width="632">
            <template #overline>{{
              $t(
                'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.goal.overline'
              )
            }}</template>
            <template #titleh2>{{
              $t(
                'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.goal.title'
              )
            }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component dark-themed position="right" class="mt-0 mt-sm-8">
            <template #content>
              <ul>
                <li v-for="item in listContents" :key="item" class="pb-4">
                  {{ item }}
                </li>
              </ul>
            </template>
          </text-component>
        </template>
      </two-cols> -->
    </div>

    <news-teaser :newsRelatedToTagId="idOfNewsSustainabilityTag">
      <template #newsTitle>
        <h2 class="text-h2 text-center white--text">
          {{ $t('news.teaser.titleRelated') }}
          <br class="d-sm-none d-md-flex" />
        </h2>
      </template>
    </news-teaser>

    <contact-us />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ContactUs from '../../../components/complete/ContactUs.vue';
import NewsTeaser from '../../../components/complete/ThreeTeasersNews.vue';
import TextComponent from '../../../components/partial/TextComponent.vue';
import TwoCols from '../../../components/partial/TwoCols.vue';

//noinspection JSAnnotator
export default {
  components: {
    TwoCols,
    TextComponent,
    NewsTeaser,
    ContactUs,
  },
  name: 'ReducedClimateImpact',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },

  computed: {
    listContents() {
      return this.$t(
        'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.goal.list'
      ).split('|');
    },
    ...mapState(['idOfNewsSustainabilityTag']),
    breadcrumbItems() {
      return [
        {
          text: this.$t('sustainability.breadcrumb'),
          name: 'Sustainability',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.breadcrumb'
          ),
          name: 'SustainabilityIncoord',
          disabled: false,
        },
        {
          text: this.$t(
            'sustainability.subpages.sustainabilityIncoord.subpages.reducedImpact.breadcrumb'
          ),
          name: '',
          disabled: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.sustainability-page {
  background-color: var(--v-bgsustainability-base);
}
</style>
