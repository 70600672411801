<template>
  <div :key="routingKey">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import loadNewsTagsMixin from '../mixins/loadNewsTagsMixin.js';

//noinspection JSAnnotator
export default {
  name: 'News',
  mixins: [loadNewsTagsMixin],
  data() {
    return {
      savedRoutingKey: 0,
    };
  },
  created() {
    this.resetNumberOfItemsToShow();
    this.savedRoutingKey = this.routingKey;
    this.loadNews();
  },
  beforeUpdate() {
    if (this.savedRoutingKey !== this.routingKey) {
      this.savedRoutingKey = this.routingKey;
      this.resetNewsFilterModel();
      this.resetNumberOfItemsToShow();
    }
  },
  computed: {
    ...mapGetters({
      allNews: 'news/allNews',
      routingKey: 'app/routingKey',
    }),
  },
  methods: {
    ...mapActions({
      loadNews: 'news/loadNews',
      resetNumberOfItemsToShow: 'news/resetNumberOfItemsToShow',
      resetNewsFilterModel: 'news/resetNewsFilterModel',
    }),
  },
};
</script>
