//startpage desktop
import startHeroDesktop from '@/assets/images/start/desktop/incoord_hero_bg.jpg';
//startpage mobile
import startPageMobile from '@/assets/images/start/mobile/incoord_hero_bg.jpg';

//project desktop
import bostaderDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/bostader_hero_bg.jpg';
import hotellDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/hotell_hero_bg.jpg';
import industriDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/industri_hero_bg.jpg';
import laboratorierDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/laboratorier_hero_bg.jpg';
import handelDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/handel_hero_bg.jpg';
import kontorDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/kontor_hero_bg.jpg';
import sjukvardDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/sjukvard_hero_bg.jpg';
import utbildningslokalerDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/utbildningslokaler_hero_bg.jpg';
import kulturDesktop from '@/assets/images/project/desktop/_projekt_case_kategori/kultur_hero_bg.jpg';
//project mobile
import bostaderMobile from '@/assets/images/project/mobile/_projekt_case_kategori/bostader_hero_bg.jpg';
import hotellMobile from '@/assets/images/project/mobile/_projekt_case_kategori/hotell_hero_bg.jpg';
import industriMobile from '@/assets/images/project/mobile/_projekt_case_kategori/industri_hero_bg.jpg';
import laboratorierMobile from '@/assets/images/project/mobile/_projekt_case_kategori/laboratorier_hero_bg.jpg';
import handelMobile from '@/assets/images/project/mobile/_projekt_case_kategori/handel_hero_bg.jpg';
import kontorMobile from '@/assets/images/project/mobile/_projekt_case_kategori/kontor_hero_bg.jpg';
import sjukvardMobile from '@/assets/images/project/mobile/_projekt_case_kategori/sjukvard_hero_bg.jpg';
import utbildningslokalerMobile from '@/assets/images/project/mobile/_projekt_case_kategori/utbildningslokaler_hero_bg.jpg';
import kulturMobile from '@/assets/images/project/mobile/_projekt_case_kategori/kultur_hero_bg.jpg';

export const startpageImages = {
  startHeroDesktop,
  startPageMobile,
};

export const projectBackgroundImages = {
  bostaderDesktop,
  hotellDesktop,
  industriDesktop,
  laboratorierDesktop,
  handelDesktop,
  kontorDesktop,
  sjukvardDesktop,
  utbildningslokalerDesktop,
  kulturDesktop,
  bostaderMobile,
  hotellMobile,
  industriMobile,
  laboratorierMobile,
  handelMobile,
  kontorMobile,
  sjukvardMobile,
  utbildningslokalerMobile,
  kulturMobile,
};
