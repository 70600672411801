<template>
  <div class="mb-6">
    <slot name="title"></slot>
    <v-chip
      v-for="tag in results"
      :key="tag.id"
      class="ml-2 rounded-sm text-body-2"
      :color="getChipColor"
      text-color="white"
      style="max-height: 26px"
      @click="clickTag(tag)"
    >
      {{ tag.title }}
    </v-chip>
  </div>
</template>
<script>
export default {
  name: 'SearchTagsResults',
  props: ['results', 'resultsType'],
  computed: {
    getChipColor() {
      if (this.resultsType === 'categories') {
        return 'purple';
      } else {
        return 'black';
      }
    },
  },
  methods: {
    clickTag(tag) {
      this.$emit('tag-clicked', tag, this.resultsType);
    },
  },
};
</script>
