import { postToApi } from '@/utils/api';

const postContactUrl = 'contact';
const postApplicationUrl = 'apply';
const postNewsletterUrl = 'newsletters/signup';

export default {
  postContact: async ({ commit }, formData) => {
    commit('setLoading', true);
    await postToApi(postContactUrl, formData);
    commit('setLoading', true);
  },
  postApplication: async ({ commit }, formData) => {
    commit('setLoading', true);
    await postToApi(postApplicationUrl, formData);
    commit('setLoading', false);
  },
  postNewsletter: async ({ commit }, formData) => {
    commit('setLoading', true);
    await postToApi(postNewsletterUrl, formData);
    commit('setLoading', false);
  },
  updateCurrentSubHeaderComponent({ commit }, component) {
    commit('setCurrentSubHeaderComponent', component);
  },
  updateCurrentPageBreadCrumbs({ commit }, breadCrumbs) {
    commit('setCurrentPageBreadCrumbs', breadCrumbs);
  },
  setShowProjectFilters: async ({ commit }, payload) => {
    commit('setShowProjectFilters', payload);
  },
  setShowOverlay: async ({ commit }, payload) => {
    commit('setShowOverlay', payload);
  },
  setIncludeFilters: async ({ commit }, value) => {
    commit('setIncludeFilters', value);
  },
};
