<template>
  <div class="text-center ma-2">
    <v-progress-circular :width="2" indeterminate></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: 'LoadingIndicator',
};
</script>
