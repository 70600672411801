<template>
  <v-card elevation="0" outlined class="mb-4">
    <v-card-title class="text-h3 pb-0">{{ card.title }}</v-card-title>
    <v-card-text class="pt-0 text-body-1">{{ card.subTitle }} </v-card-text>
    <v-card-text class="pt-1">
      <v-chip
        small
        v-for="item in card.content"
        class="mr-2 my-1 rounded-sm text-body-2"
        :key="item.id"
        :color="item.selected ? 'black' : ''"
        :text-color="item.selected ? 'white' : 'black'"
        :outlined="item.selected ? false : true"
        @click="toggleSort(item.name)"
      >
        {{ item.title }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'SortCard',
  computed: {
    ...mapState(['sortNewestFirst']),
    sortTags() {
      return [
        {
          id: -1,
          name: 'newest',
          title: this.$t('project.projectList.sortOrder.newestFirst'),
          selected: this.sortNewestFirst,
        },
        {
          id: -2,
          name: 'az',
          title: this.$t('project.projectList.sortOrder.az'),
          selected: !this.sortNewestFirst,
        },
      ];
    },
    card() {
      return {
        title: this.$t('project.projectList.cardTitles.sort'),
        subTitle: this.sortNewestFirst
          ? this.$t('project.projectList.sortOrder.newestFirst')
          : this.$t('project.projectList.sortOrder.az'),
        content: this.sortTags,
      };
    },
  },
  methods: {
    ...mapMutations(['setSortNewestFirst']),
    toggleSort(sort) {
      if (sort === 'newest') {
        this.setSortNewestFirst(true);
      } else {
        this.setSortNewestFirst(false);
      }
    },
  },
};
</script>
