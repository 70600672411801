<template>
  <autoadjusting-row>
    <v-col>
      <v-menu
        v-if="showMenuStyleBreadcrumbs"
        min-width="100%"
        offset-y
        flat
        tile
        content-class="elevation-0"
        v-model="showMobileBreadcrumbMenu"
        :disabled="mobileItems.length < 1"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="mb-0"
            v-show="parentPageBreadcrumb !== null"
            @click="navigate(parentPageBreadcrumb)"
            >$arrowBack
          </v-icon>
          <v-btn
            :ripple="false"
            text
            plain
            tile
            class="d-inline text-h3 black--text px-0"
            v-bind="attrs"
            v-on="on"
          >
            {{ pageMainBreadcrumb.text }}
            <v-icon
              class="pl-2 pt-1"
              v-if="mobileItems.length > 1 && !showMobileBreadcrumbMenu"
              >{{ icons.mdiChevronDown }}
            </v-icon>
            <v-icon
              class="pl-2 pt-1"
              v-if="mobileItems.length > 1 && showMobileBreadcrumbMenu"
              >{{ icons.mdiChevronUp }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(child, index) in mobileItems">
            <v-list-item :key="child.text" class="pl-8">
              <v-list-item-title
                @click="navigate(child, $event)"
                :class="breadcrumbClass(child)"
                >{{ child.text }}</v-list-item-title
              >
            </v-list-item>
            <v-divider
              v-if="index != mobileItems.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-menu>
      <v-breadcrumbs v-else :items="getItems" class="pl-0">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :id="item.name"
            @click="navigate(item, $event)"
            :class="breadcrumbClass(item)"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template></v-breadcrumbs
      >
    </v-col>
  </autoadjusting-row>
</template>

<script>
import AutoadjustingRow from './AutoadjustingRow.vue';
import { mapMutations } from 'vuex';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

export default {
  components: { AutoadjustingRow },
  name: 'PageBreadcrumbs',
  props: ['items'],
  watch: {
    showMobileBreadcrumbMenu: function (newValue) {
      if (newValue) {
        document.documentElement.style.overflow = 'hidden';
        this.setShowOverlay(true);
      } else {
        document.documentElement.style.overflow = 'auto';
        this.setShowOverlay(false);
      }
    },
  },
  data() {
    return {
      drawer: null,
      snackbar: true,
      showMobileBreadcrumbMenu: false,
      icons: {
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },
  computed: {
    getItems() {
      if (this.$vuetify.breakpoint.xs) {
        //special case for Projekt and Nyheter, desktop-style breadcrumbs in mobile
        return this.items.map((item) => {
          if (item.disabled && item.text.length > 27) {
            item.text = item.text.substring(0, 27) + '...';
            return item;
          } else {
            return item;
          }
        });
      } else {
        return this.items;
      }
    },
    mobileItems() {
      const index = this.items.findIndex((item) => item.disabled);
      return this.items.slice(index + 1);
    },
    pageMainBreadcrumb() {
      return this.items.find((item) => item.disabled);
    },
    parentPageBreadcrumb() {
      //find the last parent page (disabled = false) before current page breadcrumb
      for (let i = 0; i < this.items.length; i++) {
        if (
          !this.items[i].disabled &&
          i + 1 < this.items.length &&
          this.items[i + 1].disabled
        ) {
          return this.items[i];
        }
      }
      return null;
    },
    showMenuStyleBreadcrumbs() {
      if (
        this.$route.matched.some(({ name }) => name === 'NewsDetails') ||
        this.$route.matched.some(({ name }) => name === 'ProjectDetails')
      ) {
        return false;
      }
      if (this.$vuetify.breakpoint.xs) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations(['setShowOverlay']),
    breadcrumbClass(item) {
      let classes = 'black--text';
      if (item.disabled) {
        if (!item.thinText) {
          classes = classes + ' font-weight-bold is_active';
        }
      } else {
        classes = classes + ' cursor-pointer';
        if (item.name && item.name.startsWith('#')) {
          classes = classes + ' on-page-breadcrumb';
        }
      }
      return classes;
    },
    navigate(item) {
      if (!item.disabled) {
        const routeName = item.name;
        if (routeName.startsWith('#')) {
          this.$vuetify.goTo(routeName, { duration: 400 });
        } else {
          this.$router.push({
            name: routeName,
          });
        }
      }
    },
  },
};
</script>

<style scoped>
li:first-child:not(.is_active) >>> .v-breadcrumbs__item:before {
  content: url('~@/assets/images/global/_desktop_mobile/button_arrow_right_bl.svg');
  margin-right: 16px;
  transform: rotate(180deg);
}
.cursor-pointer {
  cursor: pointer;
}
.on-page-breadcrumb >>> .v-breadcrumbs__item,
.v-list-item__title.on-page-breadcrumb {
  color: var(--v-anchor-base) !important;
}

.v-breadcrumbs >>> li:nth-child(even) {
  padding: 0 4px;
}
</style>
