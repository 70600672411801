import { mapActions, mapGetters } from 'vuex';

//noinspection JSAnnotator
export default {
  created() {
    if (!this.newsTagsLoading) {
      this.initializeNewsTags();
    }
  },
  computed: {
    ...mapGetters({
      newsTags: 'news/newsTags',
      newsTagsLoading: 'news/newsTagsLoading',
    }),
    newsTagsLoaded() {
      return this.newsTags.length > 0;
    },
  },
  methods: {
    ...mapActions({
      loadNewsTags: 'news/loadNewsTags',
      setNewsTags: 'news/setNewsTags',
      setNewsTagsLoading: 'news/setNewsTagsLoading',
    }),
    initializeNewsTags() {
      this.setNewsTagsLoading(true);
      this.loadNewsTags();
    },
    getNewsTagColor(id) {
      switch (id) {
        case 1:
          return 'tagdarkpink';
        case 2:
          return 'taggreen';
        case 3:
          return 'taglightblue';
        case 4:
          return 'tagdarkblue';
        case 5:
          return 'tagorange';
        default:
          return 'black';
      }
    },
  },
};
