import { getFromApi } from '@/utils/api';

const newsTagsUrl = 'news/tags';
const newsUrl = 'news/published';
const newsSearchUrl = 'news/search/';

export default {
  loadNews: async ({ commit }) => {
    commit('setLoading', true);
    const { data } = await getFromApi(newsUrl);
    commit('setAllNews', data);
    commit('setLoading', false);
  },
  loadNewsTags: async ({ commit }) => {
    commit('setLoading', true);
    const { data } = await getFromApi(newsTagsUrl);
    commit('setNewsTags', data);
    commit('setLoading', false);
  },
  resetNumberOfItemsToShow: async ({ commit, state }) => {
    const { initalItemsToShow } = state;
    commit('setNumberOfItemsToShow', initalItemsToShow);
  },
  resetNewsFilterModel: async ({ commit }) => {
    commit('setNewsFilterModel', { tag: '', year: '' });
  },
  setNewsFilterModel: async ({ commit }, payload) => {
    commit('setNewsFilterModel', payload);
  },
  setNewsTags: async ({ commit }, value) => {
    commit('setNewsTags', value);
  },
  setNewsTagsLoading: async ({ commit }, value) => {
    commit('setNewsTagsLoading', value);
  },
  setNumberOfItemsToShow: async ({ commit }, value) => {
    commit('setNumberOfItemsToShow', value);
  },
  getNewsItems: async ({ commit }, relatedToId) => {
    commit('setLoading', true);
    let url;
    if (relatedToId) {
      url = `news/tags/${relatedToId}/news?page_size=3`;
    } else {
      url = newsUrl + '?page_size=3';
    }
    const { data } = await getFromApi(url);
    commit('setTeaaserItems', data);
    commit('setLoading', true);
  },
  searchNews: async ({ commit }, searchFor) => {
    commit('setLoading', true);
    const { data } = await getFromApi(`${newsSearchUrl}${searchFor}`);
    commit('setSearchResultsNews', data);
    commit('setLoading', false);
  },
  setSearchResultsNews: async ({ commit }, payload) => {
    commit('setSearchResultsNews', payload);
  },
};
