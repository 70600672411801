<template>
  <v-container>
    <loading-indicator v-if="loading"></loading-indicator>
    <div v-else>
      <big-news :item="newsItem"></big-news>
      <list-with-teaser :items="getFirstFourItems()"></list-with-teaser>
      <list-component :items="getItems()">
        <template v-slot:default="slotProps">
          <small-news :item="slotProps.item"> </small-news>
        </template>
      </list-component>
    </div>
    <contact-us></contact-us>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingIndicator from '../../components/complete/LoadingIndicator.vue';
import BigNews from '../../components/news/BigNews.vue';
import ListComponent from '../../components/partial/ListComponent.vue';
import ListWithTeaser from '../../components/news/ListWithTeaser.vue';
import SmallNews from '../../components/news/SmallNews.vue';
import ContactUs from '../../components/complete/ContactUs.vue';

//noinspection JSAnnotator
export default {
  components: {
    ListWithTeaser,
    ListComponent,
    LoadingIndicator,
    BigNews,
    SmallNews,
    ContactUs,
  },
  name: 'NewsDetails',
  computed: {
    ...mapGetters({
      allNews: 'news/allNews',
      getNewsList: 'news/getNewsList',
      getNewsItem: 'news/getNewsItem',
    }),
    loading() {
      return this.allNews.length === 0;
    },
    newsItem() {
      return this.getNewsItem(this.$route.params);
    },
  },
  methods: {
    getNewsFiltered() {
      return this.getNewsList().filter((item) => item.id !== this.newsItem.id);
    },
    getFirstFourItems() {
      return this.getNewsFiltered().slice(0, 4);
    },
    getItems() {
      return this.getNewsFiltered().slice(4);
    },
  },
};
</script>
