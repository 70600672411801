export default {
  loading: false,
  allProjects: [],
  filteredProjects: [],
  projectTags: [],
  projectCategories: [],
  showProjectFilters: false,
  searchResultsProjects: [],
  searchResultsProjectsTags: [],
  showingProjectResults: false,
  featuredProjects: [],
  featuredProjectsLoaded: false,
  idOfProjectSustainabilityTag:
    process.env.VUE_APP_ID_PROJECTTAG_SUSTAINABILITY,
  projectTeaserItems: [],
};
