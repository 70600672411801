<template>
  <v-row class="py-10" no-gutters>
    <v-col cols="12" sm="6" :order-sm="orderSm(1)">
      <v-card flat class="rounded-0 transparent" v-bind="{ ...cardAttrs }">
        <v-img :class="roundedClass" :src="imageSource" />
      </v-card>
    </v-col>
    <v-col cols="12" sm="6" :order-sm="orderSm(2)">
      <v-card
        flat
        class="transparent px-0 black--text mt-5 mt-sm-0 pt-lg-10"
        :max-width="$vuetify.breakpoint.sm ? '284px' : '642px'"
        :class="imageOnLeft ? 'ml-md-6' : ''"
        v-bind="{ ...$attrs, ...textCardAttrs }"
      >
        <h3 class="text-h3 pb-5">
          {{ overline }}
        </h3>
        <h2 class="text-h2 pb-5 max-width-text">
          {{ titleh2 }}
        </h2>
        <p>{{ content }}</p>
        <slot name="content"> </slot>
        <slot name="button"> </slot>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NoMarginImage',
  props: {
    imageOnLeft: Boolean,
    imageSource: String,
    overline: String,
    titleh2: String,
    content: String,
  },
  computed: {
    ...mapState(['mainMarginSize']),
    roundedClass() {
      return this.imageOnLeft ? 'rounded-r' : 'rounded-l';
    },
    cardAttrs() {
      return this.imageOnLeft
        ? {
            style: 'margin-right: ' + this.marginImage() + 'px',
          }
        : { style: 'margin-left: ' + this.marginImage() + 'px' };
    },
    textCardAttrs() {
      if (this.$vuetify.breakpoint.xs) {
        return {
          style:
            'margin-right: ' +
            this.mainMarginSize +
            'px; margin-left: ' +
            this.mainMarginSize +
            'px',
        };
      } else {
        return this.imageOnLeft
          ? {
              style:
                'margin-right: ' +
                this.mainMarginSize +
                'px; margin-left: ' +
                this.marginImage() +
                'px',
            }
          : { style: 'margin-left: ' + this.mainMarginSize + 'px' };
      }
    },
  },
  methods: {
    marginImage() {
      return this.$vuetify.breakpoint.xs ? this.mainMarginSize : '12';
    },
    orderSm(val) {
      if (!this.imageOnLeft && this.$vuetify.breakpoint.smAndUp) {
        if (val === 1) {
          return 2;
        }
        if (val === 2) {
          return 1;
        }
      }
      return val;
    },
  },
};
</script>

<style scoped>
.max-width-text {
  max-width: 404px;
}
</style>
