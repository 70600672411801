<template>
  <v-container>
    <loading-indicator v-if="loading || loadingFeatured"></loading-indicator>
    <div v-else>
      <big-project :project="showProject"></big-project>
      <list-component :items="listOfProjects">
        <template v-slot:default="slotProps">
          <small-project :project="slotProps.item"> </small-project>
        </template>
        <template
          #featuredProject
          v-if="!showingProjectResults && featuredProject"
        >
          <featured-project
            :featuredProject="featuredProject"
          ></featured-project>
        </template>
      </list-component>
    </div>
    <contact-us></contact-us>
  </v-container>
</template>

<script>
import BigProject from '../../components/project/details/BigProject.vue';
import { mapGetters } from 'vuex';
import ListComponent from '../../components/partial/ListComponent.vue';
import LoadingIndicator from '../../components/complete/LoadingIndicator.vue';
import SmallProject from '../../components/project/list/SmallProject.vue';
import FeaturedProject from '../../components/complete/FeaturedProject.vue';
import featuredProjectMixin from '../../mixins/featuredProjectMixin.js';
import ContactUs from '../../components/complete/ContactUs.vue';

//noinspection JSAnnotator
export default {
  name: 'ProjectDetails',
  params: ['slug', 'id'],
  mixins: [featuredProjectMixin],
  components: {
    BigProject,
    ListComponent,
    LoadingIndicator,
    SmallProject,
    FeaturedProject,
    ContactUs,
  },
  watch: {
    showProject: {
      immediate: true,
      handler: function () {
        if (!this.showingProjectResults) {
          this.getFeaturedProject();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      getProject: 'project/getProject',
      loading: 'project/loading',
      filteredProjects: 'project/filteredProjects',
      showingProjectResults: 'project/showingProjectResults',
    }),
    showProject() {
      const project = this.getProject(this.$route.params);
      if (!project) {
        //error handling here
      }
      return project;
    },
    loadingFeatured() {
      return this.showingProjectResults ? false : !this.featuredProjectsLoaded;
    },
    listOfProjects() {
      let tempList = this.filteredProjects.filter(
        (item) => item.id !== this.showProject.id
      );
      if (this.featuredProject) {
        tempList = tempList.filter(
          (item) => item.id !== this.featuredProject.id
        );
      }
      return tempList;
    },
  },
};
</script>
