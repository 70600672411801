<template>
  <v-footer dark color="bgdark" class="px-0">
    <autoadjusting-row>
      <v-col cols="12" sm="6" md="4" :class="columnClasses">
        <h2 class="pr-lg-16 pr-md-6">
          {{ $t('footer.title') }}
        </h2>
        <v-list dense color="bgdark">
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            :to="{ name: item.routeName }"
            class="pa-0 text-body-1"
          >
            <v-list-item-content>
              <v-list-item-subtitle v-text="item.title"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card
          color="bgdark"
          elevation="0"
          :href="$t('footer.followusLink')"
          target="_blank"
        >
          <v-card-title class="px-0 mx-0">
            <v-img
              alt="LinkedIn logo"
              src="@/assets/images/global/_desktop_mobile/footer_linkedin_icon.svg"
              max-height="36px"
              max-width="36px"
              class="mr-2"
            />
            <span class="text-body-1 font-weight-light">
              {{ $t('footer.followus') }}</span
            >
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" :class="columnClasses">
        <v-img
          alt="Map"
          src="@/assets/images/global/_desktop_mobile/footer_map_icon.svg"
          max-height="50px"
          max-width="48px"
          class="mt-3"
        />
        <v-card color="bgdark" elevation="0">
          <v-card-text class="px-0">
            <h3 class="text-h3">
              {{ $t('footer.incoord') }}
            </h3>
            <p>
              {{ $t('footer.phone') }}
            </p>
            <i18n path="footer.email" tag="p">
              <template v-slot:link>
                <a class="text-decoration-none" href="mailto:info@incoord.se">{{
                  $t('footer.emailLink')
                }}</a>
              </template>
            </i18n>
            <p>
              {{ $t('footer.address') }}
            </p>
          </v-card-text>
        </v-card>
        <outlined-button
          :href="$t('footer.findusLink')"
          target="_blank"
          :darkContent="false"
        >
          <template #buttonText>
            {{ $t('footer.findus') }}
          </template>
        </outlined-button>
      </v-col>

      <v-col cols="12" sm="auto" md="4" :class="columnClasses">
        <v-img
          alt="Documents"
          src="@/assets/images/global/_desktop_mobile/footer_docs_icon.svg"
          max-height="48px"
          max-width="38px"
          class="mt-3"
        />
        <v-card color="bgdark" elevation="0">
          <v-card-text class="px-0">
            <a
              :href="getPathToAccountingFile"
              download
              class="text-decoration-none"
            >
              {{ $t('footer.download.accounting') }}
            </a>
            <span> {{ $t('footer.download.fileending') }}</span>
            <div class="mb-4">
              {{ $t('footer.accounting') }}
            </div>
            <div>
              <a
                :href="getPathToSustainabilityFile"
                download
                class="text-decoration-none"
              >
                {{ $t('footer.download.sustainability') }}
              </a>
              <span> {{ $t('footer.download.fileending') }}</span>
              <div>
                {{ $t('footer.sustainability') }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </autoadjusting-row>
  </v-footer>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import getPdfFromBackendMixin from '../../mixins/getBackendPathToPdf.js';
import { mapState } from 'vuex';

export default {
  components: { AutoadjustingRow, OutlinedButton },
  mixins: [getPdfFromBackendMixin],
  data() {
    return {
      columnClasses: 'px-xs-0 pl-lg-2 py-8',
    };
  },
  computed: {
    ...mapState(['menuItems']),
  },
};
</script>

<style scoped lang="scss">
.row {
  padding-top: 50px !important;
}
.v-card {
  p:last-child {
    padding-top: 20px;
  }
}
.v-list-item {
  min-height: 20px !important;
  max-width: fit-content;
}
.v-list .v-list-item--active:before,
.v-list-item:hover::before {
  opacity: 0;
}
</style>
