import { mapActions, mapGetters } from 'vuex';

//noinspection JSAnnotator
export default {
  data() {
    return {
      featuredProject: null,
    };
  },
  computed: {
    ...mapGetters({
      featuredProjects: 'project/featuredProjects',
      featuredProjectsLoaded: 'project/featuredProjectsLoaded',
    }),
  },
  methods: {
    ...mapActions({
      loadFeaturedProjects: 'project/loadFeaturedProjects',
    }),
    getFeaturedProject() {
      if (this.featuredProjectsLoaded) {
        this.featuredProject = this.getRandomFeaturedProject();
      } else {
        this.loadFeaturedProjects().then(
          () => {
            this.featuredProject = this.getRandomFeaturedProject();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    getRandomFeaturedProject() {
      if (this.featuredProjects.length > 0) {
        const random = Math.floor(Math.random() * this.featuredProjects.length);
        return this.featuredProjects[random];
      } else {
        return null;
      }
    },
  },
};
