<template>
  <v-sheet>
    <label class="text-body-1" :for="id">
      {{ label }}
    </label>
    <v-checkbox
      v-model="currentValue"
      :color="color"
      :rules="required ? rules : []"
      @change="onUpdate"
    >
      <template v-slot:label>
        <div class="text-body-1">
          {{ placeholder }}
          <slot name="link" />
        </div>
      </template>
    </v-checkbox>
  </v-sheet>
</template>

<script>
import IncoordFormcontrolMixin from '@/components/core/IncoordFormcontrolMixin';

export default {
  name: 'IncoordCheckbox',
  mixins: [IncoordFormcontrolMixin],
};
</script>

<style scoped></style>
