<template>
  <v-navigation-drawer
    right
    v-model="drawer"
    app
    temporary
    :width="computedWidth"
    clipped
    class="search-field"
  >
    <v-container>
      <v-row v-bind="{ ...commonRowAttrs }" class="mt-n1">
        <v-spacer></v-spacer>
        <v-col cols="12" sm="10">
          <h2>{{ $t('global.search') }}</h2>
          <div class="d-flex mb-6 mt-4">
            <v-text-field
              class="shrink rounded-sm"
              :prepend-inner-icon="showIcon ? '$search' : undefined"
              :clear-icon="icons.mdiCloseCircle"
              color="grey"
              clearable
              :label="$t('global.search')"
              single-line
              dense
              hide-details="auto"
              :placeholder="$t('global.search')"
              :rules="rules"
              persistent-placeholder
              outlined
              v-model="searchPhrase"
              @click:clear="clearSearch"
              @keyup.enter="search"
            >
              ></v-text-field
            >
            <v-btn
              outlined
              @click="search"
              height="36px"
              class="text-h3 rounded-sm ml-1"
              style="margin-top: 2px"
              :disabled="searchPhrase && searchPhrase.length < 3"
            >
              {{ $t('global.search') }}
            </v-btn>
            <v-img
              class="ml-auto mt-3 cursor-pointer"
              src="@/assets/images/global/_desktop_mobile/close_icon.svg"
              max-height="18px"
              max-width="18px"
              @click="closeSearch"
            ></v-img>
          </div>
          <popular-searches
            v-if="!isSearching"
            @search-for="onPopularClicked"
          ></popular-searches>
          <loading-indicator v-if="!loadingDone"></loading-indicator>
          <div v-show="loadingDone">
            <v-chip-group
              v-if="!resultsEmpty"
              v-model="showResultGroup"
              mandatory
              active-class="black white--text"
            >
              <v-chip v-for="size in selectionChips" :key="size" :value="size">
                {{ size }}
              </v-chip></v-chip-group
            >
            <div
              class="mb-10 mt-8"
              v-show="
                showResultGroup === labelAll ||
                showResultGroup === labelProjects
              "
            >
              <search-projects
                @close-search="closeSearch"
                @loading-done="projectsResultsLoading = false"
                :searchFor="searchFor"
              ></search-projects>
              <search-tags
                v-if="catsAndTagsLoaded"
                @close-search="closeSearch"
                @loading-done="projectsTagsResultsLoading = false"
                :searchFor="searchFor"
              ></search-tags>
            </div>
            <div
              class="mb-10 mt-8"
              v-show="
                showResultGroup === labelAll || showResultGroup === labelNews
              "
            >
              <search-news
                @close-search="closeSearch"
                @loading-done="newsResultsLoading = false"
                :searchFor="searchFor"
              ></search-news>
              <search-news-tags
                v-if="newsTagsLoaded"
                @close-search="closeSearch"
                @loading-done="newsTagsResultsLoading = false"
                :searchFor="searchFor"
              ></search-news-tags>
            </div>
            <div v-if="isSearching && resultsEmpty" class="mt-16">
              <p class="grey--text darken-2">
                {{ $t('search.noMatch') }} {{ searchPhrase }}
              </p>
            </div>
            <div v-if="isSearching && !resultsEmpty">
              <p class="grey--text darken-2 mb-4">
                {{ $t('search.noMoreResults') }}
                <span class="black--text">'{{ searchPhrase }}'</span>
                <span
                  v-if="showResultGroup !== labelAll"
                  class="grey--text darken-2"
                >
                  {{ this.$t('search.in') }} {{ showResultGroup }}</span
                >
              </p>
              <p
                class="black--text font-weight-bold text-decoration-underline cursor-pointer"
                @click="closeSearch"
              >
                {{ $t('global.close') }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import PopularSearches from './PopularSearches.vue';
import { mapGetters } from 'vuex';
import LoadingIndicator from '../complete/LoadingIndicator.vue';
import SearchProjects from './SearchProjects.vue';
import SearchTags from './SearchProjectTags.vue';
import SearchNews from './SearchNews.vue';
import SearchNewsTags from './SearchNewsWithTags.vue';
import loadProjectCategoriesAndTagsMixin from '../../mixins/loadProjectCategoriesAndTagsMixin.js';
import loadNewsTagsMixin from '../../mixins/loadNewsTagsMixin.js';
import { mdiCloseCircle } from '@mdi/js';

//noinspection JSAnnotator
export default {
  components: {
    PopularSearches,
    LoadingIndicator,
    SearchProjects,
    SearchTags,
    SearchNewsTags,
    SearchNews,
  },
  name: 'SearchComponent',
  props: ['drawer'],
  mixins: [loadProjectCategoriesAndTagsMixin, loadNewsTagsMixin],
  watch: {
    searchPhrase(val) {
      if (val) {
        this.showIcon = false;
      } else {
        this.clearSearch();
        this.showIcon = true;
      }
    },
    loadingDone(val) {
      if (val) {
        if (this.selectionChips && this.selectionChips.length > 0) {
          this.showResultGroup = this.selectionChips[0];
        } else {
          this.showResultGroup = '';
        }
      }
    },
  },
  data() {
    return {
      labelAll: this.$t('global.all'),
      labelNews: this.$t('global.news'),
      labelProjects: this.$t('global.project'),
      showIcon: true,
      searchPhrase: '',
      searchFor: '',
      projectsResultsLoading: false,
      projectsTagsResultsLoading: false,
      newsResultsLoading: false,
      newsTagsResultsLoading: false,
      isSearching: false,
      showResultGroup: this.labelAll,
      icons: {
        mdiCloseCircle,
      },
      rules: [
        (value) =>
          (value && value.length >= this.minSearchStringLength) ||
          this.$t('search.minimumCharacters'),
      ],
    };
  },
  computed: {
    ...mapGetters({
      searchResultsProjects: 'project/searchResultsProjects',
      searchResultsProjectsTags: 'project/searchResultsProjectsTags',
      searchResultsNews: 'news/searchResultsNews',
      searchResultsNewsTags: 'news/searchResultsNewsTags',
      minSearchStringLength: 'search/minSearchStringLength',
    }),
    computedWidth() {
      return this.$vuetify.breakpoint.xs ? '100%' : '50%';
    },
    commonRowAttrs() {
      let mobileStyle = '';
      if (this.$vuetify.breakpoint.xs) {
        mobileStyle = '; margin-left: ' + this.marginWidth() + 'px';
      }
      return {
        style: 'margin-right: ' + this.marginWidth() + 'px' + mobileStyle,
      };
    },
    loadingDone() {
      return !this.projectsResultsLoading && !this.newsResultsLoading;
    },
    resultsEmpty() {
      return (
        this.searchResultsProjects.length === 0 &&
        this.searchResultsProjectsTags.length === 0 &&
        this.searchResultsNews.length === 0 &&
        this.searchResultsNewsTags.length === 0
      );
    },
    selectionChips() {
      let result = [this.labelAll];
      if (
        this.searchResultsProjects.length > 0 ||
        this.searchResultsProjectsTags.length > 0
      ) {
        result.push(this.labelProjects);
      }
      if (
        this.searchResultsNews.length > 0 ||
        this.searchResultsNewsTags.length > 0
      ) {
        result.push(this.labelNews);
      }
      if (result.length < 3) {
        result.shift();
      }
      return result;
    },
  },
  methods: {
    marginWidth() {
      return this.$store.state.mainMarginSize;
    },
    closeSearch() {
      this.$emit('close-drawer');
    },
    onPopularClicked(value) {
      this.searchPhrase = value;
      this.search();
    },
    clearSearch() {
      this.searchPhrase = '';
      this.isSearching = false;
      this.searchFor = '';
    },
    search() {
      if (
        this.searchPhrase &&
        this.searchPhrase.length >= this.minSearchStringLength
      ) {
        if (this.searchPhrase.toLowerCase() !== this.searchFor) {
          this.projectsResultsLoading = true;
          this.projectsTagsResultsLoading = true;
          this.newsResultsLoading = true;
          this.newsTagsResultsLoading = true;
          this.isSearching = true;
          this.searchFor = this.searchPhrase.toLowerCase();
        }
      }
    },
  },
};
</script>
<style lang="scss">
button {
  &.v-icon {
    font-size: 14px !important;
  }
}
.v-btn {
  border-width: 2px !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
