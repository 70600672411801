import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as helper from '../js/helper.js';

//noinspection JSAnnotator
export default {
  async mounted() {
    if (this.projectCategories.length === 0 || this.projectTags.length === 0) {
      await this.loadProjectCategories();
      await this.loadProjectTags();
      this.updateProjectCategories(
        helper.addSelectedAndOptionAll(this.projectCategories)
      );
      this.updateProjectTags(helper.addSelectedAndOptionAll(this.projectTags));
    }
  },
  computed: {
    ...mapGetters({
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
    }),
    catsAndTagsLoaded() {
      return this.projectCategories.length > 0 && this.projectTags.length > 0;
    },
  },
  methods: {
    ...mapActions({
      loadProjectCategories: 'project/loadProjectCategories',
      loadProjectTags: 'project/loadProjectTags',
    }),
    ...mapMutations({
      updateProjectCategories: 'project/updateProjectCategories',
      updateProjectTags: 'project/updateProjectTags',
    }),
  },
};
