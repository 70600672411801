export default {
  loading: (state) => state.loading,
  getProject: (state) => (params) => {
    if (params.id) {
      return state.allProjects.find((project) => project.id === params.id);
    } else if (params.slug) {
      return state.allProjects.find((project) => project.slug === params.slug);
    } else {
      return {};
    }
  },
  getArrayOfThreeProjects: (state) => (index) => {
    return state.filteredProjects.slice(index, index + 3);
  },
  getFilteredProjects: (state) => {
    return state.filteredProjects.slice(0, state.projectsVisible);
  },
  allProjects: (state) => state.allProjects,
  projectTags: (state) => state.projectTags,
  projectCategories: (state) => state.projectCategories,
  filteredProjects: (state) => state.filteredProjects,
  showProjectFilters: (state) => state.showProjectFilters,
  searchResultsProjects: (state) => state.searchResultsProjects,
  searchResultsProjectsTags: (state) => state.searchResultsProjectsTags,
  showingProjectResults: (state) => state.showingProjectResults,
  selectedCategories: (state) =>
    state.projectCategories.filter((item) => item.selected),
  featuredProjects: (state) => state.featuredProjects,
  featuredProjectsLoaded: (state) => state.featuredProjectsLoaded,
};
