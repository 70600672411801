<template>
  <v-sheet>
    <label class="text-body-1" :for="id">
      {{ label }}
    </label>
    <v-textarea
      :id="id"
      v-model="currentValue"
      :placeholder="placeholder"
      outlined
      :rules="required ? rules : []"
      @keyup="onUpdate"
    ></v-textarea>
  </v-sheet>
</template>

<script>
import IncoordFormcontrolMixin from '@/components/core/IncoordFormcontrolMixin';

export default {
  name: 'IncoordTextarea',
  mixins: [IncoordFormcontrolMixin],
};
</script>

<style scoped></style>
