<template>
  <div class="contact pb-10">
    <autoadjusting-row>
      <v-col cols="12" class="mb-4 mt-lg-7 mb-lg-5">
        <div class="text-center contact-headline">
          <h2 class="text-h2">{{ $t('global.contact.teaserTitle') }}</h2>
        </div>
      </v-col>
      <v-col cols="12">
        <v-row class="mb-lg-15 pb-lg-15">
          <v-col cols="12" sm="6" md="8">
            <v-card flat class="pa-8 pa-xs-5 pa-lg-10 rounded">
              <v-card-title class="text-h2 pa-0 pb-4">
                {{ $t('global.contact.form.title') }}
              </v-card-title>
              <v-row>
                <v-col cols="12" lg="6">
                  <p class="pb-3 text-body-1">
                    {{ $t('global.contact.form.text') }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>

              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="contact-form"
                :disabled="disableForm"
              >
                <v-row>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-name">{{
                      $t('global.contact.form.name')
                    }}</label>
                    <v-text-field
                      id="form-name"
                      v-model="formData.name"
                      label=""
                      :placeholder="namePlaceholder"
                      dense
                      class="rounded-sm"
                      persistent-placeholder
                      outlined
                      :rules="generalRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-email">{{
                      $t('global.contact.form.email')
                    }}</label>
                    <v-text-field
                      id="form-email"
                      v-model="formData.email"
                      :placeholder="emailPlaceholder"
                      :rules="emailRules"
                      label=""
                      outlined
                      dense
                      required
                      class="rounded-sm"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-interest">{{
                      $t('global.contact.form.interest')
                    }}</label>
                    <v-select
                      id="form-interest"
                      v-model="formData.interest"
                      label=""
                      :placeholder="interestPlaceholder"
                      :items="interestCategories"
                      outlined
                      dense
                      class="rounded-sm"
                      :rules="generalRules"
                    ></v-select
                  ></v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-expand-transition mode="out-in">
                  <v-row v-show="showExpanded">
                    <v-col cols="12" class="py-0 mb-5">
                      <h4 class="font-weight-bold body-1 pb-4">
                        {{ $t('global.contact.form.contractorTitel') }}
                      </h4>
                      <p>
                        {{ $t('global.contact.form.contractorInformation') }}
                      </p>
                    </v-col>
                    <v-col
                      v-for="contractorProp in contractorFormProps"
                      cols="12"
                      :lg="contractorProp.size"
                      class="py-0 mb-5"
                      :key="contractorProp.attribute"
                    >
                      <component
                        :is="`Incoord${contractorProp.component}`"
                        :id="`contact-contractor-${contractorProp.attribute}`"
                        :label="contractorProp.label"
                        :placeholder="contractorProp.placeholder"
                        :model.sync="contractorData[contractorProp.attribute]"
                        :required="
                          isContractorContact && contractorProp.required
                        "
                        :rules="generalRules"
                        :options="contractorProp.options"
                      ></component>
                    </v-col>
                  </v-row>
                </v-expand-transition>
                <v-expand-transition mode="out-in">
                  <v-row v-show="!showExpanded">
                    <v-col md="12">
                      <incoord-textarea
                        id="form-message"
                        :label="$t('global.contact.form.message')"
                        :model.sync="formData.message"
                        :placeholder="messagePlaceholder"
                        :rules="generalRules"
                      />
                    </v-col>
                  </v-row>
                </v-expand-transition>
                <outlined-button
                  v-on:click-submit="submit"
                  :enable-value="valid"
                  darkContent
                  :loading="loading"
                  :disabled="loading"
                >
                  <template #buttonText>
                    {{ $t('global.contact.form.button') }}
                  </template>
                </outlined-button>
              </v-form>
            </v-card>
          </v-col>

          <v-col sm="6" md="4">
            <contact-card
              button
              :extraInfo="linkToCareer"
              :mailToLink="mailToLink"
            ></contact-card>
          </v-col>
        </v-row>
      </v-col>
    </autoadjusting-row>
    <user-message
      :showMe="showMessage"
      :messageText="userMessage"
      :error="error"
    ></user-message>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import ContactCard from '../partial/ContactCard.vue';
import UserMessage from './UserMessage.vue';
import IncoordInput from '@/components/core/IncoordInput';
import IncoordCheckboxGroup from '@/components/core/IncoordCheckboxGroup';
import IncoordTextarea from '@/components/core/IncoordTextarea';
import IncoordCheckbox from '@/components/core/IncoordCheckbox';
import IncoordDatePicker from '@/components/core/IncoordDatePicker';

//noinspection JSAnnotator
export default {
  components: {
    IncoordDatePicker,
    IncoordCheckbox,
    IncoordTextarea,
    IncoordCheckboxGroup,
    IncoordInput,
    OutlinedButton,
    AutoadjustingRow,
    ContactCard,
    UserMessage,
  },
  props: {
    linkToCareer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMessage: false,
      userMessage: '',
      error: false,
      interests: [
        { text: 'contactHire', value: 'hire' },
        { text: 'contactWorkModel', value: 'workmodel' },
        { text: 'contactContractor', value: 'contractor' },
        { text: 'contactOther', value: 'other' },
      ],
      valid: false,
      loading: false,
      formData: {
        name: '',
        email: '',
        interest: '',
        message: '',
      },
      contractorFormProps: [
        {
          size: '6',
          attribute: 'company',
          component: 'Input',
          placeholder: 'Ditt företag',
          label: 'Företag',
          required: true,
        },
        {
          size: '6',
          attribute: 'telephone',
          component: 'Input',
          placeholder: 'Ditt telefonnummer',
          label: 'Telefon',
          required: true,
        },
        {
          size: '12',
          attribute: 'context',
          component: 'CheckboxGroup',
          placeholder: '',
          label: 'Produkter/nisch',
          options: [
            {
              key: 'electricity',
              label: 'El',
            },
            {
              key: 'lighting',
              label: 'Belysning',
            },
            {
              key: 'pipes',
              label: 'Rör',
            },
            {
              key: 'control',
              label: 'Styr',
            },
            {
              key: 'tele',
              label: 'Tele',
            },
            {
              key: 'ventilation',
              label: 'Ventilation',
            },
            {
              key: 'sprinkler',
              label: 'Sprinkler',
            },
          ],
        },
        {
          size: '6',
          attribute: 'presentation',
          component: 'Textarea',
          placeholder:
            'Vilka produkter/tjänster/system etc. vill ni presentera?',
          label: 'Vad vill ni presentera?',
          required: true,
        },
        {
          size: '6',
          attribute: 'has_material',
          component: 'Checkbox',
          placeholder: 'Ja',
          label: 'Har ni EPDer för era produkter?',
        },
        {
          size: '6',
          attribute: 'extra_information',
          component: 'Textarea',
          placeholder:
            'Har ni något annat intressant som kan vara bra att veta?',
          label: 'Hållbarhets- och miljöperspektiv?',
          required: true,
        },
        {
          size: '6',
          label: 'Önskemål om datum för produktpresentation?',
          attribute: 'requested_date',
          component: 'DatePicker',
          placeholder:
            'Tillgängliga tider finns endast onsdagar mellan 12:00 och 13:00',
          required: true,
          options: [3],
        },
      ],
      contractorData: {
        company: '',
        telephone: '',
        context: [],
        presentation: '',
        requested_date: null,
        has_material: false,
        extraInformation: '',
      },
      namePlaceholder: this.$t('global.contact.form.namePlaceholder'),
      emailPlaceholder: this.$t('global.contact.form.emailPlaceholder'),
      emailRules: [
        (v) => !!v || this.$t('global.contact.form.required'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('global.contact.form.invalidEmail'),
      ],
      generalRules: [(v) => !!v || this.$t('global.contact.form.required')],
      messagePlaceholder: this.$t('global.contact.form.messagePlaceholder'),
      interestPlaceholder: this.$t('global.contact.form.interestPlaceholder'),
      showExpanded: false,
    };
  },
  watch: {
    selectedInterest(current) {
      this.showExpanded = current === 'contractor';
    },
  },
  computed: {
    mailToLink() {
      return (
        'mailto:' + this.$t('global.contact.emailAddress') + '?subject=Contact'
      );
    },
    disableForm() {
      return this.loading;
    },
    interestCategories() {
      return this.interests.map((interest) => {
        const text = this.$t(
          `global.contact.form.interestCategories.${interest.text}`
        );
        return { ...interest, text };
      });
    },
    selectedInterest() {
      return this.formData.interest;
    },
    isContractorContact() {
      return this.selectedInterest === 'contractor';
    },
  },
  methods: {
    ...mapActions({
      postContact: 'app/postContact',
    }),
    async submit() {
      this.showMessage = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let sendData = this.formData;
        if (this.isContractorContact) {
          sendData = Object.assign(sendData, this.contractorData);
        }
        const formData = new FormData();
        Object.keys(sendData).map((key) => {
          let value = sendData[key];
          if (key === 'has_material') {
            value = sendData[key] ? 1 : 0;
          }
          formData.append(key, value);
        });
        try {
          await this.postContact(formData);
          this.userMessage = this.$t('global.contact.form.success');
          this.error = false;
          this.$refs.form.reset();
          this.contractorData.context = [];
        } catch (error) {
          this.userMessage = this.$t('global.contact.form.error');
          this.error = true;
        }
        this.loading = false;
        this.showMessage = true;
      }
    },
    setContractorData(attribute, value) {
      this.contractorData[attribute] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  background-color: #fafaf8;
}
.contact-headline {
  padding-top: 90px;
}
.contact-form {
  padding-bottom: 40px;
}
a {
  text-decoration: none;
}
.v-text-field.v-input--dense {
  max-height: 42px;
}
</style>
