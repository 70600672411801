<template>
  <v-card v-bind="{ ...$attrs, ...commonAttrs }">
    <h3 v-if="$slots.overline" class="text-h3 pb-5" :class="getThemedTextClass">
      <slot name="overline"></slot>
    </h3>
    <h1 v-if="$slots.titleh1" class="text-h1 pb-5" :class="getThemedTextClass">
      <slot name="titleh1"></slot>
    </h1>
    <h2 v-if="$slots.titleh2" class="text-h2 pb-5" :class="getThemedTextClass">
      <slot name="titleh2"></slot>
    </h2>
    <h3 v-if="$slots.titleh3" class="text-h3 pb-5" :class="getThemedTextClass">
      <slot name="titleh3"></slot>
    </h3>
    <div v-if="$slots.content" class="text-body-1" :class="getThemedTextClass">
      <slot name="content"></slot>
    </div>
    <div v-if="$slots.contentWithoutFont" :class="getThemedTextClass">
      <slot name="contentWithoutFont"></slot>
    </div>
    <v-card-actions
      v-if="$slots.actions"
      class="pa-0"
      :class="centered ? 'justify-center' : ''"
    >
      <slot name="actions"></slot>
    </v-card-actions>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'TextComponent',
  props: {
    darkThemed: Boolean,
    centered: Boolean,
  },
  computed: {
    getThemedTextClass() {
      let classes = 'px-0';
      if (this.centered) {
        classes = classes.concat(' ', 'text-center');
      }
      return classes.concat(
        ' ',
        this.darkThemed ? 'black--text' : 'white--text'
      );
    },
    commonAttrs() {
      return {
        elevation: 0,
        class: 'transparent px-0',
      };
    },
  },
};
</script>
