var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('two-cols',{scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('text-component',{staticClass:"my-9 pb-0 pb-md-10 pb-lg-15",attrs:{"max-width":_vm.$vuetify.breakpoint.sm ? '329px' : '638px',"dark-themed":""},scopedSlots:_vm._u([{key:"titleh1",fn:function(){return [_vm._v(_vm._s(_vm.$t('about.subpages.business.title')))]},proxy:true},{key:"contentWithoutFont",fn:function(){return [_c('p',{staticClass:"text-body-1 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.text1'))+" ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('about.subpages.business.text2')))]),_c('p',{staticClass:"text-h2 mt-10 mb-5"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.subtitle'))+" ")]),_c('p',{staticClass:"text-body-1 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.text3'))+" ")]),_c('h3',{staticClass:"text-h3 pt-4 pb-1 d-inline-flex"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.titleVVS'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"grey darken-2","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('about.subpages.business.tooltipVVS')))])]),_c('table-component',{attrs:{"items":_vm.servicesVVS}}),_c('h3',{staticClass:"text-h3 pt-4 pb-1 d-inline-flex"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.titleEnergi'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"grey darken-2","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('about.subpages.business.tooltipEnergi')))])]),_c('table-component',{attrs:{"items":_vm.servicesEnergi}}),_c('h3',{staticClass:"text-h3 pt-4 pb-1 d-inline-flex"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.titleStyr'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"grey darken-2","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('about.subpages.business.tooltipStyr')))])]),_c('table-component',{attrs:{"items":_vm.servicesStyr}}),_c('h3',{staticClass:"text-h3 pt-4 pb-1 d-inline-flex"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.titleEl'))+" ")]),_c('v-tooltip',{attrs:{"bottom":"","max-width":_vm.$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"grey darken-2","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('about.subpages.business.tooltipEl')))])]),_c('table-component',{attrs:{"items":_vm.servicesEl}}),_c('p',{staticClass:"text-body-1 mt-10 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('about.subpages.business.text4'))+" ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('about.subpages.business.text5')))])]},proxy:true}])}),_c('v-img',{staticClass:"mb-15",attrs:{"max-width":"50%","src":require("@/assets/images/about/_desktop_mobile/auktoriserat_konsultforetag.jpg")}})]},proxy:true},{key:"second",fn:function(){return [_c('v-img',{staticClass:"rounded",attrs:{"src":require("@/assets/images/about/_desktop_mobile/business/verksamhet_toppbild.jpg")}})]},proxy:true}])}),_c('contact-us')],1)}
var staticRenderFns = []

export { render, staticRenderFns }