export default {
  loading: (state) => state.loading,
  dateFormat: (state) => state.dateFormat,
  timeFormat: (state) => state.timeFormat,
  timeFormatWithSeconds: (state) => state.timeFormatWithSeconds,
  dateTimeFormat: (state) => state.dateTimeFormat,
  dateTimeWithSecondsFormat: (state) => state.dateTimeWithSecondsFormat,
  routingKey: (state) => state.routingKey,
  subHeaderComponent: (state) => state.currentSubHeaderComponent,
  breadcrumbs: (state) => state.currentPageBreadCrumbs,
  includeFiltersInSearch: (state) => state.includeFiltersInSearch,
  showProjectFilters: (state) => state.showProjectFilters,
  showOverlay: (state) => state.showOverlay,
  locale: (state) => state.locale,
};
