<template>
  <v-img class="align-center" :src="imageSource" :height="getImageHeight">
    <autoadjusting-row :style="getRowStyle">
      <v-col cols="12" sm="7" align-self="center">
        <template v-for="(tag, index) in tags">
          <v-chip
            class="mr-1 rounded-sm white--text text-body-2"
            color="purple"
            style="max-height: 26px"
            :key="index"
            >{{ tag.title }}
          </v-chip></template
        >
        <div v-if="$slots.overline" class="text-h2 white--text">
          <slot name="overline"> </slot>
        </div>
        <div
          v-if="$slots.title"
          class="text-h1 white--text mt-3 mt-sm-2 mt-md-3"
        >
          <slot name="title"> </slot>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="5" align-self="center">
        <v-card
          :color="blackCard ? 'black' : 'white'"
          v-if="$slots.rightCardContent"
          :class="blackCard ? 'white--text' : 'black--text'"
          class="px-md-3 py-md-3"
        >
          <slot name="rightCardContent"></slot>
        </v-card>
      </v-col>
    </autoadjusting-row>
  </v-img>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
export default {
  components: { AutoadjustingRow },
  name: 'TopNewsAndProjects',
  props: {
    imageLarge: {
      type: String,
    },
    imageSmall: {
      type: String,
    },
    blackCard: {
      type: Boolean,
    },
    tags: {
      type: Array,
    },
  },
  computed: {
    imageSource() {
      if (this.$vuetify.breakpoint.xs) {
        return this.imageSmall;
      } else {
        return this.imageLarge;
      }
    },
    getImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 478;
        case 'sm':
          return 300;
        default:
          return 400;
      }
    },
    getRowStyle() {
      return 'height: ' + this.getImageHeight + 'px';
    },
  },
};
</script>
