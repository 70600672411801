<template>
  <v-container>
    <top-news-and-projects
      :image-large="require('@/assets/images/news/desktop/nyheter_hero_bg.jpg')"
      :image-small="require('@/assets/images/news/mobile/nyheter_hero_bg.jpg')"
      :blackCard="true"
    >
      <template #overline v-if="$vuetify.breakpoint.mdAndUp">{{
        $t('news.hero.overline')
      }}</template>
      <template #title>{{ $t('news.hero.title') }}</template>
      <template #rightCardContent>
        <v-card-text class="white--text">
          {{ $t('news.hero.card') }}
          <div>
            <outlined-button
              :darkContent="false"
              :href="$t('news.hero.cisionLink')"
            >
              <template #buttonText>
                {{ $t('news.hero.button') }}
              </template>
            </outlined-button>
          </div>
        </v-card-text>
      </template>
    </top-news-and-projects>

    <loading-indicator v-if="loading"></loading-indicator>
    <div class="pt-4 pt-sm-0" v-else>
      <list-with-teaser
        v-if="!newsFilterModelActive"
        :items="getFirstFourItems()"
      ></list-with-teaser>
      <list-component :items="getItems()">
        <template v-slot:default="slotProps">
          <small-news :item="slotProps.item"> </small-news>
        </template>
      </list-component>
    </div>
    <contact-us></contact-us>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContactUs from '../../components/complete/ContactUs.vue';
import ListComponent from '../../components/partial/ListComponent.vue';
import LoadingIndicator from '../../components/complete/LoadingIndicator.vue';
import ListWithTeaser from '../../components/news/ListWithTeaser.vue';
import TopNewsAndProjects from '../../components/complete/TopNewsAndProjects.vue';
import OutlinedButton from '../../components/partial/OutlinedButton.vue';
import loadNewsTagsMixin from '../../mixins/loadNewsTagsMixin.js';
import SmallNews from '../../components/news/SmallNews.vue';

//noinspection JSAnnotator
export default {
  name: 'NewsList',
  components: {
    ContactUs,
    ListComponent,
    LoadingIndicator,
    ListWithTeaser,
    TopNewsAndProjects,
    OutlinedButton,
    SmallNews,
  },
  mixins: [loadNewsTagsMixin],
  created() {
    this.updateCurrentSubHeaderComponent('filter-component');
  },
  computed: {
    ...mapGetters({
      allNews: 'news/allNews',
      newsFilterModel: 'news/newsFilterModel',
    }),
    loading() {
      return this.allNews.length === 0;
    },
    newsFilterModelActive() {
      return (
        this.newsFilterModel.tag !== '' || this.newsFilterModel.year !== ''
      );
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
    }),
    getNewsList(filterTag, filterYear) {
      if (!filterTag && !filterYear) {
        return this.allNews;
      } else {
        let filteredNews = this.allNews;
        if (filterTag) {
          filteredNews = this.allNews.filter((item) =>
            item.tags.some((tag) => tag.title === filterTag)
          );
        }
        if (filterYear) {
          filteredNews = filteredNews.filter((item) =>
            item.date.startsWith(filterYear)
          );
        }
        return filteredNews;
      }
    },

    getFirstFourItems() {
      return this.getNewsList().slice(0, 4);
    },
    getItems() {
      return this.newsFilterModelActive
        ? this.getNewsList(this.newsFilterModel.tag, this.newsFilterModel.year)
        : this.getNewsList().slice(4);
    },
  },
};
</script>

<style scoped lang="scss">
.v-chip.v-chip--outlined {
  border-width: 1px;
  border-color: #c4c4c4;
}
.v-chip.v-size--default {
  height: 26px;
}
</style>
