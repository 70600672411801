<template>
  <v-card class="rounded-0 elevation-0 px-0">
    <srcset-image
      :imageSmall="require('@/assets/images/start/mobile/hallbarhet_bg.jpg')"
      :imageLarge="require('@/assets/images/start/desktop/hallbarhet_bg.jpg')"
      :max-height="getImageHeight"
    >
      <autoadjusting-row class="py-16">
        <v-col cols="12" sm="6" md="5">
          <text-component
            max-width="523"
            :centered="false"
            :dark-themed="false"
            class="pt-9 pb-16"
          >
            <template #content>
              <div class="text-h1 mb-5">
                {{ $t('sustainability.teaser.title') }}
              </div>
              <div class="text-h2" style="display: inline">
                {{ $t('sustainability.teaser.subtitle1') }}
              </div>
              <span class="text-h2 green--text">{{
                $t('sustainability.teaser.subtitle2')
              }}</span
              >.
              <div class="text-h2 mb-4" style="display: inline">
                {{ $t('sustainability.teaser.subtitle3') }}
              </div>
              <p class="mt-4">{{ $t('sustainability.teaser.text') }}</p>

              <outlined-button
                :to="{ name: 'Sustainability' }"
                :darkContent="false"
              >
                <template #buttonText>
                  {{ $t('sustainability.teaser.button') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </srcset-image>
  </v-card>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import SrcsetImage from '../partial/SrcsetImage.vue';
import TextComponent from '../partial/TextComponent.vue';
export default {
  components: { AutoadjustingRow, TextComponent, OutlinedButton, SrcsetImage },
  name: 'MissionPossible',
  computed: {
    getImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 720;
        case 'sm':
          return 653;
        default:
          return 720;
      }
    },
  },
};
</script>
