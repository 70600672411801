<template>
  <v-card class="pa-10 pa-sm-5 sustainability-page">
    <v-row>
      <v-col cols="6" sm="2" md="4">
        <srcset-image :imageLarge="imageLarge" :imageSmall="imageSmall" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="8">
        <text-component darkThemed>
          <template #content>
            <slot name="cardContent"></slot>
            <outlined-button :to="buttonTo" darkContent>
              <template #buttonText>
                {{ buttonText }}
              </template>
            </outlined-button>
          </template>
        </text-component>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import OutlinedButton from '../partial/OutlinedButton.vue';
import SrcsetImage from '../partial/SrcsetImage.vue';
import TextComponent from '../partial/TextComponent.vue';
export default {
  components: { TextComponent, OutlinedButton, SrcsetImage },
  name: 'SustainabilityCard',
  props: {
    imageLarge: {
      type: String,
      default: '',
    },
    imageSmall: {
      type: String,
      default: '',
    },
    buttonTo: Object,
    buttonText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.sustainability-page {
  background-color: var(--v-bgsustainability-base);
}
</style>
