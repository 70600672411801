<template>
  <div>
    <top-component
      wider
      :image-source="
        require('@/assets/images/sustainability/desktop/hallbarhet_hero_bg.jpg')
      "
      :image-srcset="
        require('@/assets/images/sustainability/mobile/hallbarhet_hero_bg.jpg')
      "
      scrollTo="#kraft"
    >
      <template #overline>{{ $t('sustainability.hero.overline') }}</template>
      <template #titleh1>{{ $t('sustainability.hero.title') }}</template>
      /></top-component
    >

    <two-cols id="kraft" class="py-16 py-sm-11 py-lg-15 my-sm-11 my-lg-15">
      <template #first>
        <text-component
          dark-themed
          position="left"
          :max-width="$vuetify.breakpoint.sm ? 329 : 632"
        >
          <template #titleh2>{{ $t('sustainability.force.title') }}</template>
        </text-component>
      </template>
      <template #second>
        <text-component
          dark-themed
          position="right"
          :max-width="$vuetify.breakpoint.sm ? 329 : 632"
        >
          <template #content
            ><div class="mb-4">{{ $t('sustainability.force.text1') }}</div>
            <div>{{ $t('sustainability.force.text2') }}</div>
          </template>
        </text-component>
      </template>
    </two-cols>

    <v-card id="hur" class="rounded-0 elevation-0 px-0">
      <srcset-image
        :imageLarge="
          require('@/assets/images/sustainability/desktop/sa_jobbar_vi_med_hallbarhet.jpg')
        "
        :imageSmall="
          require('@/assets/images/sustainability/mobile/sa_jobbar_vi_med_hallbarhet.jpg')
        "
      >
        <autoadjusting-row>
          <v-spacer></v-spacer>
          <v-col cols="10" md="6">
            <div
              class="mb-10 white--text text-center pt-15 mt-15 pt-sm-10 mt-sm-10 pt-md-15 mt-md-15"
              :class="$vuetify.breakpoint.xs ? 'text-h2' : 'text-h1'"
            >
              {{ $t('sustainability.how.title') }}
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </autoadjusting-row>
        <autoadjusting-row v-if="$vuetify.breakpoint.mdAndUp" class="pb-16">
          <v-spacer></v-spacer>
          <v-col cols="12" sm="10" md="6">
            <sustainability-card
              :imageLarge="
                require('@/assets/images/sustainability/desktop/hallbarhet_inom_projekt@2x.png')
              "
              :buttonTo="{ name: 'SustainabilityProjects' }"
              :buttonText="$t('sustainability.how.left.button')"
            >
              <template #cardContent>
                <h2 class="text-h2" style="display: inline">
                  {{ $t('sustainability.how.left.title') }}
                </h2>
                <p class="text-body-1 mt-4">
                  {{ $t('sustainability.how.left.text') }}
                </p>
              </template>
            </sustainability-card>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="10" md="6">
            <sustainability-card
              :imageLarge="
                require('@/assets/images/sustainability/desktop/hallbarhet_inom_incoord@2x.png')
              "
              :buttonTo="{ name: 'SustainabilityIncoord' }"
              :buttonText="$t('sustainability.how.right.button')"
            >
              <template #cardContent>
                <h2 class="text-h2" style="display: inline">
                  {{ $t('sustainability.how.right.title') }}
                </h2>
                <p class="mt-4 text-body-1">
                  {{ $t('sustainability.how.right.text') }}
                </p>
              </template>
            </sustainability-card>
          </v-col>
          <v-spacer></v-spacer>
        </autoadjusting-row>
        <div v-else>
          <autoadjusting-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="8">
              <sustainability-card
                :imageLarge="
                  require('@/assets/images/sustainability/desktop/hallbarhet_inom_projekt@2x.png')
                "
                :imageSmall="
                  require('@/assets/images/sustainability/mobile/hallbarhet_inom_projekt.png')
                "
                :buttonTo="{ name: 'SustainabilityProjects' }"
                :buttonText="$t('sustainability.how.left.button')"
              >
                <template #cardContent>
                  <h2 class="text-h2" style="display: inline">
                    {{ $t('sustainability.how.left.title') }}
                  </h2>
                  <p class="mt-4 text-body-1">
                    {{ $t('sustainability.how.left.text') }}
                  </p>
                </template>
              </sustainability-card>
            </v-col>
            <v-spacer></v-spacer>
          </autoadjusting-row>
          <autoadjusting-row class="pt-8 pt-sm-1 pb-16">
            <v-spacer></v-spacer>
            <v-col cols="12" sm="8">
              <sustainability-card
                :imageLarge="
                  require('@/assets/images/sustainability/desktop/hallbarhet_inom_incoord@2x.png')
                "
                :imageSmall="
                  require('@/assets/images/sustainability/mobile/hallbarhet_inom_incoord.png')
                "
                :buttonTo="{ name: 'SustainabilityIncoord' }"
                :buttonText="$t('sustainability.how.right.button')"
              >
                <template #cardContent>
                  <h2 class="text-h2" style="display: inline">
                    {{ $t('sustainability.how.right.title') }}
                  </h2>
                  <p class="mt-4 text-body-1">
                    {{ $t('sustainability.how.right.text') }}
                  </p>
                </template>
              </sustainability-card>
            </v-col>
            <v-spacer></v-spacer>
          </autoadjusting-row>
        </div>
      </srcset-image>
    </v-card>

    <div class="sustainability-page">
      <two-cols class="py-16 py-sm-11 py-lg-15">
        <template #first>
          <text-component
            dark-themed
            position="left"
            :max-width="$vuetify.breakpoint.sm ? 329 : 632"
          >
            <template #titleh2>{{ $t('sustainability.focus.title') }}</template>
          </text-component>
        </template>
        <template #second>
          <text-component
            dark-themed
            position="right"
            :max-width="$vuetify.breakpoint.sm ? 329 : 632"
          >
            <template #content
              ><div>{{ $t('sustainability.focus.text') }}</div>
            </template>
          </text-component>
        </template>
      </two-cols>

      <no-margin-image
        id="resultat"
        :imageSource="
          require('@/assets/images/sustainability/_desktop_mobile/globala_malen_dm.png')
        "
        :titleh2="$t('sustainability.susByIncoord.title')"
      >
        <template #content>
          <div :class="noMarginImageText">
            {{ $t('sustainability.susByIncoord.text1') }}
          </div>
          <div :class="noMarginImageText">
            {{ $t('sustainability.susByIncoord.text2') }}
          </div>
          <div class="text-body-1">
            <a :href="getPathToSustainabilityFile" download>
              {{ $t('footer.download.sustainability') }}
            </a>
            <span> {{ $t('footer.download.fileending') }}</span>
          </div>
        </template>
        ></no-margin-image
      >

      <no-margin-image
        id="unik"
        imageOnLeft
        :imageSource="
          require('@/assets/images/sustainability/_desktop_mobile/hallbarhetsredovisning.jpg')
        "
        :titleh2="$t('sustainability.goalAndResults.title')"
      >
        <template #content>
          <div :class="noMarginImageText">
            {{ $t('sustainability.goalAndResults.text1') }}
          </div>
          <div :class="noMarginImageText">
            {{ $t('sustainability.goalAndResults.text2') }}
          </div>
          <div :class="noMarginImageText">
            {{ $t('sustainability.goalAndResults.text3') }}
          </div>
          <div class="text-body-1">
            <a :href="getPathToAccountingFile" download>
              {{ $t('footer.download.accounting') }}
            </a>
            <span> {{ $t('footer.download.fileending') }}</span>
          </div>
        </template>
      </no-margin-image>
    </div>

    <news-teaser subText>
      <template #newsTitle>
        <h3 class="text-h3 text-center white--text mb-4">
          {{ $t('news.teaser.overline') }}
          <br class="d-sm-none d-md-flex" />
        </h3>
        <h1 class="text-h1 text-center white--text">
          {{ $t('news.teaser.title') }}
          <br class="d-sm-none d-md-flex" />
        </h1>
      </template>
    </news-teaser>

    <contact-us></contact-us>
  </div>
</template>

<script>
import NoMarginImage from '../components/complete/NoMarginImage.vue';
import TopComponent from '../components/complete/TopComponent.vue';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TwoCols from '../components/partial/TwoCols.vue';
import SustainabilityCard from '../components/sustainability/SustainabilityCard.vue';
import { mapActions, mapState } from 'vuex';
import getPdfFromBackendMixin from '../mixins/getBackendPathToPdf';
import SrcsetImage from '../components/partial/SrcsetImage.vue';
import NewsTeaser from '../components/complete/ThreeTeasersNews.vue';
import ContactUs from '../components/complete/ContactUs.vue';

//noinspection JSAnnotator
export default {
  components: {
    TopComponent,
    TwoCols,
    TextComponent,
    AutoadjustingRow,
    NoMarginImage,
    SustainabilityCard,
    SrcsetImage,
    NewsTeaser,
    ContactUs,
  },
  mixins: [getPdfFromBackendMixin],
  name: 'Sustainability',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  computed: {
    ...mapState(['idOfNewsSustainabilityTag']),
    noMarginImageText() {
      return 'mb-4 text-body-1';
    },
    breadcrumbItems() {
      return [
        {
          text: this.$t('sustainability.breadcrumb'),
          name: 'Sustainability',
          disabled: true,
        },
        {
          text: this.$t('sustainability.how.breadcrumb'),
          name: '#hur',
          disabled: false,
        },
        {
          text: this.$t('sustainability.goalAndResults.breadcrumb'),
          name: '#resultat',
          disabled: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
    }),
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.sustainability-page {
  background-color: var(--v-bgsustainability-base);
  padding-bottom: 80px;
}
</style>
