<template>
  <v-menu tile min-width="100%" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn plain :ripple="false" v-bind="attrs" v-on="on" @click="toggleOverlay" v-click-outside="resetOverlay"><v-icon
          size="18">$navigationIcon</v-icon></v-btn>
    </template>
    <v-sheet class="mt-1">
      <autoadjusting-row no-gutters>
        <v-col>

          <v-list class="pt-3">
            <template v-for="(item, index) in menuItems">
              <v-list-item :key="item.title" active-class="menu-item--active" color="white" :to="{ name: item.routeName }"
                @click="updateRoutingKeyAndCloseOverlay" class="px-0">
                <v-list-item-title><span>{{ item.title }}</span></v-list-item-title>
              </v-list-item>
              <v-divider v-if="index != menuItems.length - 1" :key="index"></v-divider>
            </template>
          </v-list>
        </v-col>
      </autoadjusting-row>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';

export default {
  components: { AutoadjustingRow },
  name: 'NavigationHamburger',
  computed: {
    ...mapState(['showOverlay']),
    menuItems() {
      return this.$store.getters.topMenuItems;
    },
    fortyClass() {
      return this.$route.name === "40" ? "nav-forty-gradient" : "nav-forty";
    },
    lightThemed() {
      return this.$route.name === "40";
    },
    fortyImage() {
      return this.$route.name === "40"
        ? require("@/assets/images/forty/_desktop_mobile/40_white.svg")
        : require("@/assets/images/forty/_desktop_mobile/40_gradient.svg");
    },
  },
  methods: {
    ...mapMutations(['setRoutingKey', 'setShowOverlay']),
    updateRoutingKeyAndCloseOverlay() {
      this.setRoutingKey();
      this.toggleOverlay();
    },
    toggleOverlay() {
      this.setShowOverlay(!this.showOverlay);
      if (this.showOverlay) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.style.overflow = 'auto';
      }
    },
    resetOverlay() {
      document.documentElement.style.overflow = 'auto';
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: none;
}

.menu-item--active>.v-list-item__title>span {
  padding-bottom: 2px;
  border-bottom: 2px solid #34c759 !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}

.v-list-item__title {
  overflow: visible;
}


.nav-forty {
  border-radius: 6px !important;
  color: black;
  font: normal normal bold 16px/24px Open Sans;
  margin-left: -16px;
}

.nav-forty-gradient {
  background: transparent linear-gradient(90deg, #813A89 0%, #813A89 33%, #CF605F 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  color: white;
  font: normal normal bold 16px/24px Open Sans;
}
</style>
