import { mapActions, mapGetters } from 'vuex';

//noinspection JSAnnotator
export default {
  created() {
    if (this.projectTeaserItems.length === 0) {
      this.getProjectTeaserItems(this.idOfProjectSustainabilityTag);
    }
  },
  computed: {
    ...mapGetters({
      idOfProjectSustainabilityTag: 'project/idOfProjectSustainabilityTag',
      projectTeaserItems: 'project/projectTeaserItems',
    }),
  },
  methods: {
    ...mapActions({ getProjectTeaserItems: 'project/getProjectTeaserItems' }),
  },
};
