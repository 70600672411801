<template>
  <div>
    <div class="three-quarter-height d-flex">
      <top-q-r dark-themed wider :image-source="require('@/assets/images/qr/_desktop_mobile/compressed/ljusare-framtid.jpg')
        " :image-srcset="require('@/assets/images/qr/_desktop_mobile/compressed/ljusare-framtid.jpg')
    " scrollTo="#om">
        <template #headline>{{ $t('qr.sustainability.text') }}</template>
        /></top-q-r>
    </div>

    <autoadjusting-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12">
        <v-card flat class="text-left mt-4">
          <p class="opensans-regular-14 black--text">
            {{ $t('qr.hero.alt_text') }}
          </p>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </autoadjusting-row>

    <autoadjusting-row>
      <template>
        <v-container class="text-center">
          <v-row justify="center">
            <v-col cols="6" xs="6" md="4">
              <button @click="scrollToElement" data-target="hallbara"
                class="qr-blue--background opensans-bold-12 white--text qr-page--button px-4">{{
                  $t('qr.buttons.hallbara') }}
              </button>

            </v-col>
            <v-col cols="6" xs="6" md="4">
              <button @click="scrollToElement" data-target="samarbeten"
                class="qr-blue--background opensans-bold-12 white--text qr-page--button px-4">{{
                  $t('qr.buttons.hallbarhet') }}
              </button>
            </v-col>
            <v-col cols="6" xs="6" md="4">
              <button @click="scrollToElement" data-target="specialister"
                class="qr-blue--background opensans-bold-12 white--text qr-page--button px-4">{{
                  $t('qr.buttons.sustainability') }}
              </button>
            </v-col>
            <v-col cols="6" xs="6" md="4">
              <button @click="scrollToElement" data-target="kompetenser"
                class="qr-blue--background opensans-bold-12 white--text qr-page--button px-4">{{
                  $t('qr.buttons.kompetenser') }}
              </button>
            </v-col>
            <v-col cols="8" xs="8" md="4">
              <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
                <button class="opensans-bold-12 qr-blue--text qr-page--button px-4">{{
                  $t('qr.buttons.samarbeta') }}
                </button>
              </a>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </autoadjusting-row>

    <div class="my-16" id="hallbara">
      <v-divider></v-divider>
      <autoadjusting-row>
        <v-col cols="12" sm="8" class="mt-16">
          <v-card flat class="text-left">
            <div class="qr-blue--text qr-section-title opsensans-bold-14 mb-8">{{ $t('qr.hallbara.title') }}</div>
            <div class="qr-section-header gotham-bold-40 normal-line-height mb-4">{{ $t('qr.hallbara.header') }}</div>
            <div class="qr-section-subtext opensans-regular-16">{{ $t('qr.hallbara.subtext') }}</div>
          </v-card>
        </v-col>
      </autoadjusting-row>
      <four-cols>
        <template #first>
          <text-component id="thirds" class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/sara-kulturhus.jpg"
                aspect-ratio="1" /></template>
            <h2 class="opensans-regular-14">{{ $t('qr.hallbara.sara.namn') }}</h2>
            <h2 class="opensans-bold-20 normal-line-height">{{ $t('qr.hallbara.sara.title') }}</h2>
            <a @click="toggleText('saraText')" data-target="saraText"
              class="cyan--text cyan-lighten-1 text-decoration-underline">
              {{ $t('qr.readMore') }}
            </a>
            <v-card-text v-show="textsVisibility.saraText" class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.hallbara.sara.text') }}
              </p>
            </v-card-text>
            <v-card-text class="mt-4">
              <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
                <button class="opensans-bold-14 qr-blue--background qr-page--button white--text px-4">{{
                  $t('qr.contactUs') }}
                </button>
              </a>
            </v-card-text>
          </text-component>
        </template>
        <template #second>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/backakra.jpg"
                aspect-ratio="1" /></template>
            <h2 class="opensans-regular-14">{{ $t('qr.hallbara.backakra.namn') }}</h2>
            <h2 class="opensans-bold-20 normal-line-height">{{ $t('qr.hallbara.backakra.title') }}</h2>
            <a @click="toggleText('backakraText')" data-target="backakraText"
              class="cyan--text cyan-lighten-1 text-decoration-underline">
              {{ $t('qr.readMore') }}
            </a>
            <v-card-text v-show="textsVisibility.backakraText" class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.hallbara.backakra.text') }}
              </p>
            </v-card-text>
            <v-card-text class="mt-4">
              <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
                <button class="opensans-bold-14 qr-blue--background qr-page--button white--text px-4">{{
                  $t('qr.contactUs') }}
                </button>
              </a>
            </v-card-text>
          </text-component>
        </template>
        <template #third>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/intervallet.jpg"
                aspect-ratio="1" /></template>
            <h2 class="opensans-regular-14">{{ $t('qr.hallbara.intervallet.namn') }}</h2>
            <h2 class="opensans-bold-20 normal-line-height">{{ $t('qr.hallbara.intervallet.title') }}</h2>
            <a @click="toggleText('intervalletText')" data-target="intervalletText"
              class="cyan--text cyan-lighten-1 text-decoration-underline">
              {{ $t('qr.readMore') }}
            </a>
            <v-card-text v-show="textsVisibility.intervalletText" class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.hallbara.intervallet.text') }}
              </p>
            </v-card-text>
            <v-card-text class="mt-4">
              <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
                <button class="opensans-bold-14 qr-blue--background qr-page--button white--text px-4">{{
                  $t('qr.contactUs') }}
                </button>
              </a>
            </v-card-text>
          </text-component>
        </template>
        <template #fourth>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/duvslaget.jpg"
                aspect-ratio="1" /></template>
            <h2 class="opensans-regular-14">{{ $t('qr.hallbara.duvslaget.namn') }}</h2>
            <h2 class="opensans-bold-20 normal-line-height">{{ $t('qr.hallbara.duvslaget.title') }}</h2>
            <a @click="toggleText('duvslagetText')" data-target="duvslagetText"
              class="cyan--text cyan-lighten-1 text-decoration-underline">
              {{ $t('qr.readMore') }}
            </a>
            <v-card-text v-show="textsVisibility.duvslagetText" class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height normal-line-heightpb-0 mb-0">
                {{ $t('qr.hallbara.duvslaget.text') }}
              </p>
            </v-card-text>
            <v-card-text class="mt-4">
              <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
                <button class="opensans-bold-14 qr-blue--background qr-page--button white--text px-4">{{
                  $t('qr.contactUs') }}
                </button>
              </a>
            </v-card-text>
          </text-component>
        </template>
      </four-cols>
    </div>


    <div class="my-16" id="samarbeten">
      <v-divider></v-divider>
      <autoadjusting-row>
        <v-col cols="12" sm="8" class="mt-16">
          <v-card flat class="text-left">
            <div class="qr-blue--text qr-section-title opsensans-bold-14 mb-8">{{ $t('qr.samarbeten.title') }}</div>
            <div class="qr-section-header gotham-bold-40 normal-line-height mb-4">{{ $t('qr.samarbeten.header') }}</div>
            <div class="qr-section-subtext opensans-regular-16">{{ $t('qr.samarbeten.subtext') }}</div>
          </v-card>
        </v-col>
      </autoadjusting-row>
      <three-cols>
        <template #first>
          <text-component id="thirds" class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/open-bim.jpg"
                aspect-ratio="1" /></template>
            <h2 class="gotham-bold-20 pt-8 pb-6">{{ $t('qr.samarbeten.bim.title') }}</h2>
            <v-card-text class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.samarbeten.bim.text') }}
              </p>
            </v-card-text>
          </text-component>
        </template>
        <template #second>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/innovationsforetagen.jpg"
                aspect-ratio="1" /></template>
            <h2 class="gotham-bold-20 pt-8 pb-6">{{ $t('qr.samarbeten.innovation.title') }}</h2>
            <v-card-text class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.samarbeten.innovation.text') }}
              </p>
            </v-card-text>
          </text-component>
        </template>
        <template #third>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/ai-forskning.jpg"
                aspect-ratio="1" /></template>
            <h2 class="gotham-bold-20 pt-8 pb-6">{{ $t('qr.samarbeten.forskning.title') }}</h2>
            <v-card-text class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.samarbeten.forskning.text') }}
              </p>
            </v-card-text>
          </text-component>
        </template>
      </three-cols>
      <autoadjusting-row>
        <v-col cols="12" xs="8" sm="12" class="mt-8">
          <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
            <button class="opensans-bold-14 qr-blue--background qr-page--button white--text px-4">{{
              $t('qr.contactUs') }}
            </button>
          </a>
        </v-col>
      </autoadjusting-row>
    </div>

    <div class="my-16" id="specialister">
      <v-divider></v-divider>
      <autoadjusting-row>
        <v-col cols="12" sm="8" class="mt-16">
          <v-card flat class="text-left">
            <div class="qr-blue--text qr-section-title opsensans-bold-14 mb-8">{{ $t('qr.specialister.title') }}</div>
            <div class="qr-section-header gotham-bold-40 normal-line-height mb-4">{{ $t('qr.specialister.header') }}</div>
            <div class="qr-section-subtext opensans-regular-16">{{ $t('qr.specialister.subtext') }}</div>
          </v-card>
        </v-col>
      </autoadjusting-row>
      <three-cols>
        <template #first>
          <text-component id="thirds" class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/specialister-klimat.jpg"
                aspect-ratio="1" /></template>
            <h2 class="gotham-bold-20 pt-8 pb-6">{{ $t('qr.specialister.klimat.title') }}</h2>
            <v-card-text class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.specialister.klimat.text') }}
              </p>
            </v-card-text>
          </text-component>
        </template>
        <template #second>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/specialister-socialt.jpg"
                aspect-ratio="1" /></template>
            <h2 class="gotham-bold-20 pt-8 pb-6">{{ $t('qr.specialister.socialt.title') }}</h2>
            <v-card-text class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.specialister.socialt.text') }}
              </p>
            </v-card-text>
          </text-component>
        </template>
        <template #third>
          <text-component class="pt-10 pt-sm-0 pt-md-10" dark-themed position="right">
            <template #titleh1><v-img src="@/assets/images/qr/_desktop_mobile/compressed/specialister-ekonomiskt.jpg"
                aspect-ratio="1" /></template>
            <h2 class="gotham-bold-20 pt-8 pb-6">{{ $t('qr.specialister.ekonomiskt.title') }}</h2>
            <v-card-text class="px-0 py-0">
              <p class="text-body-1 black--text opensans-light-14 normal-line-height pb-0 mb-0">
                {{ $t('qr.specialister.ekonomiskt.text') }}
              </p>
            </v-card-text>
          </text-component>
        </template>
      </three-cols>
      <autoadjusting-row>
        <v-col cols="12" xs="8" sm="12" class="mt-8">
          <a href="mailto:enljusareframtid@incoord.se?subject=En ljusare framtid tillsammans med Incoord">
            <button class="opensans-bold-14 qr-blue--background qr-page--button white--text px-4">{{
              $t('qr.contactUs') }}
            </button>
          </a>
        </v-col>
      </autoadjusting-row>
    </div>

    <div class="my-16" id="kompetenser">
      <v-divider></v-divider>
      <two-cols>
        <template #first>
          <v-col cols="12" sm="12" class="mt-16">
            <v-card flat class="text-left">
              <div class="qr-blue--text qr-section-title opsensans-bold-14 mb-8">{{ $t('qr.kompetenser.title') }}</div>
              <div class="qr-section-header gotham-bold-40 normal-line-height mb-4 text-uppercase">{{
                $t('qr.kompetenser.header') }}
              </div>
              <div class="qr-section-subtext opensans-regular-16">{{ $t('qr.kompetenser.subtext') }}</div>
            </v-card>

            <div class="mt-8 opensans-bold-20 text--uppercase">{{ $t('qr.kompetenser.kompetenser.title') }}</div>
            <div class="mt-4 opensans-regular-14">{{ $t('qr.kompetenser.kompetenser.text') }}</div>
            <h3 class="text-h3 pt-8 pb-2">
              {{ $t('qr.kompetenser.kompetenser.vvs.title') }}
            </h3>
            <div class="list-container">
              <div class="list-item opensans-regular-14"
                v-for="(item, index) in $t('qr.kompetenser.kompetenser.vvs.list')" :key="index">
                {{ item }}
              </div>
            </div>
            <h3 class="text-h3 pt-8 pb-2">
              {{ $t('qr.kompetenser.kompetenser.hallbart.title') }}
            </h3>
            <div class="list-container">
              <div class="list-item opensans-regular-14"
                v-for="(item, index) in $t('qr.kompetenser.kompetenser.hallbart.list')" :key="index">
                {{ item }}
              </div>
            </div>
            <h3 class="text-h3 pt-8 pb-2">
              {{ $t('qr.kompetenser.kompetenser.styr.title') }}
            </h3>
            <div class="list-container">
              <div class="list-item opensans-regular-14"
                v-for="(item, index) in $t('qr.kompetenser.kompetenser.styr.list')" :key="index">
                {{ item }}
              </div>
            </div>
            <h3 class="text-h3 pt-8 pb-2">
              {{ $t('qr.kompetenser.kompetenser.el.title') }}
            </h3>
            <div class="list-container">
              <div class="list-item opensans-regular-14" v-for="(item, index) in $t('qr.kompetenser.kompetenser.el.list')"
                :key="index">
                {{ item }}
              </div>
            </div>
            <div class="py-12 opensans-regular-14">{{ $t('qr.kompetenser.footer') }}</div>
          </v-col>
        </template>
        <template #second>
          <v-img class="rounded mt-16" src="@/assets/images/about/_desktop_mobile/verksamhet/verksamhet_toppbild.jpg">
          </v-img>
        </template>
      </two-cols>
    </div>
  </div>
</template>


<script>
import TopQR from '../components/complete/TopQR.vue';
import setSubHeaderToNullMixin from '../mixins/setSubHeaderToNullMixin.js';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import ThreeCols from '../components/partial/ThreeCols.vue';
import FourCols from '../components/partial/FourCols.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TwoCols from '../components/partial/TwoCols.vue';
import { mapActions } from 'vuex';

//noinspection JSAnnotator
export default {
  name: 'QRcampaign',
  components: {
    TopQR,
    AutoadjustingRow,
    ThreeCols,
    FourCols,
    TextComponent,
    TwoCols,

  },
  mixins: [setSubHeaderToNullMixin],
  data() {
    return {
      showMessage: false,
      userMessage: '',
      error: false,
      valid: false,
      loading: false,
      emailPlaceholder: this.$t('forty.newsletter.placeholder'),
      emailRules: [
        (v) => !!v || this.$t('global.contact.form.required'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('global.contact.form.invalidEmail'),
      ],
      formData: {
        email: '',
      },
      textsVisibility: {
        saraText: false,
        backakraText: false,
        intervalletText: false,
        duvslagetText: false,
      },
    };
  },
  computed: {
    breadcrumbItems() {
      return [];
    },
    disableForm() {
      return this.loading;
    },
  },
  methods: {
    ...mapActions({
      updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
      updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
      postNewsletter: 'app/postNewsletter'
    }),
    async submit() {
      console.log('Submitting');
      this.showMessage = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let sendData = this.formData;
        const formData = new FormData();
        Object.keys(sendData).map((key) => {
          let value = sendData[key];
          formData.append(key, value);
        });
        try {
          await this.postNewsletter(formData);
          this.userMessage = this.$t('global.contact.form.success');
          this.error = false;
          this.$refs.form.reset();
        } catch (error) {
          this.userMessage = this.$t('global.contact.form.error');
          this.error = true;
        }
        this.loading = false;
        this.showMessage = true;
      }
    },
    navigate(item) {
      if (item.startsWith('#')) {
        this.$vuetify.goTo(item, { duration: 400 });
      } else {
        this.$router.push({
          name: item,
        });
      }
    },
    scrollToElement(event) {
      // Get the target ID from the clicked button's data-target attribute
      const targetId = event.currentTarget.getAttribute('data-target');

      // Get the element by its ID
      let element = document.getElementById(targetId);

      if (element) {
        // Scroll to the element
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    toggleText(targetId) {
      this.textsVisibility[targetId] = !this.textsVisibility[targetId];
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Gotham-Bold';
  src: url('~@/assets/fonts/Gotham-Bold.ttf') format('truetype');
}

.gotham-bold-20 {
  font-family: 'Gotham-Bold', sans-serif !important;
  font-size: 16pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.normal-line-height {
  line-height: normal !important;
}

.more-line-height {
  line-height: 1.18 !important;
}

p {
  margin-bottom: 16px;
}

.card-subtitle {
  font-weight: bold;
  display: block;
}

.half-height {
  min-height: 50svh;
  position: relative;
}

.three-quarter-height {
  min-height: 50svh;
  position: relative;
}

.v-application {

  a,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
  }
}

.qr-blue--text {
  color: #505cb4 !important;
}

.qr-blue--background {
  background-color: #505cb4 !important;
}

.qr-page--button {
  height: 60px;
  min-width: 100%;
  word-wrap: normal;
  border: 2px solid;
  border-radius: 1em;
  border-color: #505cb4;
}

.gotham-bold-40 {
  font-family: 'Gotham-Bold', sans-serif !important;
  font-size: 34pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.opensans-regular-18 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14pt !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.opensans-regular-16 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12pt !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.opensans-regular-14 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 11pt !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.opensans-bold-20 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.opensans-bold-14 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.opensans-bold-12 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 10pt !important;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.opensans-light-14 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12pt !important;
  font-weight: lighter;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal !important;
}

.list-container {
  column-count: 3;
  column-gap: 20px;
}

@media screen and (max-width: 600px) {

  .three-quarter-height {
    min-height: 75svh;
    position: relative;
  }

  .list-container {
    column-count: 2;
    column-gap: 10px;
  }

  .gotham-bold-40 {
    font-size: 20pt !important;
    font-weight: bold !important;
  }

  .opensans-regular-18 {
    font-size: 14pt !important;
    font-weight: normal !important;
  }

  .opensans-regular-16 {
    font-size: 12pt !important;
    font-weight: normal !important;
  }

  .opensans-regular-14 {
    font-size: 10pt !important;
    font-weight: normal !important;
  }

  .opensans-bold-20 {
    font-size: 14pt !important;
    font-weight: bold !important;
  }

  .opensans-bold-14 {
    font-size: 10pt !important;
    font-weight: bold !important;
  }

  .opensans-bold-12 {
    font-size: 8pt !important;
    font-weight: bold !important;
  }

  .opensans-light-14 {
    font-size: 10pt !important;
    font-weight: lighter !important;
  }
}
</style>
