<template>
  <div>
    <two-cols>
      <template #first>
        <text-component
          :max-width="$vuetify.breakpoint.sm ? '329px' : '638px'"
          class="my-9 pb-0 pb-md-10 pb-lg-15"
          dark-themed
        >
          <template #titleh1>{{
            $t('about.subpages.business.title')
          }}</template>
          <template #contentWithoutFont>
            <p class="text-body-1 mb-4">
              {{ $t('about.subpages.business.text1') }}
            </p>
            <p class="text-body-1">{{ $t('about.subpages.business.text2') }}</p>
            <p class="text-h2 mt-10 mb-5">
              {{ $t('about.subpages.business.subtitle') }}
            </p>
            <p class="text-body-1 mb-4">
              {{ $t('about.subpages.business.text3') }}
            </p>
            <h3 class="text-h3 pt-4 pb-1 d-inline-flex">
              {{ $t('about.subpages.business.titleVVS') }}
            </h3>
            <v-tooltip
              bottom
              :max-width="$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="grey darken-2"
                  class="ml-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>{{ $t('about.subpages.business.tooltipVVS') }}</span>
            </v-tooltip>
            <table-component :items="servicesVVS"></table-component>

            <h3 class="text-h3 pt-4 pb-1 d-inline-flex">
              {{ $t('about.subpages.business.titleEnergi') }}
            </h3>
            <v-tooltip
              bottom
              :max-width="$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="grey darken-2"
                  class="ml-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>{{ $t('about.subpages.business.tooltipEnergi') }}</span>
            </v-tooltip>
            <table-component :items="servicesEnergi"></table-component>

            <h3 class="text-h3 pt-4 pb-1 d-inline-flex">
              {{ $t('about.subpages.business.titleStyr') }}
            </h3>
            <v-tooltip
              bottom
              :max-width="$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="grey darken-2"
                  class="ml-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>{{ $t('about.subpages.business.tooltipStyr') }}</span>
            </v-tooltip>
            <table-component :items="servicesStyr"></table-component>

            <h3 class="text-h3 pt-4 pb-1 d-inline-flex">
              {{ $t('about.subpages.business.titleEl') }}
            </h3>
            <v-tooltip
              bottom
              :max-width="$vuetify.breakpoint.smAndUp ? '50vw' : '90vw'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="grey darken-2"
                  class="ml-1"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>{{ $t('about.subpages.business.tooltipEl') }}</span>
            </v-tooltip>
            <table-component :items="servicesEl"></table-component>

            <p class="text-body-1 mt-10 mb-4">
              {{ $t('about.subpages.business.text4') }}
            </p>
            <p class="text-body-1">{{ $t('about.subpages.business.text5') }}</p>
          </template>
        </text-component>
        <v-img
          class="mb-15"
          max-width="50%"
          src="@/assets/images/about/_desktop_mobile/auktoriserat_konsultforetag.jpg"
        >
        </v-img>
      </template>
      <template #second>
        <v-img
          class="rounded"
          src="@/assets/images/about/_desktop_mobile/business/verksamhet_toppbild.jpg"
        >
        </v-img>
      </template>
    </two-cols>

    <contact-us></contact-us>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContactUs from '../../components/complete/ContactUs.vue';
import TableComponent from '../../components/partial/TableComponent.vue';
import TextComponent from '../../components/partial/TextComponent.vue';
import TwoCols from '../../components/partial/TwoCols.vue';
import { mdiInformationOutline } from '@mdi/js';

//noinspection JSAnnotator
export default {
  components: {
    TwoCols,
    TextComponent,
    ContactUs,
    TableComponent,
  },
  name: 'Business',
  created() {
    this.updateCurrentSubHeaderComponent('page-breadcrumbs');
    this.updateCurrentPageBreadCrumbs(this.breadcrumbItems);
  },
  data: () => ({
    icons: {
      mdiInformationOutline,
    },
  }),
  computed: {
    servicesVVS() {
      return this.$t('about.subpages.business.servicesVVS').split('|');
    },
    servicesEl() {
      return this.$t('about.subpages.business.servicesEl').split('|');
    },
    servicesStyr() {
      return this.$t('about.subpages.business.servicesStyr').split('|');
    },
    servicesEnergi() {
      return this.$t('about.subpages.business.servicesEnergi').split('|');
    },
    breadcrumbItems() {
      return [
        {
          text: this.$t('about.breadcrumb'),
          name: 'About',
          disabled: false,
        },
        {
          text: this.$t('about.subpages.business.breadcrumb'),
          name: '#verksamhet',
          disabled: true,
        },
      ];
    },
  },
  ...mapActions({
    updateCurrentSubHeaderComponent: 'app/updateCurrentSubHeaderComponent',
    updateCurrentPageBreadCrumbs: 'app/updateCurrentPageBreadCrumbs',
  }),
};
</script>
