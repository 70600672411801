import { getFromApi } from '@/utils/api';

const projectsUrl = 'projects/published';
const projectCategoriesUrl = 'projects/categories';
const projectTagsUrl = 'projects/tags';
const featuredProjectsUrl = 'projects/featured';
const projectsSearchUrl = 'projects/search/';

export default {
  loadProjects: async ({ commit }) => {
    commit('setLoading', true);
    const { data } = await getFromApi(projectsUrl);
    commit('setProjects', data);
    commit('setFilteredProjects', data);
    commit('setLoading', false);
  },
  loadProjectCategories: async ({ commit }) => {
    commit('setLoading', true);
    const { data } = await getFromApi(projectCategoriesUrl);
    const projectCategories = data.map((category) => {
      return { ...category, selected: false };
    });
    projectCategories.unshift({
      title: 'Alla',
      id: -1,
      selected: true,
    });
    commit('updateProjectCategories', projectCategories);
    commit('setLoading', false);
  },
  loadProjectTags: async ({ commit }) => {
    commit('setLoading', true);
    const { data } = await getFromApi(projectTagsUrl);
    const projectTags = data.map((tag) => {
      return { ...tag, selected: false };
    });
    commit('updateProjectTags', projectTags);
    commit('setLoading', false);
  },
  loadFeaturedProjects: async ({ commit }) => {
    commit('setLoading', true);
    const { data } = await getFromApi(featuredProjectsUrl);
    commit('initializeFeaturedProjects', data);
    commit('setFeaturedProjectsLoaded', true);
    commit('setLoading', false);
  },
  updateProjectCategories: async ({ commit }, categoryArray) => {
    commit('updateProjectCategories', categoryArray);
  },
  updateProjectTags: async ({ commit }, categoryArray) => {
    commit('updateProjectTags', categoryArray);
  },
  setShowingProjectResults: async ({ commit }, categoryArray) => {
    commit('setShowingProjectResults', categoryArray);
  },
  setSelectedCategory: async ({ commit, getters }, categoryId) => {
    let { projectCategories } = getters;
    projectCategories = projectCategories.map((category) => {
      let selected = false;
      if (category.id === categoryId) {
        selected = true;
      }
      return { ...category, selected };
    });
    commit('updateProjectCategories', projectCategories);
  },
  setSelectedTag: async ({ commit, getters }, tagId) => {
    let { projectTags } = getters;
    projectTags = projectTags.map((tag) => {
      let selected = false;
      if (tag.id === tagId) {
        selected = true;
      }
      return { ...tag, selected };
    });
    commit('updateProjectCategories', projectTags);
  },
  updateFilteredProjects({ commit }, filteredProjects) {
    commit('setFilteredProjects', filteredProjects);
  },
  getProjectTeaserItems: async ({ commit }, relatedToId) => {
    let url;
    if (relatedToId) {
      url = `projects/tags/${relatedToId}/projects?page_size=3`;
    } else {
      url = projectsUrl + '?page_size=3';
    }
    const { data } = await getFromApi(url);
    commit('setProjectTeaserItems', data);
  },
  resetNumberOfItemsToShow: async ({ commit, state }) => {
    const { initalItemsToShow } = state;
    commit('resetNumberOfItemsToShow', initalItemsToShow);
  },
  searchProjects: async ({ commit }, searchFor) => {
    commit('setLoading', true);
    const { data } = await getFromApi(`${projectsSearchUrl}${searchFor}`);
    commit('setSearchResultsProjects', data);
    commit('setLoading', false);
  },
  setSearchResultsProjects: async ({ commit }, payload) => {
    commit('setSearchResultsProjects', payload);
  },
  setSearchResultsProjectsTags: async ({ commit }, payload) => {
    commit('setSearchResultsProjectsTags', payload);
  },
};
