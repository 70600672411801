<template>
  <div :key="routingKey">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as helper from '../js/helper.js';
import resetProjectFiltersMixin from '../mixins/resetProjectFiltersMixin.js';

//noinspection JSAnnotator
export default {
  name: 'Project',
  mixins: [resetProjectFiltersMixin],

  data() {
    return {
      savedRoutingKey: 0,
    };
  },
  async created() {
    this.savedRoutingKey = this.routingKey;
  },
  async mounted() {
    await this.loadProjects();
    this.calculateTagsCount();
    this.calculateCatsCount();
    if (this.projectTags.length === 0) {
      this.initializeProjectTagsAndCats();
      this.setShowingProjectResults(false);
    }
    this.resetNumberOfItemsToShow();
  },
  beforeUpdate() {
    if (this.savedRoutingKey !== this.routingKey) {
      this.savedRoutingKey = this.routingKey;
      this.resetProjectFilters();
    }
  },
  computed: {
    ...mapGetters({
      allProjects: 'project/allProjects',
      filteredProjects: 'project/filteredProjects',
      featuredProjects: 'project/featuredProjects',
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
      loading: 'project/loading',
      routingKey: 'app/routingKey',
    }),
  },
  methods: {
    ...mapActions({
      loadProjects: 'project/loadProjects',
      loadProjectCategories: 'project/loadProjectCategories',
      loadProjectTags: 'project/loadProjectTags',
      updateProjectCategories: 'project/updateProjectCategories',
      updateProjectTags: 'project/updateProjectTags',
      setShowingProjectResults: 'project/setShowingProjectResults',
      resetNumberOfItemsToShow: 'project/resetNumberOfItemsToShow',
    }),
    ...mapMutations({
      setLoading: 'project/setLoading',
    }),
    calculateTagsCount() {
      this.projectTags.forEach((item) => {
        return (item.count = helper.calculateTagsInProjects(
          item.id,
          this.allProjects
        ));
      });
    },
    calculateCatsCount() {
      this.projectCategories.forEach(
        (item) =>
          (item.count = helper.calculateCatsInProjects(
            item.id,
            this.filteredProjects
          ))
      );
    },

    initializeProjectTagsAndCats() {
      this.updateProjectCategories(
        helper.addSelectedAndOptionAll(this.projectCategories)
      );
      this.updateProjectTags(helper.addSelectedAndOptionAll(this.projectTags));
      this.calculateTagsCount();
      this.calculateCatsCount();
    },
  },
};
</script>

<style scoped lang="scss">
.v-text-field {
  &.v-text-field--enclosed {
    &:not(.v-text-field--rounded) {
      > .v-input__control {
        > .v-input__slot {
          padding: 0 20px !important;
        }
      }
    }
  }
}
.v-chip.v-chip--outlined {
  border-width: 1px;
  border-color: #c4c4c4;
}
.v-chip.v-size--default {
  height: 26px;
}
</style>
