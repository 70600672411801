<template>
  <div class="contact pb-10">
    <autoadjusting-row>
      <v-col cols="12" class="mb-4 mt-lg-7 mb-lg-5">
        <div id="ansok" class="text-center contact-headline">
          <h2 class="text-h2">{{ $t('career.application.teaserTitle') }}</h2>
        </div>
      </v-col>
      <v-col cols="12">
        <v-row class="mb-lg-15 pb-lg-15">
          <v-col cols="12" sm="6" md="8">
            <v-card flat class="pa-8 pa-xs-5 pa-lg-10 rounded">
              <v-card-title class="text-h2 pa-0 pb-4">
                {{ $t('career.application.form.title') }}
              </v-card-title>
              <v-row>
                <v-col cols="12" lg="6">
                  <p class="pb-3 text-body-1">
                    {{ $t('career.application.form.text') }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>

              <v-form
                ref="form"
                :disabled="disableForm"
                v-model="valid"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-firstname">{{
                      $t('career.application.form.firstName')
                    }}</label>
                    <v-text-field
                      id="form-firstname"
                      v-model="formData.firstName"
                      label=""
                      :placeholder="
                        $t('career.application.form.firstNamePlaceholder')
                      "
                      dense
                      class="rounded-sm text-body-1"
                      persistent-placeholder
                      outlined
                      :rules="[
                        (v) => !!v || $t('career.application.form.required'),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-lastname">{{
                      $t('career.application.form.lastName')
                    }}</label>
                    <v-text-field
                      id="form-lastname"
                      v-model="formData.lastName"
                      :placeholder="
                        $t('career.application.form.lastNamePlaceholder')
                      "
                      label=""
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || $t('career.application.form.required'),
                      ]"
                      class="rounded-sm text-body-1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-email">{{
                      $t('career.application.form.email')
                    }}</label>
                    <v-text-field
                      id="form-email"
                      v-model="formData.email"
                      :placeholder="
                        $t('career.application.form.emailPlaceholder')
                      "
                      :rules="emailRules"
                      label=""
                      outlined
                      dense
                      class="rounded-sm text-body-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-mobile">{{
                      $t('career.application.form.mobile')
                    }}</label>
                    <v-text-field
                      id="form-mobile"
                      v-model="formData.mobile"
                      label=""
                      :placeholder="
                        $t('career.application.form.mobilePlaceholder')
                      "
                      dense
                      class="rounded-sm text-body-1"
                      persistent-placeholder
                      outlined
                      :rules="[
                        (v) => !!v || $t('career.application.form.required'),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-type">{{
                      $t('career.application.form.type')
                    }}</label>
                    <v-select
                      id="form-type"
                      v-model="formData.type"
                      label=""
                      :placeholder="
                        $t('career.application.form.typePlaceholder')
                      "
                      :items="typeCategories"
                      outlined
                      dense
                      class="rounded-sm text-body-1"
                      :rules="[
                        (v) => !!v || $t('career.application.form.required'),
                      ]"
                    ></v-select
                  ></v-col>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-discipline">{{
                      $t('career.application.form.discipline')
                    }}</label>
                    <v-select
                      id="form-discipline"
                      v-model="formData.discipline"
                      :placeholder="
                        $t('career.application.form.disciplinePlaceholder')
                      "
                      :items="disciplineCategories"
                      label=""
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || $t('career.application.form.required'),
                      ]"
                      class="rounded-sm text-body-1"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-letter">{{
                      $t('career.application.form.letter')
                    }}</label>
                    <v-file-input
                      id="form-letter"
                      v-model="formData.letter"
                      label=""
                      :placeholder="$t('career.application.form.upload')"
                      outlined
                      dense
                      class="rounded-sm text-body-1"
                      prepend-icon=""
                      :accept="acceptFileTypes"
                      :rules="fileRules"
                      show-size
                    ></v-file-input
                  ></v-col>
                  <v-col cols="12" lg="6" class="py-0 mb-5 mb-md-7">
                    <label class="text-body-1" for="form-cv">{{
                      $t('career.application.form.cv')
                    }}</label>
                    <v-file-input
                      id="form-cv"
                      v-model="formData.cv"
                      :placeholder="$t('career.application.form.upload')"
                      outlined
                      prepend-icon=""
                      dense
                      class="rounded-sm text-body-1"
                      :accept="acceptFileTypes"
                      :rules="fileRules"
                      show-size
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row align="center" class="pl-3">
                  <v-checkbox
                    v-model="formData.agree_to_policy"
                    color="green"
                    :rules="[
                      (v) => !!v || $t('career.application.form.required'),
                    ]"
                  >
                    <template v-slot:label>
                      <div class="text-body-1">
                        {{ $t('career.application.form.checkbox') &nbsp;}}
                        <a
                          class="text-decoration-none text-body-1"
                          text
                          target="_blank"
                          @click="policyClick"
                        >
                          {{ $t('career.application.form.gdpr') }}</a
                        >
                      </div>
                    </template>
                  </v-checkbox>
                </v-row>

                <outlined-button
                  @click-submit="submit"
                  :enable-value="valid"
                  darkContent
                  :loading="loading"
                  :disabled="loading"
                >
                  <template #buttonText>
                    {{ $t('career.application.form.button') }}
                  </template>
                </outlined-button>
              </v-form>
            </v-card>
          </v-col>
          <v-col sm="6" md="4">
            <contact-card :mailToLink="mailToLink"></contact-card>
          </v-col>
        </v-row>
      </v-col>
    </autoadjusting-row>
    <user-message
      :showMe="showMessage"
      :messageText="userMessage"
      :error="error"
    ></user-message>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import { each } from 'lodash';
import UserMessage from './UserMessage.vue';
import ContactCard from '../partial/ContactCard.vue';
export default {
  components: { AutoadjustingRow, OutlinedButton, UserMessage, ContactCard },
  name: 'ApplyForJob',
  data() {
    return {
      showMessage: false,
      userMessage: '',
      error: false,
      typeCategories: this.$t('career.application.form.typeCategories').split(
        ','
      ),
      disciplineCategories: this.$t(
        'career.application.form.disciplineCategories'
      ).split(','),
      valid: false,
      loading: false,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        discipline: '',
        type: '',
        cv: null,
        letter: null,
        agree_to_policy: false,
      },
      emailRules: [
        (v) => !!v || this.$t('career.application.form.required'),
        (v) =>
          /.+@.+\..+/.test(v) || this.$t('global.contact.form.invalidEmail'),
      ],
      fileRules: [
        (v) => !!v || this.$t('career.application.form.required'),
        (v) =>
          (v && /\.(doc|docx|pdf)$/i.test(v.name)) ||
          this.$t('career.application.form.invalidFiletype') +
            this.$t('career.application.form.validFiletypes'),
        (v) =>
          (v && v.size <= 5000000) ||
          this.$t('career.application.form.fileSize'),
      ],
      acceptFileTypes: this.$t('career.application.form.validFiletypes')
        .split(',')
        .map((item) => '.' + item)
        .toString(),
      showPolicy: false,
    };
  },
  computed: {
    mailToLink() {
      return (
        'mailto:' +
        this.$t('global.contact.emailAddress') +
        '?subject=Application'
      );
    },
    disableForm() {
      return this.loading;
    },
  },
  methods: {
    ...mapActions({
      postApplication: 'app/postApplication',
    }),
    policyClick() {
      const routeData = this.$router.resolve({ name: 'Privacy' });
      window.open(routeData.href, '_blank');
    },
    async submit() {
      this.showMessage = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        each(this.formData, (value, key) => {
          formData.append(key, value);
        });
        try {
          await this.postApplication(formData);
          this.$refs.form.reset();
          this.userMessage = this.$t('career.application.form.success');
          this.error = false;
        } catch (error) {
          this.userMessage = this.$t('career.application.form.error');
          this.error = true;
        }
        this.loading = false;
        this.showMessage = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  background-color: #fafaf8;
}
.contact-headline {
  padding-top: 90px;
}
a {
  text-decoration: none;
}
.v-text-field.v-input--dense {
  max-height: 42px;
}
.policy-text {
  padding: 8px;
  background-color: #f8f8f8;
}
</style>
