<template>
  <div>
    <top-component
      :image-source="
        require('@/assets/images/start/desktop/incoord_hero_bg.jpg')
      "
      :image-srcset="
        require('@/assets/images/start/mobile/incoord_hero_bg.jpg')
      "
      scrollTo="#in_english"
    >
      <template #overline>{{ $t('inEnglish.hero.overline') }}</template>
      <template #overline2
        >{{ $t('inEnglish.hero.title') }} <br />
        {{ $t('inEnglish.hero.title2') }}</template
      >
      >
    </top-component>

    <two-cols id="in_english">
      <template #first>
        <text-component
          dark-themed
          class="pt-16 py-sm-16"
          position="left"
          max-width="523"
        >
          <template #titleh2>{{ $t('inEnglish.about.title') }}</template>
        </text-component>
      </template>
      <template #second>
        <text-component dark-themed class="pb-16 py-sm-16">
          <template #content>
            <p class="text-body-1">
              {{ $t('inEnglish.about.text1') }}
            </p>
            <p class="mt-4 text-body-1">
              {{ $t('inEnglish.about.text2') }}
            </p>
          </template>
        </text-component>
      </template>
    </two-cols>

    <v-card class="rounded-0 elevation-0 py-16" style="background-color: white">
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col cols="12" sm="10" lg="8">
          <text-component dark-themed centered>
            <template #titleh2>{{ $t('inEnglish.projects.title') }}</template>
            <template #actions>
              <outlined-button darkContent :to="{ name: 'Project' }">
                <template #buttonText>
                  {{ $t('inEnglish.projects.button') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </v-card>

    <contact-us :linkToCareer="false"></contact-us>
  </div>
</template>

<script>
import ContactUs from '../components/complete/ContactUs.vue';
import TopComponent from '../components/complete/TopComponent.vue';
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import OutlinedButton from '../components/partial/OutlinedButton.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TwoCols from '../components/partial/TwoCols.vue';
import setSubHeaderToNullMixin from '../mixins/setSubHeaderToNullMixin.js';

export default {
  components: {
    TopComponent,
    TextComponent,
    AutoadjustingRow,
    OutlinedButton,
    ContactUs,
    TwoCols,
  },
  name: 'InEnglish',
  mixins: [setSubHeaderToNullMixin],
  created() {
    this.$i18n.locale = 'en';
  },
  beforeDestroy() {
    this.$i18n.locale = 'sv';
  },
  computed: {
    services() {
      return this.$t('inEnglish.about.services').split('|');
    },
  },
};
</script>
