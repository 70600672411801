<template>
  <srcset-image class="align-center" :imageLarge="imageSource" :imageSmall="imageSrcset" :max-height="getImageHeight">
    <template>
      <v-container>
        <autoadjusting-row>
          <v-spacer> </v-spacer>
          <v-col cols="12" :sm="wider ? '10' : '9'" :xl="wider ? '10' : '8'" align="center">
            <v-card class="rounded-0 elevation-0 px-5 py-10 transparent" :max-width="getTextWidth">
              <v-img v-if="addLogo" class="mx-auto" max-height="197" :max-width="getLogoWidth"
                src="@/assets/images/start/_desktop_mobile/incoord_lineart_logo.svg" />
              <h1 v-if="$slots.headline" class="text-h1 gotham-bold-40 mb-4" :class="getThemedTextClass">
                <slot name="headline"></slot>
              </h1>
              <h2 v-if="$slots.text" class="text-h2 mb-5 opensans-regular-18" :class="getThemedTextClass">
                <slot name="text"></slot>
              </h2>
            </v-card>
          </v-col>
          <v-spacer> </v-spacer>
        </autoadjusting-row>
      </v-container>
    </template>
  </srcset-image>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import SrcsetImage from '../partial/SrcsetImage.vue';
export default {
  components: { AutoadjustingRow, SrcsetImage },
  name: 'TopQR',
  props: {
    darkThemed: Boolean,
    centered: Boolean,
    wider: Boolean,
    addLogo: { type: Boolean, default: false },
    imageSource: {
      type: String,
    },
    imageSrcset: {
      type: String,
    },
    scrollTo: {
      type: String,
    },
  },
  computed: {
    commonAttrs() {
      return {
        class: 'px-5 transparent',
        elevation: 0,
      };
    },
    getThemedTextClass() {
      let classes = 'px-0 text-center';
      return classes.concat(
        ' ',
        this.darkThemed ? 'black--text' : 'white--text'
      );
    },
    getThemedButtonSource() {
      return this.darkThemed
        ? require('@/assets/images/global/_desktop_mobile/hero_chevron_down_bl.svg')
        : require('@/assets/images/global/_desktop_mobile/hero_chevron_down_wh.svg');
    },
    getTextWidth() {
      if (this.wider) {
        return this.$vuetify.breakpoint.sm ? 464 : 1118;
      } else {
        return this.$vuetify.breakpoint.sm ? 464 : 882;
      }
    },

    getImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 750;
        case 'sm':
          return 835;
        default:
          return 1030;
      }
    },
    getLogoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 282;
        case 'sm':
          return 344;
        case 'md':
          return 344;
        default:
          return 707;
      }
    },
  },
  methods: {
    scrollToElement() {
      this.$vuetify.goTo(this.scrollTo, { offset: 0, duration: 300 });
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Gotham-Bold';
  src: url('~@/assets/fonts/Gotham-Bold.ttf') format('truetype');
}

.gotham-bold-20 {
  font-family: 'Gotham-Bold', sans-serif !important;
  font-size: 16pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18 !important;
}

p {
  margin-bottom: 16px;
}

.card-subtitle {
  font-weight: bold;
  display: block;
}

.page-height {
  min-height: 100svh;
  position: relative;
}

.v-application {

  a,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
  }
}

.qr-blue--text {
  color: #505cb4 !important;
}

.qr-blue--background {
  background-color: #505cb4 !important;
}

.gotham-bold-40 {
  font-family: 'Gotham-Bold', sans-serif !important;
  font-size: 34pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18 !important;
}

.opensans-regular-18 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14pt !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.opensans-regular-16 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12pt !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.opensans-bold-14 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 12pt !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal !important;
  letter-spacing: normal !important;
}

@media screen and (max-width: 600px) {
  .gotham-bold-40 {
    font-size: 20pt !important;
    font-weight: bold !important;
  }

  .opensans-regular-18 {
    font-size: 12pt !important;
    font-weight: normal !important;
  }

  .opensans-regular-16 {
    font-size: 10pt !important;
    font-weight: normal !important;
  }

  .opensans-bold-14 {
    font-size: 9pt !important;
    font-weight: bold !important;
  }
}
</style>
